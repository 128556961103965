import { useEffect, useMemo } from "react";

import { leftAngleIcon, rightAngleIcon } from "svgs";
import { PAGE_SIZE } from "utils/constants";
import { classNames } from "utils/helpers";
import { Page } from "types/store";

interface ComponentProps {
  page: Page;
  onBack: () => void;
  onNext: () => void;
  resetCallback: () => void;
}

export default function TablePagination(props: ComponentProps) {
  const {
    onBack,
    onNext,
    resetCallback,
    page: { selectedPage, totalElements, totalPages },
  } = props;

  const shownRecords = useMemo(() => {
    const shownRows = selectedPage * PAGE_SIZE;
    if (selectedPage === totalPages) {
      return totalElements;
    }

    if (shownRows > totalElements) {
      return totalElements;
    }

    return shownRows;
  }, [selectedPage, totalPages, totalElements]);

  const prevBtnClasses = useMemo(() => {
    return classNames({
      paginationButton: true,
      disabled: selectedPage === 1,
    });
  }, [selectedPage]);

  const nextBtnClasses = useMemo(() => {
    return classNames({
      paginationButton: true,
      disabled: selectedPage >= totalPages,
    });
  }, [selectedPage, totalPages, ]);

  const recordRange = useMemo(

    () => (shownRecords > 0 ? (selectedPage - 1) * PAGE_SIZE + 1 : 0),
    [shownRecords, selectedPage]
  );
  useEffect(() => {
    if (recordRange >= shownRecords) {
      resetCallback();
    }
  }, [recordRange, shownRecords, resetCallback]);

  return totalElements > PAGE_SIZE ? (
    <article className="pagination">
      <section className="pagination-text">
        Showing {recordRange} - {shownRecords} of {totalElements} records        
      </section>
      <section className="paginationButtons-container">
        <span className={prevBtnClasses} onClick={onBack}>
          {leftAngleIcon}
        </span>
        <span className={nextBtnClasses} onClick={onNext}>
          {rightAngleIcon}
        </span>
      </section>
    </article>
  ): null;
}
