import { Radio as RadioAntd } from "antd";
import { RadioChangeEvent } from "antd/lib/radio";

import { Radio } from "components/base";
import { useCallback, useEffect, useState } from "react";

interface ComponentProps {
  options: { label: string; value: string|number }[];
  value?: string|number;
  defaultValue?: string;
  onChange?: (e: RadioChangeEvent) => void;
  disabled?:boolean;
}

export default function RadioGroup(props: ComponentProps) {
  const {
    options,
    onChange: propsOnChange,
    defaultValue = [],
    value: propsValue,
    disabled = false,
  } = props;

  const [value, setValue] = useState(propsValue);

  useEffect(() => {
    setValue(propsValue);
  }, [propsValue]);

  const onChange = useCallback((event) => {
    setValue(event.target.value);
  }, []);

  return (
    <RadioAntd.Group
      className="filter-radio-group"
      onChange={propsOnChange || onChange}
      defaultValue={defaultValue}
      value={value}>

      {options.map((option) => (
        <Radio key={option.value} value={option.value} disabled={disabled}>
          {option.label}
        </Radio>
      ))}
    </RadioAntd.Group>
  );
}
