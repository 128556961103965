import { Input as AntInput } from "antd";
import { InputProps } from "antd/lib/input";

interface ComponentProps {
  customClasses?: string;
}

export default function Input(props: InputProps & ComponentProps) {
  const { customClasses = "" } = props;

  return <AntInput {...props} className={`search-box ${customClasses}`} />;
}
