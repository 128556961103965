import { Button, Dropdown, Menu } from "antd";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Input, SwitchButton } from "components/base";
import { useHistory, useLocation } from "react-router-dom";
import { setModalName, setUserSearch } from "store/actions";
import {
  actionMenu,
  archiveIcon,
  exportIcon,
  plusIcon,
  searchIcon,
} from "svgs";
import { ROUTES } from "utils/constants";
import { AppState } from "store";

export default function UsersHeader() {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  const selectedView = location.pathname.split("/")[2];
  const { showbulkActionsUser } = useSelector<AppState, AppState["users"]>(
    (state) => state.users
  );
    const {
        common: { userType =1 },
    } = useSelector<AppState, AppState>((state) => state);

  const onAddUsers = useCallback(() => {
    dispatch(setModalName("add_user"));
  }, [dispatch]);

  const onSwitchView = useCallback(
    (event) => {
      const { value } = event.target;
      history.push(`${ROUTES.users}/${value}`);
    },
    [history]
  );

  const onSearch = useCallback(
    (e) => {
      dispatch(setUserSearch(e.target.value));
    },
    [dispatch]
  );

  const menu = (
    <Menu className="vehicle-drop-menu">
      <Menu.Item key="0" disabled>
        <span> Import</span>
      </Menu.Item>
      <Menu.Item key="1" disabled>
        <span> Export</span>
      </Menu.Item>
    </Menu>
  );

  return (
    <div className="common-header">
      <Input
        customClasses="--standard"
        placeholder="Search Users..."
        prefix={searchIcon}
        onChange={onSearch}
      />

      {showbulkActionsUser && (
        <article className="bulk-actions">
          <Button disabled icon={exportIcon}>
            Export
          </Button>
          {/* <Button disabled danger icon={archiveIcon}>
            Archive
          </Button> */}
        </article>
      )}

      <div className="actions-container">
        {/*<SwitchButton*/}
        {/*  label="Select View:"*/}
        {/*  options={["table", "list"]}*/}
        {/*  defaultOption={selectedView ?? "table"}*/}
        {/*  onChange={onSwitchView}*/}
        {/*  type="secondary"*/}
        {/*/>*/}
        {/* <div className="vertical-separator" /> */}
        {/* <Button
          className="export-btn"
          disabled
          icon={<span className="custom-icon">{exportIcon}</span>}>
          Export
        </Button> */}
        {/* <Dropdown overlay={menu} trigger={["click"]} className="vehicle-menu">
          <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
            {actionMenu}
          </a>
        </Dropdown> */}
          { userType != 6 && userType != 3 && <Button
          type="primary"
          icon={<span className="custom-icon">{plusIcon}</span>}
          onClick={onAddUsers}>
          Add User
        </Button> }
      </div>
    </div>
  );
}
