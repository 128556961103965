import { Formik } from "formik";
import { useCallback } from "react";
import PhoneInput from "react-phone-input-2";
import { Form, Button, Typography, Input, Upload } from "antd";

import {
    userRoles,
} from "components/tab-content/users-content/__mock__";
import { FormItem, SelectSearch } from "components";
import {BATTERY_FIRMWARE, BATTERY_MODEL_FIRMWARE} from "utils/formik-data";
import { BatteryModelValues } from "types/formik";
import { SelectType } from "types";
import { uploadIcon } from "svgs";
import {addBattery, addBatteryModel, setModalName, updateBatteryModel} from "store/actions";
import {useDispatch} from "react-redux";

const { Title, Text } = Typography;

interface ComponentProps {
    initialValues: BatteryModelValues;
    setValues: (values: BatteryModelValues) => void;
    onCancel?: React.MouseEventHandler<HTMLElement>;
}

export default function AddBatteryModelForm(props: ComponentProps) {
    const { initialValues, onCancel, setValues } = props;
    const dispatch = useDispatch();
    const onCancels = useCallback(() => {
        dispatch(setModalName(undefined));
    }, [dispatch]);

    const operationalState = [
        {
            label: "IN_VEHICLE",
            value: "IN_VEHICLE"
        },
        {
            label: "IN_KIOSK",
            value: "IN_KIOSK"
        },
        {
            label: "IN_MAINTENANCE",
            value: "IN_MAINTENANCE"
        }
    ];

    const onSubmit = useCallback(
        (values: BatteryModelValues) => {

            dispatch(
                addBatteryModel({
                    make: values.make.trim() || "",
                    model: values.model.trim() || "",
                    year: parseInt(values.year) || 2024
                })
            );
            onCancels();
        },
        [dispatch, onCancels]
    );
    return (
        <Formik
            initialValues={initialValues}
            validationSchema={BATTERY_MODEL_FIRMWARE.validationSchema}
            onSubmit={onSubmit}
            enableReinitialize
        >
            {({
                  setFieldValue,
                  dirty,
                  isValid,
                  handleSubmit,
                  handleChange,
                  values,
                  errors,
              }) => (
                <div className="form-container">
                    <Title level={5}>Add Battery Model</Title>
                    <Text className="description" type="secondary">
                    Please provide the following details to add new model
                    </Text>
                    <br />
                     <br />
                    <Form className="--add-users-form" onFinish={handleSubmit}>
                        <section className="">
                            <FormItem label="Make" error={errors.make} required>
                                <Input
                                    name="make"
                                    value={values.make}
                                    onChange={handleChange}
                                    placeholder="Make"
                                />
                            </FormItem>
                            <FormItem label="Model" error={errors.model} required>
                                <Input
                                    name="model"
                                    value={values.model}
                                    onChange={handleChange}
                                    placeholder="Model"
                                />
                            </FormItem>
                            <FormItem label="Year" error={errors.year} required>
                                <Input
                                    name="year"
                                    value={values.year}
                                    onChange={handleChange}
                                    placeholder="Year"
                                />
                            </FormItem>
                        </section>
                        <div className="action-btn-container">
                            <>
                                <Button className="cancel-btn" onClick={onCancel}>
                                    Cancel
                                </Button>
                                <Button
                                    htmlType="submit"
                                    type="primary"
                                    disabled={(!isValid && dirty)}
                                >
                                    Save
                                </Button>
                            </>
                        </div>
                    </Form>
                </div>
            )}
        </Formik>
        
    );
    
}