import {Battery, BatteryActions, GetState, Zone, ZonesActions, ZonesState} from "types";
import {Dispatch} from "react";
import {apiResponse} from "./common-actions";
import {zoneManagmentServiceClient} from "../../utils/grpc-clients";
import {SUCCESS_MESSAGES} from "../../utils/constants";
import {ZoneManagementServiceClient} from "../../protobuf/Zone_serviceServiceClientPb";
import {
    AddZoneRequest,
    AddZoneResponse,
    GetZonesListRequest, GetZonesListResponse, UpdateZoneRequest, UpdateZoneResponse, ZoneItem,
    ZoneLatLng,
    ZoneUpdateItem
} from "../../protobuf/zone_service_pb";

export function setBulkActionsZones(
  payload: ZonesState["showbulkActionsZones"]
): ZonesActions {
  return {
    type: "SET_ZONE_BULK_ACTIONS",
    payload,
  };
}


export function setCoordinates(
    payload: ZonesState["zoneCoordinates"]
): ZonesActions {
  return {
    type: "SET_ZONE_COORDINATES",
    payload,
  };
}

export function initializeZones(
){

  const request = new GetZonesListRequest()
  // request.setOwnerOrgId("86ee2476-4ca0-44f0-b73c-3cb07eab2ea5");

  return async (dispatch: Dispatch<any>) => {
    function dispatcher(resp: GetZonesListResponse) {
      const zones: Zone[] = resp?.getZonesList()?.map(zone => ({
        ...(zone?.toObject() as ZoneItem.AsObject),

      }));
      dispatch(setZones(zones))
    }
    dispatch(
        apiResponse<
            ZoneManagementServiceClient,
            GetZonesListRequest,
            GetZonesListResponse
            >({
          client: zoneManagmentServiceClient,
          action: "getZonesList",
          request,
          dispatcher,
        })
    );
  };
}

export function setZones(
    payload: Zone[]
): ZonesActions {
  return {
    type: "SET_ZONES",
    payload,
  };
}

//Add New Zone
export function addZone({
                             name,
                             coordinates,
                           }: {
  name:string;
  coordinates:[];

}) {
  return (dispatch: Dispatch<any>, getState: GetState) => {


   const updateZoneItem = new ZoneUpdateItem()
    updateZoneItem.setZoneName(name)
      let locArray = []
    for (let i = 0; i < coordinates.length; i++ ) {
         let latLng: { lat: number; lng: number; } = coordinates[i]
        let locItem = new ZoneLatLng()
        locItem.setLng(latLng.lng)
        locItem.setLat(latLng.lat)
        locArray.push(locItem)
    }
    updateZoneItem.setLatLngList(locArray)

    const request = new AddZoneRequest()
    request.setZone(updateZoneItem)




    dispatch(
        apiResponse<
            ZoneManagementServiceClient,
            AddZoneRequest,
            AddZoneResponse
            >({
          request,
          client: zoneManagmentServiceClient,
          action: "addZone",
          successMessage: SUCCESS_MESSAGES.addZone,
          //dispatcher,
        })
    );
  };
}
//end add new zone


//Add New Zone
export function editZone({
                            id,
                            name,
                            coordinates,
                        }: {
    id: string;
    name:string;
    coordinates:[];

}) {
    return (dispatch: Dispatch<any>, getState: GetState) => {


        const updateZoneItem = new ZoneUpdateItem()
        updateZoneItem.setZoneName(name)
        let locArray = []
        for (let i = 0; i < coordinates.length; i++ ) {
            let latLng: { lat: number; lng: number; } = coordinates[i]
            let locItem = new ZoneLatLng()
            locItem.setLng(latLng.lng)
            locItem.setLat(latLng.lat)
            locArray.push(locItem)
        }
        updateZoneItem.setLatLngList(locArray)

        const request = new UpdateZoneRequest()
        request.setZone(updateZoneItem)
        request.setId(id)

        function dispatcher() {
            dispatch(initializeZones())
        }

        dispatch(
            apiResponse<
                ZoneManagementServiceClient,
                UpdateZoneRequest,
                UpdateZoneResponse
                >({
                request,
                client: zoneManagmentServiceClient,
                action: "updateZone",
                successMessage: SUCCESS_MESSAGES.editZone,
                dispatcher,
            })
        );
    };
}
//end add new zone


export function setSelectedZone(payload:ZonesState["selectedZone"]):ZonesActions {
    return {
        type: "SET_SELECTED_ZONE",
        payload: payload,
    }
}
