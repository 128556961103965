import { Formik } from "formik";
import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { Form, Button, Typography, Input } from "antd";

import { setModalName } from "store/actions";
import { archiveIcon, plusIcon } from "svgs";
import { USER_TYPE } from "utils/mapped-data";

const { Title, Text } = Typography;

export default function AddUsersForm() {
  const dispatch = useDispatch();

  const onCancel = useCallback(() => {
    dispatch(setModalName(undefined));
  }, [dispatch]);

  return (
    <Formik initialValues={{}} onSubmit={onCancel}>
      {({ setFieldValue, handleSubmit, handleChange, values }) => (
        <div className="form-container">
          <Title level={5}>Edit Roles</Title>
          <Text className="description" type="secondary">
            Edit the existing roles in your system or{" "}
            <span className="add-new">{plusIcon} Add Role</span>
          </Text>
          <Form className="--edit-role-form" onFinish={handleSubmit}>
            {Object.values(USER_TYPE).map((role, i) => (
              <Form.Item className="edit-role-input-wrapper">
                <Input value={role} />
                <span className="delete">{archiveIcon}</span>
              </Form.Item>
            ))}
            <div className="action-btn-container">
              <Button className="cancel-btn" onClick={onCancel}>
                Cancel
              </Button>
              <Button type="primary" htmlType="submit">
                Save
              </Button>
            </div>
          </Form>
        </div>
      )}
    </Formik>
  );
}
