import { Checkbox as CheckboxAnt } from "antd";
import { CheckboxOptionType, CheckboxValueType } from "antd/lib/checkbox/Group";

import { Checkbox } from "components/base";
import { ReactNode, useCallback, useEffect, useState } from "react";

interface ComponentProps {
  options: ({ render?: ReactNode } & CheckboxOptionType)[];
  value?: Array<CheckboxValueType>;
  defaultValue?: CheckboxValueType[];
  onChange?: (checkedValue: Array<CheckboxValueType>) => void;
  suffix?: boolean;
}

export default function CheckBoxGroup(props: ComponentProps) {
  const {
    options,
    onChange: propsOnchange,
    defaultValue = [],
    value: propsValue,
    suffix,
  } = props;

  const [value, setValue] = useState(propsValue);

  useEffect(() => {
    setValue(propsValue);
  }, [propsValue]);

  const onChange = useCallback((updateValue) => {
    setValue(updateValue);
  }, []);

  return (
    <CheckboxAnt.Group
      value={value}
      defaultValue={defaultValue}
      onChange={propsOnchange || onChange}
      className="filter-checkbox-group">
      {options.map((option) => (
        <Checkbox key={option.value as string} value={option.value}>
          <span
            className="issue-checkbox-label"
            title={option.label?.toString()}>
            {option.label}
          </span>
          <span>{option.render}</span>
        </Checkbox>
      ))}
    </CheckboxAnt.Group>
  );
}
