import { ColumnChart } from "@k2/rv-viz";
import { useEffect, useState } from "react";
import styled from "styled-components";

const columnData = [
  {
    data: [
      {
        color: "rgba(69, 128, 217, 0.2)",
        labelColor: "#777777",
        x: "Pending",
        y: 180,
        label: "Pending:",
      },
      {
        color: "rgba(112, 184, 87, 0.2)",
        labelColor: "#777777",
        x: "Completed",
        y: 120,
        label: "Completed:",
      },
      {
        color: "rgba(237, 32, 32, 0.2)",
        labelColor: "#777777",
        x: "Cancelled",
        y: 20,
        label: "Cancelled:",
      },
    ],
    name: "series 1",
  },
];

const ColumnChartStyled = styled(ColumnChart)<{
  barBorderWidth: number;
}>`
  &.custom-column-chart {
    .k2--vertical-bar-series {
      > rect {
        stroke-width: 4px;
        stroke-dasharray: calc(${(props) => props.barBorderWidth} - 1) 3000;

        :nth-last-of-type(1) {
          stroke: #ed2020 !important;
        }
        :nth-last-of-type(2) {
          stroke: #2c9f3f !important;
        }
        :nth-last-of-type(3) {
          stroke: #4580d9 !important;
        }
      }
    }
  }
`;

export default function RenderChart() {
  const [state, setState] = useState({
    barBorderWidth: 0,
  });

  useEffect(() => {
    function reportWindowSize() {
      setTimeout(() => {
        const barRects = document.querySelectorAll(
          ".custom-column-chart .k2--vertical-bar-series > rect"
        );
        const borderWidth = barRects[0]?.getBoundingClientRect().width;
        setState({ barBorderWidth: borderWidth });
      }, 500);
    }
    reportWindowSize();
    window.addEventListener("resize", reportWindowSize);

    return () => {
      window.removeEventListener("resize", reportWindowSize);
    };
  }, [state]);

  return (
    <ColumnChartStyled
      barBorderWidth={state.barBorderWidth}
      barWidth={0.98}
      verticalGridLines={false}
      horizontalGridLines={false}
      xAxis={false}
      yAxis={false}
      legends={false}
      data={columnData}
      xyPlot={{
        margin: {
          left: 10,
          right: 10,
          top: 5,
          bottom: 0,
        },
      }}
      title="Issues by Status"
      className="custom-column-chart"
      label={(props) => {
        return (
          <article className="column-chart-label">
            <span className="label-name">{props.data.label}</span>
            <span className="label-value">{props.data.y}</span>
          </article>
        );
      }}
    />
  );
}
