import { Typography, Button , Form } from "antd";
import {useCallback, useState, useEffect, useMemo} from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "store";
import {deleteVehicle, setModalName, updateFirmwareVehicle, getDropdownFirmwares, updateFirmwareOnVehicles} from "store/actions";
import {FormItem, SelectSearch} from "../components";
import { parseFirmwares } from "utils/parsers";
import { Formik } from "formik";
const { Text, Title } = Typography;



export default function BulkVehicleFirmwareUpdateForm() {

   
    const {
        firmwares: { dropdownFirmwares },
      } = useSelector<AppState, AppState>((state) => state);
    const dispatch = useDispatch();
    const { selectedVehicles } = useSelector<AppState, AppState["vehicles"]>(
        (state) => state.vehicles
    );
    const initialValues = {
        firmwareVersion: '',
    };
    const firmwaresOptions = useMemo(() =>  parseFirmwares(dropdownFirmwares), [
        dropdownFirmwares,
      ]);
    let devicesIotIdList: string[] = [];
    if (selectedVehicles !== undefined) {
        devicesIotIdList = selectedVehicles
            .map(item => item?.deviceIotId)
            .filter(devicesIotId => typeof devicesIotId === 'string')
            .map(devicesIotId => devicesIotId as string);
    }
    const onSubmit = useCallback((values) => {
        console.log("Version Value:", values['Firmware Version'].toString())
       dispatch(updateFirmwareOnVehicles(values['Firmware Version'].toString(),devicesIotIdList));
        onCancel();
    }, [dispatch]);

    const onCancel = useCallback(() => {
        dispatch(setModalName(undefined));
    }, [dispatch]);

    useEffect(() => {
        if (!dropdownFirmwares) {
          dispatch(getDropdownFirmwares());
        }
      }, [dispatch, dropdownFirmwares]);

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}>
            {({
                  values,
                  handleSubmit,
                  setFieldValue,
              }) =>
        <section className="delete-modal">
            <Title level={5}>Update Firmware</Title>
            <Text type="secondary">
                Please select the target firmware version for the selected vehicle(s):<br/><br/>
            </Text>
            <Form onFinish={handleSubmit} className="--update-firmware-form">
            <FormItem label="Firmware Versions">
                <SelectSearch
                     value={values.firmwareVersion}
                     items= {firmwaresOptions} 
                      setFieldValue={setFieldValue}
                      fieldName="Firmware Version"
                      placeholder="Select "
                />
            </FormItem>
            <div className="action-btn-container">
                <Button className="cancel-btn" onClick={onCancel}>
                    Cancel
                </Button>
                <Button type="primary" danger htmlType="submit">
                    Update Firmware
                </Button>
            </div>
            </Form>
        </section>
            }
        </Formik>
    );
}
