import { Formik } from "formik";
import {useCallback, useMemo, useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Button, Typography, Input, Upload } from "antd";


import { fileToByteArray } from "utils/helpers";
import { FormItem, SelectSearch } from "components";
import {FIRMWARE_FORMIK, MODEL_FIRMWARE_FORMIK} from "utils/formik-data";
import {FirmwareValues, ModelFirmwareValues} from "types/formik";
import { SelectType } from "types";
import { uploadIcon } from "svgs";
import {addIssue, setModalName, addFirmwareToModel, addFirmWare} from "../../store/actions";
import {AppState} from "../../store";


const { Title, Text } = Typography;

export type StateFirmwareValues = ModelFirmwareValues & { };

export default function UploadModelFirmware(){

    const dispatch = useDispatch();
    const [{ values }, setState] = useState<{
        values: StateFirmwareValues;
    }>({
        values: { ...MODEL_FIRMWARE_FORMIK.initialValues },
    });
    const {
        models: {selectedModels},
    } = useSelector<AppState, AppState>((state) => state);

    const onCancel = useCallback(() => {
        dispatch(setModalName(undefined));
    }, [dispatch]);

    const initialValues = useMemo(() => {
        return MODEL_FIRMWARE_FORMIK.initialValues ;
    }, []);

    const setValues = useCallback(
        (values: ModelFirmwareValues) => {
            setState({
                values: {
                    ...values,
                },
            });
        },
        [dispatch, onCancel]
    );

    const onSubmit = useCallback(
        (values: ModelFirmwareValues) => {
            setValues(values);
            dispatch(
                addFirmwareToModel({
                    document: values.document_file || "",
                    documentExt: values.document_file_raw?.type || "",
                    documentName: values.document_file_raw?.name || "",
                    model_id: selectedModels?.id || "",
                    firmware_version: values.firmware_version || "",
                    md_hash: "",
                })
            )
            onCancel();
        },
        [setValues, dispatch, onCancel]
    );


    return (
        <Formik
            initialValues={initialValues}
            validationSchema={MODEL_FIRMWARE_FORMIK.validationSchema}
            onSubmit={onSubmit}
            enableReinitialize
        >
            {({
                  setFieldValue,
                  dirty,
                  isValid,
                  handleSubmit,
                  handleChange,
                  values,
                  errors,
              }) => (
                <div className="form-container">
                    <Title level={5}>Upload Firmware</Title>
                    <Text className="description text-ceneter" type="secondary">
                       Please upload new firmware version:
                    </Text>
                    <br />
                    <br />
                    <Form className="" onFinish={handleSubmit}>
                        <section className="">
                            <FormItem label="Version No" error={errors.firmware_version} required >
                                <Input
                                    name="firmware_version"
                                    value={values.firmware_version}
                                    onChange={handleChange}
                                    placeholder="Version No eg: 1.0.0"
                                />
                            </FormItem>
                            <FormItem
                                label="Firmware"
                                error={errors.document_file}
                                verticalAlign="flex-start"
                                required
                            >
                                <Upload.Dragger
                                    name="document_file"
                                    multiple={false}
                                    fileList={values.fileList}
                                    accept={".bin"}
                                    onChange={({ fileList }) => {
                                        // Getting total number of files
                                        const totalFiles = fileList.length;

                                        // If multiple files, pick last file
                                        if (totalFiles > 1) {
                                            setFieldValue("fileList", [fileList[totalFiles - 1]]);
                                        } else {
                                            setFieldValue("fileList", fileList);
                                        }
                                    }}
                                    beforeUpload={async (file) => {
                                        const unit8Arr = await fileToByteArray(file);
                                        setFieldValue("document_file", unit8Arr);
                                        setFieldValue("document_file_raw", file);
                                    }}
                                >
                                    {uploadIcon}
                                    <p className="ant-upload text">
                                        Drag the file here or
                                        <span className="secondary-color"> browse</span> from system
                                    </p>
                                </Upload.Dragger>
                            </FormItem>
                        </section>
                        <div className="action-btn-container">
                            <>
                                <Button className="cancel-btn" onClick={onCancel}>
                                    Cancel
                                </Button>
                                <Button
                                    htmlType="submit"
                                    type="primary"
                                    disabled={(!isValid && dirty)}
                                >
                                    Upload
                                </Button>
                            </>
                        </div>
                    </Form>
                </div>
            )}
        </Formik>
    );




}