import {useCallback, useMemo} from "react";
import {ORGANIZATION_FIRMWARE, ZONE_FORMIK} from "../../utils/formik-data";
import {addOrganization, addZone, setModalName} from "../../store/actions";
import {OrganizationValues, ZoneValues} from "../../types/formik";
import {Formik} from "formik";
import {Button, Form, Input, Typography} from "antd";
import {FormItem, SelectSearch} from "../../components";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../store";

const { Title, Text } = Typography;

export default function AddZoneModalForm() {
    const dispatch = useDispatch();
    const {
        zones: {zoneCoordinates},
    } = useSelector<AppState, AppState>((state) => state);
    const initialValues = useMemo(() => {
        return ZONE_FORMIK.initialValues
    }, []);

    console.log("zone coordinates from state: ", zoneCoordinates)


    const onCancels = useCallback(() => {
        dispatch(setModalName(undefined));
    }, [dispatch]);

    const onSubmit = useCallback(
        (values: ZoneValues) => {
            console.log("organization Values :  ", values)
            dispatch(addZone({
                name: values.name || "",
                coordinates: zoneCoordinates || []
            }))
            onCancels();
        },
        [dispatch,onCancels]
    );

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={ZONE_FORMIK.validationSchema}
            onSubmit={onSubmit}
            enableReinitialize
        >
            {({
                  setFieldValue,
                  dirty,
                  isValid,
                  handleSubmit,
                  handleChange,
                  values,
                  errors,
              }) => (
                <div className="form-container">
                    <Title level={5}>Add Zone</Title>
                    <Text className="description" type="secondary">
                    Please provide the following details to add new zone
                    </Text>
                    <br />
                     <br />

                    <Form className="--add-users-form" onFinish={handleSubmit}>
                        <section className="">
                            <FormItem label="Name" error={errors.name} required>
                                <Input
                                    name="name"
                                    value={values.name}
                                    onChange={handleChange}
                                    placeholder="Name"
                                />
                            </FormItem>

                        </section>
                        <div className="action-btn-container">
                            <>
                                <Button className="cancel-btn" onClick={onCancels}>
                                    Cancel
                                </Button>
                                <Button
                                    htmlType="submit"
                                    type="primary"
                                    disabled={(!isValid && dirty)}
                                >
                                    Save
                                </Button>
                            </>
                        </div>
                    </Form>
                </div>
            )}
        </Formik>
    );
}