import ReactCodeInput from "react-code-input";
import { useCallback, useState } from "react";
import { useHistory } from "react-router-dom";
import { useCookies } from "react-cookie";
import { addDays } from "date-fns";
import { Button } from "antd";
import { login, phoneVerification } from "store/actions";
import { ROUTES } from "utils/constants";
import { useDispatch, useSelector } from "react-redux";
import { LoadingScreen } from "components";
import { Formik } from "formik";
import { fluxNetworkIcon } from "svgs";
import { AppState } from "store";
import zypImage from "../images/zyp.png";

export default function LoginVerify() {
  const [value, setValue] = useState("");
  const history = useHistory();
  const dispatch = useDispatch();
  const [cookies, setCookies] = useCookies();
  const { phone } = useSelector<AppState, AppState["common"]>(
    (state) => state.common
  );

  const onChange = useCallback((val: string) => {
    setValue(val);
  }, []);

  const onSubmit = useCallback(() => {
    dispatch(
      login(value, (token, user) => {
        if (token) {
          setCookies(
            "meta",
            { token, user },
            { expires: addDays(new Date(), 30) }
          );
          history.push(ROUTES.users);
        }
      })
    );
  }, [value, dispatch]);

  const onResend = useCallback(() => {
    dispatch(
      phoneVerification(phone?.number || "", phone?.dialCode || "", () => {})
    );
  }, [phone, dispatch]);

  return (
    <Formik initialValues={{}} onSubmit={onSubmit}>
      {({ values, handleSubmit }) => (
        <form onSubmit={handleSubmit} className="login-form">
          <LoadingScreen />
          <section className="form-header">
            <p className="header-title"><img src={zypImage} height="70px" width="150px" alt="zyp" /></p>
            <p className="header-desc">Fleet Management System</p>
          </section>
          <section className="form-input">
            <p className="input-desc">
              Enter the six digit code that you just received on your mobile
              phone
            </p>

            <ReactCodeInput
              name=""
              value={value}
              inputMode="numeric"
              type="number"
              className="codes-input-container"
              fields={6}
              onChange={onChange}
            />
          </section>

          <section className="form-footer">
            <Button type="ghost" className="code-btn" onClick={onResend}>
              Resend Code
            </Button>
            <Button
              type="primary"
              className="next-btn"
              htmlType="submit"
              onKeyDown={onSubmit}
              disabled={value.length !== 6}
            >
              Verify
            </Button>
          </section>
        </form>
      )}
    </Formik>
  );
}
