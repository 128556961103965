import { Table } from "components/base";
import { TableProps } from "antd/lib/table";
import { addAssignee, vehicleAction } from "./cell-renders";
import { unassignedVehiclesData } from "./__mock__";
import { TabNavs } from "components";
import { useState } from "react";

const columns: TableProps<any>["columns"] = [
  {
    title: "Vehicle ID",
    dataIndex: "vehicle_no",
    render: vehicleAction,
    className: "open-link",
  },
  { title: "Model", dataIndex: "make_model" },
  {
    title: "",
    dataIndex: "add_assignee",
    className: "moved-left open-link",
    render: addAssignee,
  },
];

export default function UnassignedVehicles() {
  const [selectedTab, setSelectedTab] = useState("Drivers");

  return (
    <>
      <section className="dashboard-table-header ">
        <strong className="dashboard-table-title --vehicle">
          Un-Allocated Vehicles
        </strong>
      </section>
      <section className="vehicle-dashboard-tabs">
        <TabNavs
          selected={selectedTab}
          onChange={(value) => setSelectedTab(value)}
          options={[{ key: "Partners" }, { key: "Drivers" }]}
          showCount
        />
      </section>
      <Table
        customClasses="--dashboard-table "
        rowClassName="dashed-border --vehicle"
        dataSource={unassignedVehiclesData}
        columns={columns}
        pagination={false}
        scroll={{ y: 480 }}
      />
    </>
  );
}
