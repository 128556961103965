import { useCallback, useMemo } from "react";
import { Menu, Dropdown } from "antd";
import {useDispatch, useSelector} from "react-redux";
import { Link } from "react-router-dom";
import { ROUTES } from "utils/constants";

import { actionMenu } from "svgs";
import { VehiclesState } from "types/store";
import { getVehicle, setModalName, setSelectedVehicle } from "store/actions";
import {AppState} from "../../../store";

export default function DropdownAction({
  vehicle,
}: {
  vehicle: VehiclesState["selectedVehicle"];
}) {
  const dispatch = useDispatch();
  const {
      common:{userType=1}
  } = useSelector<AppState, AppState>((state) => state);
  const onEditShift = useCallback((e) => {
    e.domEvent.stopPropagation(); 
    dispatch(setSelectedVehicle(vehicle));
    dispatch(setModalName("edit_vehicle"));
  }, [dispatch, vehicle]);

  const onDelete = useCallback((e) => {
    e.domEvent.stopPropagation(); 
    dispatch(setSelectedVehicle(vehicle));
    dispatch(setModalName("delete_vehicle"));
  }, [dispatch, vehicle]);
 
  const onVehicleDetail = useCallback((e) => {
    e.domEvent.stopPropagation(); 
    dispatch(setSelectedVehicle(vehicle));
  }, [dispatch, vehicle]);

    const onUpdateFirmware = useCallback((e) => {
      e.domEvent.stopPropagation(); 
        dispatch(setSelectedVehicle(vehicle));
        dispatch(setModalName("firmware_vehicle"));
    }, [dispatch, vehicle]);

    const onMqttServerChange = useCallback((e) => {
      e.domEvent.stopPropagation(); 
        dispatch(setSelectedVehicle(vehicle));
        dispatch(setModalName("change_mqtt_server"));
    }, [dispatch, vehicle]);

  const menu = useMemo(
    () => (
      <Menu>
        <Menu.Item key="1">
          <Link
            to={ROUTES.vehicle_detail}
            onClick={onVehicleDetail} //() => dispatch(getVehicle(vehicle?.id || ""))
          >
            Vehicle Details
          </Link>
        </Menu.Item>
        {/* <Menu.Item key="2" className="firmware-action" onClick={onUpdateFirmware}>
          Update Firmware
        </Menu.Item>
        {userType == 1 || userType == 2 && <Menu.Item key="3" className="mqtt-action" onClick={onMqttServerChange}>
          Change Server
        </Menu.Item> } */}
        <Menu.Item key="3" className="delete-action" onClick={onDelete}>
          Archive
        </Menu.Item>

      </Menu>
    ),
    [onDelete, onEditShift]
  );

  return (
    <Dropdown overlay={menu} trigger={["click"]}>
      
      <a className="ant-dropdown-link"  onClick={(e) => e.stopPropagation()}>{actionMenu}</a>
    </Dropdown>
  );
}
