import { Switch, Redirect } from "react-router-dom";
import { ROUTES } from "utils/constants";
import TableView from "./table-view";
// import VehicleDetail from "./vehicle-detail";
import ProtectedRoute from "components/protected-route";
export default function VehicleModelsContent() {
    return (
        <div className="vehicle-models-content">
            <Switch>
                <ProtectedRoute exact path={ROUTES.vehicle_model}>
                    <Redirect to={ROUTES.vehicle_models_list} />
                </ProtectedRoute>
                <ProtectedRoute exact path={ROUTES.vehicle_models_list}>
                    <TableView />
                </ProtectedRoute>
                {/*<ProtectedRoute exact path={ROUTES.vehicle_model_detail}>*/}
                {/*    <VehicleDetail />*/}
                {/*</ProtectedRoute>*/}
                <ProtectedRoute path={`${ROUTES.vehicle_model}/*`}>
                    <Redirect to={ROUTES.vehicle_models_list} />
                </ProtectedRoute>
            </Switch>
        </div>
    );
}
