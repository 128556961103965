import { Formik } from "formik";
import { useCallback } from "react";
import { Form, Button, Typography, Input, Upload } from "antd";

import { FormItem, SelectSearch } from "components";
import { BATTERY_FIRMWARE } from "utils/formik-data";
import { BatteryValues } from "types/formik";
import { SelectType } from "types";
import {addBattery, setModalName} from "store/actions";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../store";

const { Title, Text } = Typography;

interface ComponentProps {
    initialValues: BatteryValues;
    setValues: (values: BatteryValues) => void;
    batteryModelOptions: SelectType;
    clientOptions: SelectType,
    onCancel?: React.MouseEventHandler<HTMLElement>;
}

export default function AddBatteryForm(props: ComponentProps) {
    const { initialValues, onCancel, setValues, batteryModelOptions, clientOptions } = props;

    const dispatch = useDispatch();
    const onCancels = useCallback(() => {
        dispatch(setModalName(undefined));
    }, [dispatch]);

    const onSubmit = useCallback(
        (values: BatteryValues) => {
            console.log("battery Values : ", values)
            dispatch(
                addBattery({
                    battery_id: values.battery_id.trim() || "",
                    operational_state: values.operational_state || "",
                    host_id: values.host_id || "",
                    battery_model_id: values.battery_model_id || "",
                    client_org_id: values.client_org_id || "",
                    battery_alias: values.battery_alias?.trim()|| "",
                })
            );
            onCancels();
        },
        [dispatch, onCancels]
    );

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={BATTERY_FIRMWARE.validationSchema}
            onSubmit={onSubmit}
            enableReinitialize
        >
            {({
                  setFieldValue,
                  dirty,
                  isValid,
                  handleSubmit,
                  handleChange,
                  values,
                  errors,
              }) => (
                <div className="form-container">
                    <Title level={5}>Add Battery</Title>
                    <Text className="description" type="secondary">
                     Please provide the following details to add a battery   
                     </Text>
                     <br />
                     
                    <Form className="--add-vehicles-form" onFinish={handleSubmit}>
                        <section className="">
                            <FormItem label="Battery ID"  error={errors.battery_id} required>
                                <Input
                                    name="battery_id"
                                    value={values.battery_id}
                                    onChange={handleChange}
                                    placeholder="Battery ID"
                                />
                            </FormItem>
                            <FormItem label="Battery Alias" error={errors.battery_alias} required>
                                <Input
                                    name="battery_alias"
                                    value={values.battery_alias}
                                    onChange={handleChange}
                                    placeholder="Battery Alias"
                                />
                            </FormItem>

                            <FormItem label="Battery Model" error={errors.battery_model_id} required>
                                <SelectSearch
                                    isMultiple={false}
                                    value={values.battery_model_id || ""}
                                    items={batteryModelOptions}
                                    setFieldValue={setFieldValue}
                                    fieldName="battery_model_id"
                                    placeholder="Select"
                                    onChangeProp={(value) => {
                                        setFieldValue("battery_model_id", value);
                                    }}
                                />
                            </FormItem>
                            {/*<FormItem error={errors.client_org_id} label="Organization">*/}
                            {/*    <SelectSearch*/}
                            {/*        value={values.client_org_id}*/}
                            {/*        items={clientOptions}*/}
                            {/*        setFieldValue={setFieldValue}*/}
                            {/*        fieldName="client_org_id"*/}
                            {/*        placeholder="Select "*/}
                            {/*    />*/}
                            {/*</FormItem>*/}
                        </section>
                        <div className="action-btn-container">
                            <>
                                <Button className="cancel-btn" onClick={onCancel}>
                                    Cancel
                                </Button>
                                <Button
                                    htmlType="submit"
                                    type="primary"
                                    disabled={(!isValid && dirty)}
                                >
                                    Save
                                </Button>
                            </>
                        </div>
                    </Form>
                </div>
            )}
        </Formik>
    );
}
