import { Button } from "antd";
import { useHistory } from "react-router-dom";
import { useCallback } from "react";
import { Formik } from "formik";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

import { ROUTES } from "utils/constants";
import { phoneVerification } from "store/actions";
import { LoadingScreen } from "components";
import { useDispatch } from "react-redux";
import { dialCodePrefix, numberWithoutCode } from "utils/helpers";
import { fluxNetworkIcon } from "svgs";
import zypImage from "images/zyp.png"

export default function LoginForm() {
  const history = useHistory();
  const dispatch = useDispatch();

  const nextPage = useCallback(() => {
    history.push(ROUTES.verify);
  }, [history]);
  const onSubmit = useCallback(
    ({ phone_no, dial_code }: { phone_no: string; dial_code: string }) => {
      dispatch(
        phoneVerification(
          numberWithoutCode(phone_no, dial_code),
          dialCodePrefix(dial_code),
          nextPage
        )
      );
    },
    [nextPage, dispatch]
  );

  return (
    <Formik initialValues={{ phone_no: "", dial_code: "" }} onSubmit={onSubmit}>
      {({ values, setFieldValue, handleSubmit }) => (
        <form onSubmit={handleSubmit} className="login-form">
          <LoadingScreen />
          <section className="form-header">
            <p className="header-title"><img src={zypImage} height="70px" width="150px" alt="zyp" /></p>
            <p className="header-desc">Fleet Management System</p>
          </section>
          <section className="form-input">
            <p className="input-desc">
              Provide your phone number to proceed to your fleet management
              portal
            </p>
            <PhoneInput
              country="pk"
              onKeyDown={(e) => {
                if (e.key === "Enter" && e.shiftKey === false) {
                  e.preventDefault();
                  values.phone_no && onSubmit(values);
                }
              }}
              inputProps={{
                name: "phone",
                required: true,
                autoFocus: true,
              }}
              value={values.phone_no}
              onChange={(val, data: { dialCode: string }) => {
                setFieldValue("dial_code", data.dialCode);
                setFieldValue("phone_no", val);
              }}
              inputClass="login-phone-input"
              buttonClass=""
              placeholder="12345678"
              defaultErrorMessage="Phone number not regestired. Please enter a verified phone number"
            />
          </section>
          <section className="form-footer">
            <Button
              type="primary"
              className="next-btn"
              htmlType="submit"
              disabled={!values.phone_no}>
              Next
            </Button>
          </section>
        </form>
      )}
    </Formik>
  );
}
