import { Layout as AntLayout } from "antd";
import { AppHeader, AppNav } from "components";

export default function FallbackLayout() {
  return (
    <AntLayout>
      <AppHeader toggleCollapse={() => {}} />
      <AntLayout className="body-content">
        <AppNav collapsed={false} />
      </AntLayout>
    </AntLayout>
  );
}
