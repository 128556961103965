import { Map } from "components";
import { GoogleMap, Polygon, LoadScript, useJsApiLoader } from '@react-google-maps/api';
import {MAP_API_KEY} from "../../../utils/constants";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../../store";
import {useEffect, useRef} from "react";
import {initializeZones} from "../../../store/actions";
import { Spin } from "antd";


const libraries: ("places" | "drawing" | "geometry" | "localContext" | "visualization")[] = ["places", "drawing"];
export default function MapView() {
  const dispatch = useDispatch();
  const {
    zones,
  } = useSelector<AppState, AppState["zones"]>((state) => state.zones);
  useEffect(() => {
    dispatch(initializeZones());
  }, [dispatch])

  const polygonCoordinates:any = [
    // Polygon 1
    // [
    //   { lat: 40.712776, lng: -74.005974 },
    //   { lat: 40.712776, lng: -74.008974 },
    //   { lat: 40.715776, lng: -74.008974 },
    //   { lat: 40.715776, lng: -74.005974 }
    // ],
    // Polygon 2
    // [
    //   { lat: 40.717776, lng: -74.005974 },
    //   { lat: 40.717776, lng: -74.008974 },
    //   { lat: 40.720776, lng: -74.008974 },
    //   { lat: 40.720776, lng: -74.005974 }
    // ]
    // Add more polygons as needed
  ];
  zones?.forEach((zone) => {
    polygonCoordinates.push(zone.latLngList)
  })

  const mapContainerStyle = {
    width: '100%',
    height: '400px'
  };

  let minLat = Number.MAX_VALUE;
  let maxLat = Number.MIN_VALUE;
  let minLng = Number.MAX_VALUE;
  let maxLng = Number.MIN_VALUE;

  polygonCoordinates.forEach((polygon:any) => {
    polygon.forEach((coord:any) => {
      minLat = Math.min(minLat, coord.lat);
      maxLat = Math.max(maxLat, coord.lat);
      minLng = Math.min(minLng, coord.lng);
      maxLng = Math.max(maxLng, coord.lng);
    });
  });

  const center = {
    lat: (minLat + maxLat) / 2,
    lng: (minLng + maxLng) / 2,
  };
  // const center = {
  //   lat: 31.585445404052734,
  //   lng: 74.32176208496094
  // };

  const mapRef:any = useRef(null);
  const onLoad = (map: google.maps.Map): void => {
    mapRef.current = map;
    const bounds = new google.maps.LatLngBounds();
    polygonCoordinates.forEach((polygon:any) => {
      polygon.forEach((coord:any) => {
        bounds.extend(coord);
      });
    });
    map.fitBounds(bounds);
  };

  useEffect(() => {
    if (mapRef.current) {
      const bounds = new window.google.maps.LatLngBounds();
      polygonCoordinates.forEach((polygon:any) => {
        polygon.forEach((coord:any) => {
          bounds.extend(coord);
        });
      });
      mapRef.current.fitBounds(bounds);
    }
  }, [polygonCoordinates]);

  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: MAP_API_KEY,
    libraries, 
  });

  if (loadError) {
    return <div>Error loading map</div>;
  }

  return (
    <>
    {!isLoaded ? (
      // Show loading spinner while the map is loading..

      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '400px' }}>
        <Spin size="large" />
      </div>
    ) : (
      <GoogleMap
        mapContainerStyle={mapContainerStyle}
        center={center}
        zoom={12}
        onLoad={onLoad}
      >
        {polygonCoordinates.map((coordinates:any, index:any) => (
          <Polygon
            key={index}
            paths={coordinates}
            options={{
              strokeColor: '#FF0000',
              strokeOpacity: 0.8,
              strokeWeight: 2,
              fillColor: '#FF0000',
              fillOpacity: 0.35,
            }}
          />
        ))}
      </GoogleMap>
    )}
  </>
  );
}
