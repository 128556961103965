import Text from "antd/lib/typography/Text";
import { completeIcon } from "svgs";

export default function EmptyState() {
  return (
    <div className="next-stepcomplete-state">
      {completeIcon} <h2 className="title">No tasks to complete</h2>
      <Text type="secondary" className="description">
        You are up to date. You have submitted the responses of all the queries.
      </Text>
    </div>
  );
}
