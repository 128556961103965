import ProtectedRoute from "components/protected-route";
import { Switch, Redirect } from "react-router-dom";
import { ROUTES } from "utils/constants";
import ListView from "./list-view";
import TableView from "./table-view";
import IssueDetail from "./issue-detail";

export default function IssuesContent() {
  return (
    <div className="issues-content">
      <Switch>
        <ProtectedRoute exact path={ROUTES.issues}>
          <Redirect to={ROUTES.issues_table} />
        </ProtectedRoute>
        <ProtectedRoute exact path={ROUTES.issues_table}>
          <TableView />
        </ProtectedRoute>
        <ProtectedRoute exact path={ROUTES.issues_list}>
          <ListView />
        </ProtectedRoute>
        <ProtectedRoute exact path={ROUTES.issue_detail}>
          <IssueDetail />
        </ProtectedRoute>
        <ProtectedRoute path={`${ROUTES.issues}/*`}>
          <Redirect to={ROUTES.issues_table} />
        </ProtectedRoute>
      </Switch>
    </div>
  );
}
