import {useCallback, useEffect, useMemo} from "react";
import { useDispatch, useSelector } from "react-redux";

import { AppState } from "store";
import { Table, TablePagination } from "components/base";
import {parseBatteryModelTable, parseBatteryTable} from "utils/parsers";
import {
    initializeVehicleModel,
    setIssuesSelectedPage,
    initializeBatteryModels, initializeBatteries,
} from "store/actions";
import { ActionMenu } from "./cell-renders";
import { TableProps } from "antd/lib/table";

const columns: TableProps<any>["columns"] = [
    {
        title: "Sr",
        dataIndex: "id",
        width: 130,
        ellipsis: true,
    },
    {
        title: "Make",
        dataIndex: "make",
        width: 130,
        ellipsis: true,
    },
    {
        title: "Model",
        dataIndex: "model",
        width: 130,
        ellipsis: true,
    },
    {
        title: "Year",
        dataIndex: "year",
        width: 130,
        ellipsis: true,
    },
    {
        title: "",
        dataIndex: "action",
        render: ActionMenu,
        align: "right",
        // width: 56,
        className: "action-menu open-link --issues",
    },
];

export default function TableView() {
  const dispatch = useDispatch();
  const {
    batteryModels,
    page,
    search,
    selectedBatteryModelRows,
    filters: { listType },
  } = useSelector<AppState, AppState["batteryModels"]>((state) => state.batteryModels);
  const { selectedPage, totalPages } = page;

    const data = useMemo(
        () => parseBatteryModelTable(search, batteryModels, listType),
        [batteryModels, listType, search]
    );
    useEffect(() => {
        dispatch(initializeBatteryModels());
    }, [dispatch]);
    console.log("data of battery model : ", data)

  const onChangeSelection = useCallback(
    (value) => {
        console.log("values : ", value)
    },
    []
  );


  const onBack = useCallback(() => {
    if (selectedPage > 1) {
      dispatch(setIssuesSelectedPage(selectedPage - 1));
    }
  }, [dispatch, selectedPage]);

  const onNext = useCallback(() => {
    if (selectedPage < totalPages) {
      dispatch(setIssuesSelectedPage(selectedPage + 1));
    }
  }, [dispatch, selectedPage, totalPages]);

  const resetCallback = useCallback(() => {
    dispatch(setIssuesSelectedPage(1));
  }, [dispatch]);

  return (
    <>
      <Table
        columns={columns}
        dataSource={data}
        // rowSelection={{
        //   selectedRowKeys: selectedBatteryModelRows,
        //   onChange: onChangeSelection,
        // }}
        pagination={false}
        scroll={{ y: "70vh" }}
      />
      {/*<TablePagination*/}
      {/*  page={page}*/}
      {/*  onBack={onBack}*/}
      {/*  onNext={onNext}*/}
      {/*  resetCallback={resetCallback}*/}
      {/*/>*/}
    </>
  );
}
