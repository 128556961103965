import { Collapse, Button, Input } from "antd";
import { useDispatch, useSelector } from "react-redux";

import { AppState } from "store";
import { cancelIcon, chevronClose, chevronOpen, seperatorIcon } from "svgs";
import { Checkbox, DatePicker, SwitchButton } from "components/base";
import { useCallback, useMemo } from "react";
import {
  onChangeIssuesFilters,
  onChangeRootIssuesFilters,
} from "store/actions";
import { getAllCheckedStatus, getIndeterminate } from "utils/helpers";
import { CheckboxPanelList } from "./sub-components";

const { Panel } = Collapse;
const { RangePicker } = DatePicker;

export default function ClientsFilter() {
  const dispatch = useDispatch();
  const { filters } = useSelector<AppState, AppState["issues"]>(
    (state) => state.issues
  );

  const { "Time Range": timeRangeUpdated, ...restUpdated } = filters.updated;
  const { "Time Range": timeRangeOriginal, ...restOriginal } = filters.original;

  const isAllChecked = useMemo(() => {
    return getAllCheckedStatus(restUpdated, restOriginal);
  }, [restUpdated, restOriginal]);
  const isIndeterminate = useMemo(() => {
    return getIndeterminate(restUpdated, restOriginal);
  }, [restUpdated, restOriginal]);

  const onCheckboxListChange = useCallback(
    (name, value, type) => {
      dispatch(onChangeIssuesFilters(name, value, type));
    },
    [dispatch]
  );

  const originalClients = [
    { label: "Foodpanda", value: "Foodpanda" },
    { label: "TCS", value: "TCS" },
    { label: "Leopord Courier", value: "Leopord Courier" },
    { label: "Bike 4 Everything", value: "Bike 4 Everything" },
    { label: "M&P Courier", value: "M&P Courier" },
    { label: "Bykea", value: "Bykea" },
    { label: "Careem", value: "Careem" },
    { label: "Uber", value: "Uber" },
    { label: "Albaik", value: "Albaik" },
    { label: "Flipkart", value: "Flipkart" },
    { label: "eBikeGO", value: "eBikeGO" },
    { label: "Zomato", value: "Zomato" },
    { label: "Rapido", value: "Rapido" },
    { label: "Deliveroo", value: "Deliveroo" },
    { label: "Pizza Hut", value: "Pizza Hut" },
    { label: "Talabat", value: "Talabat" },
  ];

  const onRootChange = useCallback(
    (event) => {
      dispatch(onChangeRootIssuesFilters(event.target.checked));
    },
    [dispatch]
  );
  const onClearAll = useCallback(() => {
    dispatch(onChangeRootIssuesFilters(false));
  }, [dispatch]);

  return (
    <div className="common-filter">
      <div className="__filter-header">
        <div className="all-options">
          <Checkbox
            customClasses="--filter-light"
            onChange={onRootChange}
            checked={isAllChecked}
            indeterminate={isIndeterminate}>
            <span className="filter-text">FILTERS</span>
          </Checkbox>
          <Button
            className="clear-all-btn"
            icon={cancelIcon}
            type="link"
            danger
            onClick={onClearAll}>
            Clear All
          </Button>
        </div>
        <SwitchButton
          label=""
          options={["current", "archived"]}
          defaultOption={"current"}
          // onChange={(e) => onListTypeChange(e.target.value)}
          type="secondary"
        />
      </div>
      <Collapse
        className="__common-collapse --issues"
        ghost
        expandIcon={({ isActive }) => (isActive ? chevronOpen : chevronClose)}
        expandIconPosition="right"
        // defaultActiveKey={Object.keys(filters.original)}
        defaultActiveKey={["2"]}>
        <CheckboxPanelList
          header={""}
          key={"Clients"}
          name={"Organizations"}
          options={originalClients}
          value={[]}
          onChange={onCheckboxListChange}
          disabled
          className="disabled"
        />
        <Panel header="Vehicle Count" key="2" className="input-container">
          <article>
            <label htmlFor="#from">From</label>
            <Input type="number" id="from" />
          </article>
          <article>
            <label htmlFor="#to">To</label>
            <Input type="number" id="to" />
          </article>
        </Panel>
        <Panel header="Onboarding Date" key="3" className="">
          <RangePicker
            // value={}
            className="issues-range-picker"
            // onChange={onChangeRange}
            dropdownClassName="issues-range-picker-popup"
            placeholder={["Start", "End"]}
            separator={seperatorIcon}
            format="MMM DD"
          />
        </Panel>
      </Collapse>
    </div>
  );
}
