import { getRandomString, range } from "utils/helpers";

export const financialsTableData = () =>
  range(10).map((item, index) => ({
    key: index,
    payment_status: getRandomString(["paid", "outstanding"]),
    payment_type: getRandomString([
      "Lease",
      "Salik",
      "Traffic Fine",
      "Service & Repair",
    ]),
    amount: getRandomString(["1,200", "100", "500", "150"]),
    vehicle_count: getRandomString(["40", "45", "50", "55", "60"]),
    date: getRandomString(
      [
        "Mar 19, 2021  10:53 pm",
        "Mar 11, 2021  11:00 pm",
        "Mar 02, 2021  06:15 pm",
        "Feb 23, 2021  04:30 pm",
        "Feb 18, 2021  10:53 pm",
        "Feb 10, 2021  11:00 pm",
        "Feb 02, 2021  06:15 pm",
        "Jan 22, 2021  04:30 pm",
        "Jan 12, 2021  10:53 pm",
        "Jan 06, 2021  11:00 pm",
      ],
      index
    ),
    toll_gate: getRandomString(["Jebel Ali Salik Gate"]),
    cause_of_fine: getRandomString(["Overspeeding"]),
    workshop: getRandomString(["Rapido Garage"]),
    client: getRandomString([
      "Foodpanda",
      "eBikeGo",
      "Pizza Hut",
      "Careem",
      "Uber",
      "Zomato",
    ]),
  }));
