import { ReactText, useCallback } from "react";
import { Typography } from "antd";
import { format } from "date-fns";
import {
  accidentIcon,
  breakdownIcon,
  serviceIcon,
  vehicleMissingIcon,
  timeIcon,
  bikeIcon,
  editIcon,
  archiveIcon,
  restoreIcon,
} from "svgs";
import { IssuesState, IssuesTableRow, KeyValue } from "types";
import { classNames } from "utils/helpers";
import { Tag } from "components/base";
import { ISSUE_STATUS, ISSUE_TYPE } from "utils/mapped-data";
import { IssueStatus, IssueType } from "protobuf/issue_service_pb";
import { DATE_FORMAT, TIME_FORMAT } from "utils/constants";
import { issuesTableData } from "./__mock__";
import { useDispatch } from "react-redux";
import { setModalName, setSelectedIssue } from "store/actions";
import DropdownAction from "./dropdown-action";

const { Text } = Typography;

const issuesIcon: KeyValue = {
  accident: accidentIcon,
  breakdown: breakdownIcon,
  service: serviceIcon,
  "vehicle missing": vehicleMissingIcon,
};

export function ActionMenu(
  value: string,
  row: typeof issuesTableData[number] & {
    raw: IssuesState["selectedIssue"];
    listType: IssuesState["filters"]["listType"];
  }
) {
  const dispatch = useDispatch();

  const onArchiveIssue = useCallback(() => {
    dispatch(setSelectedIssue(row.raw));
    dispatch(setModalName("delete_issue"));
  }, [dispatch, row.raw]);
  const onUnArchiveIssue = useCallback(() => {
    dispatch(setSelectedIssue(row.raw));
    dispatch(setModalName("undo_delete_issue"));
  }, [dispatch, row.raw]);

  const onEditIssue = useCallback(() => {
    dispatch(setSelectedIssue(row.raw));
    dispatch(setModalName("edit_issue"));
  }, [dispatch, row.raw]);

  return (
    <section className="table-action-container update-issue show-on-hover">
      <span className="edit-icon icon" onClick={onEditIssue} title="edit issue">
        {editIcon}
      </span>
      {/* {row.listType === "current" ? (
        <span
          className="delete-icon icon"
          onClick={onArchiveIssue}
          title="archive issue">
          {archiveIcon}
        </span>
      ) : (
        <span
          className="delete-icon icon"
          onClick={onUnArchiveIssue}
          title="re-open issue">
          {restoreIcon}
        </span>
      )} */}
      <DropdownAction issue={row.raw} />
    </section>
  );
}

export function issueTypeCell(type: IssueType.Enum) {
  return ISSUE_TYPE[type];
}

export function statusCell(status: IssueStatus.Enum) {
  const statusStr = ISSUE_STATUS[status];
  const classes = classNames({
    "--green": statusStr === "Completed",
    "--gray": statusStr === "Pending",
    "--red": statusStr === "Cancelled",
  });

  return (
    <Tag customClasses={`status-tag --fixed-width ${classes}`}>{statusStr}</Tag>
  );
}

export function renderEmptyContent(value: ReactText) {
  return {
    children: value,
    props: {
      colSpan: 0,
    },
  };
}

export function issueIconCell(value: string, row: IssuesTableRow) {
  const issueType = ISSUE_TYPE[row.issue_type].toLowerCase();
  return <figure className="icon-container">{issuesIcon[issueType]} </figure>;
}

export function issueDetailCell(value: string, row: IssuesTableRow) {
  const issueType = ISSUE_TYPE[row.issue_type].toLowerCase();
  const status = ISSUE_STATUS[row.status].toLowerCase();
  const issueTypeClasses = classNames({
    "--purple": issueType === "accident",
    "--orange": issueType === "breakdown",
    "--blue": issueType === "needs checkup",
    "--yellow": issueType === "vehicle missing",
  });
  const statusClasses = classNames({
    "--green": status === "completed",
    "--gray": status === "pending",
    "--red": status === "cancelled",
  });
  const date = new Date(row.date_and_time);

  return (
    <p className="list-detail">
      <Text className="list-id" type="secondary">
        <strong className="dot-separator">#{row.issue_id}</strong> Assignee is{" "}
        <strong>{row.assignee}</strong>
      </Text>
      <Text type="secondary">
        Issue is created by {row.created_by} on {format(date, DATE_FORMAT)}
        <span className="icon --time">{timeIcon}</span>
        {format(date, TIME_FORMAT)}
        <span className="icon --bike"> {bikeIcon}</span>
        {row.vehicle_id}
        <Tag customClasses={`issue-type-tag --fixed-width ${issueTypeClasses}`}>
          {issueType}
        </Tag>
        <Tag customClasses={`--fixed-width ${statusClasses}`}>{status}</Tag>
      </Text>
    </p>
  );
}
