import { useCallback, useState } from "react";
import { Button, Input, Typography } from "antd";

import { useDispatch } from "react-redux";
import { USER_TYPE } from "utils/mapped-data";
import { setModalName } from "store/actions";
import { editIcon, plusIcon } from "svgs";

const { Text } = Typography;

export default function UserRoles() {
  const [addRole, setAddRole] = useState(false);

  const dispatch = useDispatch();

  const onEditRole = useCallback(() => {
    dispatch(setModalName("edit_role"));
  }, [dispatch]);
  return (
    <section className=" grid-container user-roles">
      <Text type="secondary" className="title">
        User Roles
      </Text>
      <span className="setting">
        <Text>
          Edit or add the roles that can be assigned to users within app.
          <span onClick={onEditRole} className="edit-role">
            {editIcon} Edit Roles
          </span>
        </Text>
        <figure className="roles-list">
          {Object.values(USER_TYPE).map((role, i) => (
            <Text type="secondary" key={i}>
              {role}
            </Text>
          ))}
        </figure>
        {!addRole ? (
          <figure className="add-new" onClick={() => setAddRole(true)}>
            {plusIcon} Add Role
          </figure>
        ) : (
          <figure className="add-new-data">
            <Input />
            <Button type="primary" onClick={() => setAddRole(false)}>
              Add
            </Button>
            <Button onClick={() => setAddRole(false)}>Cancel</Button>
          </figure>
        )}
      </span>
    </section>
  );
}
