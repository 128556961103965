import { Dispatch } from "react";

import { AuthcServiceClient } from "protobuf/Authc_serviceServiceClientPb";
import {
  AddShakeHandsRequest,
  AddShakeHandsResponse,
  PhoneLoginRequest,
  PhoneLoginResponse,
  StartPhoneSignupRequest,
  StartPhoneSignupResponse,
  Token,
  DurationType,
  RefreshRequest,
  RefreshResponse,
} from "protobuf/authc_service_pb";
import { PhoneNumber } from "protobuf/user_service_pb";
import { authcServiceClient } from "utils/grpc-clients";
import { CommonActions, CommonState, GetState, UserDataItem } from "types";
import { apiResponse } from "./common-actions";
import { ERROR_MESSAGES } from "utils/constants";

export function setToken(payload: CommonState["token"]): CommonActions {
  return {
    type: "SET_TOKEN",
    payload,
  };
}

export function setRefreshToken(
  payload: CommonState["refreshToken"]
): CommonActions {
  return {
    type: "SET_REFRESH_TOKEN",
    payload,
  };
}
export function setOrganizationID(
    payload: CommonState["organizationId"]
): CommonActions {
  return {
    type: "SET_ORGANIZATION_ID",
    payload,
  };
}

export function setOwnerOrgId(
  payload: CommonState["ownerOrgId"]
): CommonActions {
  return {
    type: "SET_ASSETOWNER_ID",
    payload,
  };
}

export function setConsigneeId(
  payload: CommonState["clientOrgId"]
): CommonActions {
  return {
    type: "SET_CONSIGNEE_ID",
    payload,
  };
}

export function setUserType(payload: CommonState["userType"]): CommonActions {
  return {
    type: "SET_USER_TYPE",
    payload,
  };
}

export function setPhoneNo(payload: CommonState["phone"]): CommonActions {
  return {
    type: "SET_PHONE",
    payload,
  };
}

export function phoneVerification(
  number: string,
  countryPrefix: string,
  callback: () => void
) {
  return async (dispatch: Dispatch<any>) => {
    const phoneNumber = new PhoneNumber();
    phoneNumber.setCountryPrefix(countryPrefix);
    phoneNumber.setNumber(number);

    const request = new StartPhoneSignupRequest();
    request.setPhoneNumber(phoneNumber);

    function dispatcher() {
      callback();
    }

    dispatch(setPhoneNo({ dialCode: countryPrefix, number }));
    dispatch(
      apiResponse<
        AuthcServiceClient,
        StartPhoneSignupRequest,
        StartPhoneSignupResponse
      >({
        client: authcServiceClient,
        request,
        action: "startPhoneVerification",
        dispatcher,
      })
    );
  };
}

export function login(
  code: string,
  callback: (token?: Token.AsObject, user?: UserDataItem) => void
) {
  return async (dispatch: Dispatch<any>, getState: GetState) => {
    const { phone } = getState().common;

    const phoneNumber = new PhoneNumber();
    phoneNumber.setCountryPrefix(phone?.dialCode || "");
    phoneNumber.setNumber(phone?.number || "");

    const request = new PhoneLoginRequest();
    request.setPhoneNumber(phoneNumber);
    request.setCode(code);

    function dispatcher(resp: PhoneLoginResponse) {
      const token = resp?.getToken()?.toObject();
      const user = resp?.getUser()?.toObject();
      callback(token, user);
      dispatch(setToken(`Bearer ${token?.accessToken}`));
      dispatch(setRefreshToken(token?.refreshToken));
      dispatch(setOrganizationID(user?.organizationId))
    }

    dispatch(
      apiResponse<AuthcServiceClient, PhoneLoginRequest, PhoneLoginResponse>({
        client: authcServiceClient,
        request,
        action: "phoneLogin",
        dispatcher,
        errorMessage: ERROR_MESSAGES.verifyCode,
      })
    );
  };
}

export function sendEnquiry({
  document,
  email,
  model,
  phone,
  type,
  dispatcher,
}: {
  dispatcher: VoidFunction;
  document: Uint8Array | string;
  email: string;
  model: string;
  phone: {
    countryPrefix: string;
    number: string;
  };
  type: DurationType.Enum;
}) {
  return (dispatch: Dispatch<any>) => {
    const phoneNumber = new PhoneNumber();
    phoneNumber.setCountryPrefix(phone?.countryPrefix || "");
    phoneNumber.setNumber(phone?.number || "");

    const phoneRequest = new PhoneNumber();
    phoneRequest.setCountryPrefix(phone.countryPrefix);
    phoneRequest.setNumber(phone.number);

    const request = new AddShakeHandsRequest();
    request.setDocument(document);
    request.setEmail(email);
    request.setModelIdsList([model]);
    request.setPhoneNumber(phoneNumber);
    request.setType(type);

    dispatch(
      apiResponse<
        AuthcServiceClient,
        AddShakeHandsRequest,
        AddShakeHandsResponse
      >({
        request,
        dispatcher,
        client: authcServiceClient,
        action: "addShakeHandsRecord",
      })
    );
  };
}

export function refreshingToken(
  refreshToken: string,
  callback: (newToken: string) => void
) {
  return async (dispatch: Dispatch<any>) => {
    const request = new RefreshRequest();
    request.setRefreshToken(refreshToken);

    function dispatcher(resp: RefreshResponse) {
      const token = resp?.getToken()?.toObject();
      const tokenStr = `Bearer ${token?.accessToken}`;
      dispatch(setToken(tokenStr));
      callback(tokenStr);
    }

    dispatch(
      apiResponse<AuthcServiceClient, RefreshRequest, RefreshResponse>({
        client: authcServiceClient,
        request,
        action: "refreshToken",
        dispatcher,
      })
    );
  };
}
