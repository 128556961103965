import { Button } from "antd";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";

import { Input, SwitchButton } from "components/base";
import { setModalName } from "store/actions";
import { exportIcon, plusIcon, searchIcon } from "svgs";
import { ROUTES } from "utils/constants";
import { AppState } from "store";
import {AddZoneForm} from "../../forms";

export default function ZonesHeader() {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  const selectedView = location.pathname.split("/")[2];
  const { showbulkActionsZones } = useSelector<AppState, AppState["zones"]>(
    (state) => state.zones
  );

  // const onImportZones = useCallback(() => {
  //   dispatch(setModalName("import_zones"));
  // }, [dispatch]);

    const onAddZone = () => {
        history.push(ROUTES.add_zone)
    }

  const onSwitchView = useCallback(
    (event) => {
      const { value } = event.target;
      history.push(`${ROUTES.zones}/${value}`);
    },
    [history]
  );

  return (
    <div className="common-header">
      {/* <Input
        customClasses="--standard"
        placeholder="Search Zones..."
        prefix={searchIcon}
      /> */}
      {" "}
      {showbulkActionsZones && (
        <article className="bulk-actions">
          {/* <Button disabled icon={exportIcon}>
            Export
          </Button> */}
          {/* <Button disabled danger icon={archiveIcon}>
            Archive
          </Button> */}
        </article>
      )}
      <div className="actions-container">
        <SwitchButton
          label="Select View:"
          options={["map", "list"]}
          defaultOption={selectedView ?? "list"}
          onChange={onSwitchView}
          type="secondary"
        />
        <div className="vertical-separator" />
        {/* <Button
          className="export-btn"
          disabled
          icon={<span className="custom-icon">{exportIcon}</span>}>
          Export
        </Button> */}
        <Button
          type="primary"
          icon={<span className="custom-icon">{plusIcon}</span>}
          onClick={onAddZone}
          >
          Add Zone
        </Button>
      </div>
    </div>
  );
}
