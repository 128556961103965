import {Battery, ClientsActions, ClientsState, GetState, OrganizationClient} from "types";
import {
  batteryManagmentServiceClient,
  organizationManagementServiceClient
} from "utils/grpc-clients";
import {
  AddBatteryRequest, AddBatteryResponse,
  BatteryItem,
  BatteryUpdateItem,
  GetBatteriesRequest,
  GetBatteriesResponse
} from "../../protobuf/battery_service_pb";
import {Dispatch} from "react";
import {apiResponse} from "./common-actions";
import {BatteryManagementServiceClient} from "../../protobuf/Battery_serviceServiceClientPb";
import {initializeBatteries, setBatteries} from "./battery-actions";
import {
  AddOrganizationRequest, AddOrganizationResponse,
  ListOwnerOrgAdminRequest,
  ListOwnerOrgAdminResponse,
  OwnerOrgItem, UpdateOrganizationRequest, UpdateOrganizationResponse, UpdateOwnerOrgItem
} from "../../protobuf/client_service_pb";
import {OrganizationAdminServiceClient} from "../../protobuf/Client_serviceServiceClientPb";
import {SUCCESS_MESSAGES} from "../../utils/constants";
import {Address} from "../../protobuf/user_service_pb";


export function initializeClients(
){
  const request = new ListOwnerOrgAdminRequest()
  return async (dispatch: Dispatch<any>) => {
    function dispatcher(resp: ListOwnerOrgAdminResponse) {
      const organizations: OrganizationClient[] = resp?.getOwnerOrgsList()?.map(organizations => ({
        ...(organizations.getOwnerOrg()?.toObject() as OwnerOrgItem.AsObject),
        ownerOrgName: "Test Owner"
      }));
      console.log("organizations from DB: ", organizations)
      dispatch(setClientList(organizations))
    }
    dispatch(
        apiResponse<
            OrganizationAdminServiceClient,
            ListOwnerOrgAdminRequest,
            ListOwnerOrgAdminResponse
            >({
          client: organizationManagementServiceClient,
          action: "listOwnerOrgs",
          request,
          dispatcher,
        })
    );
  };
}


export function addOrganization({
    name,
    street_address,
    city,
    state,
    country, org_type} : {
  name:string,
  street_address:string,
  city:string,
  state:string,
  country:string,
    org_type:string,
}) {
  return (dispatch: Dispatch<any>, getState: GetState) => {

    const request = new AddOrganizationRequest();
    const orgOwnerItem = new UpdateOwnerOrgItem();
    const address = new Address();
    console.log("org_type : ", org_type)
    address.setStreetAddress1(street_address)
    address.setStreetAddress2(street_address)
    address.setCity(city)
    address.setState(state)
    address.setCountry(country)
    orgOwnerItem.setName(name);
    orgOwnerItem.setAddress(address)
      orgOwnerItem.setOrgType(org_type)
    request.setOwnerItem(orgOwnerItem)
    function dispatcher() {
      dispatch(initializeClients());
    }

    dispatch(
        apiResponse<
            OrganizationAdminServiceClient,
            AddOrganizationRequest,
            AddOrganizationResponse
            >({
          request,
          client: organizationManagementServiceClient,
          action: "addOrganization",
          successMessage: SUCCESS_MESSAGES.addOrganization,
          dispatcher,
        })
    );
  };
}

export function updateOrganization({
                                 id,
                                  name,
                                  street_address,
                                  city,
                                  state,
                                  country,org_type} : {
  id:string
  name:string,
  street_address:string,
  city:string,
  state:string,
  country:string
  org_type:string,
}) {
  return (dispatch: Dispatch<any>, getState: GetState) => {

    const request = new UpdateOrganizationRequest();
    const orgOwnerItem = new UpdateOwnerOrgItem();
    const address = new Address();

    address.setStreetAddress1(street_address)
    address.setStreetAddress2(street_address)
    address.setCity(city)
    address.setState(state)
    address.setCountry(country)
    orgOwnerItem.setName(name);
    orgOwnerItem.setAddress(address)
      orgOwnerItem.setOrgType(org_type)
    request.setOwnerItem(orgOwnerItem)
    request.setId(id)
    function dispatcher() {
      dispatch(initializeClients());
    }

    dispatch(
        apiResponse<
            OrganizationAdminServiceClient,
            UpdateOrganizationRequest,
            UpdateOrganizationResponse
            >({
          request,
          client: organizationManagementServiceClient,
          action: "updateOrganization",
          successMessage: SUCCESS_MESSAGES.updateOrganization,
          dispatcher,
        })
    );
  };
}

export function setBulkActionsClients(
  payload: ClientsState["showbulkActionsClients"]
): ClientsActions {
  return {
    type: "SET_CLIENT_BULK_ACTIONS",
    payload,
  };
}

export function setClientList(
    payload: ClientsState["organizations"]
): ClientsActions{
  return {
    type: "SET_CLIENTS",
    payload
  }
}
export function setSelectedClient(
    payload: ClientsState["selectedOrganization"]
): ClientsActions {
    return {
        type: "SET_SELECTED_ORGANIZATION",
        payload
    }
}
