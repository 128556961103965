import { IssueStatus, IssueType } from "protobuf/issue_service_pb";
import { VehicleModelsActions, VehicleModelState } from "types/store";

const originalFilters = {
    Assignee: [],
};
const updatedFilters = {
    Assignee: [],
};

const initialState: VehicleModelState = {
    page: {
        selectedPage: 1,
        totalElements: 0,
        totalPages: 0,
    },
    filters: {
        original: originalFilters,
        updated: updatedFilters,
        listType: "current",
    },
    search: "",
    showbulkActionsModels: false,
    selectedModelsRows: [],
};

export default function vehicleModelsReducer(
    state: VehicleModelState = initialState,
    action: VehicleModelsActions
): VehicleModelState {
    switch (action.type) {
        case "SET_VEHICLE_MODEL":
            return { ...state, models: action.payload };
        case "SET_VEHICLE_MODEL_SELECTED_PAGE":
            return {
                ...state,
                page: { ...state.page, selectedPage: action.payload },
            };
        case "SET_VEHICLE_MODEL_TOTAL_PAGE":
            return { ...state, page: { ...state.page, ...action.payload } };
        case "SET_VEHICLE_MODEL_ORIGINAL_FILTERS":
            return {
                ...state,
                filters: { ...state.filters, original: action.payload },
            };
        case "SET_VEHICLE_MODELS_UPDATED_FILTERS":
            return {
                ...state,
                filters: { ...state.filters, updated: action.payload },
            };
        case "SET_VEHICLE_MODEL_SEARCH":
            return {
                ...state,
                search: action.payload,
            };
        case "SET_SELECTED_VEHICLE_MODEL":
            return {
                ...state,
                selectedModels: action.payload,
            };
        case "SET_VEHICLE_MODEL_BULK_ACTIONS":
            return {
                ...state,
                showbulkActionsModels: action.payload,
            };
        case "SET_VEHICLE_MODELS_SELECTED_ROWS":
            return {
                ...state,
                selectedModelsRows: action.payload,
            };
        case "SET_VEHICLE_MODELS_LIST_TYPE":
            return {
                ...state,
                filters: {
                    ...state.filters,
                    listType: action.payload,
                },
            };
        default:
            return state;
    }
}
