import { Typography } from "antd";

import CustomColors from "./custom-colors";

const { Text } = Typography;
export default function Theming() {
  return (
    <section className=" grid-container theming">
      <Text type="secondary" className="title">
        Theming
      </Text>
      <span className="setting">
        <Text>
          Choose the colors you would like to keep for primary and secondary
          actions. Primary actions will include buttons <br />
          and secondary actions will include links and selection
        </Text>
        <CustomColors />
      </span>
    </section>
  );
}
