import { Switch, Typography } from "antd";

const { Text } = Typography;

export default function UploadParagraph() {
  return (
    <section className=" grid-container boolean">
      <Text type="secondary" className="title">
        Vehicle Photos
      </Text>
      <span className="setting boolean">
        <Text>
          Do you want to require the rider to upload a photo of the vehicle
          before checkout and upon return (to document the physical condition of the
          vehicle)?
        </Text>
        <figure className="upload">
          <Text type="secondary" className="upload-title">
            Upload Photographs
          </Text>
          <span className="toggle">
            <Switch defaultChecked size="small" />
          </span>
        </figure>
      </span>
    </section>
  );
}
