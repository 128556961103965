import { ReactNode } from "react";
import { useLocation } from "react-router-dom";

import { ROUTES } from "utils/constants";
interface ComponentProps {
  classes?: {
    header?: string;
    tabs?: string;
    content?: string;
    filter?: string;
    enquiry?: string;
  };
  children: {
    header?: ReactNode;
    tabs?: ReactNode;
    content: ReactNode;
    filter?: ReactNode;
    enquiry?: ReactNode;
  };
}

export default function InnerLayout(props: ComponentProps) {
  const { children, classes = {} } = props;
  const { header, content, filter, tabs, enquiry } = children;

  return (
    <div className="inner-layout-container">
      {header && (
        <div className={`tab-header-container ${classes.header || ""}`}>
          {header}
        </div>
      )}
      {tabs && (
        <div className={`tab-nav-container ${classes.tabs || ""}`}>{tabs}</div>
      )}
      <div className={`content-body ${classes.content}`}>
        <div className={`content-container ${classes.content}`}>{content}</div>
        {filter && (
          <div className={`filter-container ${classes.filter}`}>{filter}</div>
        )}
        {enquiry && (
          <div className={`enquiry-container ${classes.enquiry}`}>
            {enquiry}
          </div>
        )}
      </div>
    </div>
  );
}
