/// <reference types="google.maps" />
import {useEffect, useMemo, useRef, useState} from "react";
import { google, Loader, LoaderOptions } from "google-maps";

import { MAP_API_KEY } from "utils/constants";
import markerSvg from "images/marker.svg";
import {KeyValue, markerObject} from "types";
import { CloseOutlined } from "@ant-design/icons";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../store";
import {getVehiclesLocations, initializeVehicles, setMapCenter} from "store/actions";
import {useCookies} from "react-cookie";
import {addDays} from "date-fns";
import { useInterval } from "utils/hooks";
import { POLLING_MSECS } from "utils/constants";
import {functionalStatusOnOffCell, getMapFunctionalStatusOnOffCell} from "../tab-content/vehicles-content/cell-renders";


interface ComponentProps {
    apiKey?: string;
    markersPoint?: { id: string; lat: number; lng: number, licence_plate:string, updated_time:string, functional_status:string,checked_out_name:string}[];
    center: markerObject
}

interface State {
    google?: google;
    map?: google.maps.Map;
}

const options: LoaderOptions = {};
const getLoader = (apiKey: string, opt: LoaderOptions = options) =>
    new Loader(apiKey, opt);
//let center = { lat: 31.501660, lng: 74.361969 };

let markers: KeyValue<google.maps.Marker> = {};
export default function Map(props: ComponentProps) {
    const { apiKey = MAP_API_KEY, markersPoint, center } = props;
    const [{ google, map }, setState] = useState<State>({});
    const {
        filters: { updated },
        page: { selectedPage },
    } = useSelector<AppState, AppState["vehicles"]>((state) => state.vehicles);
    const setInfoWindow = (marker:{id: string, lat: number, lng: number, licence_plate: string, updated_time: string, functional_status: string, checked_out_name: string}, markers:KeyValue<google.maps.Marker>,  google:google, map:google.maps.Map) => {
        //display info window on vehicle marker to display licenceplat
        let infoBox = `
            <table class="common-table" style="text-align: left;">
            <tr><td  style="text-align: left;"><b>${marker.licence_plate}</b></td></tr>
            <tr class="ant-table-cell"><td  style="text-align: left;">Functional Status: ${getMapFunctionalStatusOnOffCell(marker.functional_status)}</td></tr>
             <tr><td  style="text-align: left;">Checkout By: <b>${marker.checked_out_name}</b></td></tr>
            <tr><td  style="text-align: left;">Location Updated: <b>${marker.updated_time}</b></td></tr>
   
            </table>
        `;
        var infoWindowOptions = {
            content: infoBox,
            ariaLabel: 'Vehicle Details',
            title: "CSV"
        }
        var infoWindow = new google.maps.InfoWindow(infoWindowOptions);
        //default marker point is open
        //infoWindow.open(map, markers[marker.id]);
        //click on marker point to display info window content
        markers[marker.id].addListener('click', function() {
            infoWindow.open(map, markers[marker.id]);
        });
        //return infoWindow;
        //end code for info window
    }

     // Ref to store map markers
     const markersRef = useRef<{ [key: string]: google.maps.Marker }>({});
    useEffect(() => {

       // Function to clear all markers
       const clearMarkers = () => {
        Object.values(markersRef.current).forEach((marker) => {
          if (marker) {
            marker.setMap(null);
          }
        });
        markersRef.current = {}; // Reset markers
      };
  
      // Clear existing markers on each update
      clearMarkers();
       
        if (map && google) {
            markersPoint?.forEach((marker) => {
                if (markersRef.current[marker.id]) {

                    markersRef.current[marker.id].setMap(null);
                } else {
                    markersRef.current[marker.id] = new google.maps.Marker({
                        position: marker,
                        title: marker.licence_plate,
                        map: map,
                        icon: {
                            url: markerSvg,
                        },
                        animation: null,
                    });
                    setInfoWindow(marker, markersRef.current, google, map)
                }

            });
        }
        return () => {
            clearMarkers();
        };
    }, [markersPoint, google, map]);

    const kmlLayer = useMemo(() => {
        if (map && google) {
            return new google.maps.KmlLayer({
                url:
                    "https://community.qlik.com/cyjdu72974/attachments/cyjdu72974/qlik-sense-enterprise-documents/954/2/UAE%20Emirates%20KML.kml",
                suppressInfoWindows: true,
                preserveViewport: true,
                map: map,
            });
        }
    }, [map, google, markersPoint]);



    useEffect(() => {
        console.log("inside center : ", center)
        getLoader(apiKey, options)
            .load()
            .then((google) => {
                const mapElement = document.getElementById("google-map") as HTMLElement;
                setState({
                    google,
                    map: new google.maps.Map(mapElement, {
                        zoom: 13,
                        center,
                    }),
                });
            });


    }, [apiKey]);

    return (
        <div data-testid="google-map-test" id="google-map" className="google-map" />
    );
}