import { Typography, Button } from "antd";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import { AppState } from "store";
import { deleteIssue, setModalName } from "store/actions";
import { ISSUE_TYPE } from "utils/mapped-data";

const { Text, Title } = Typography;

export default function DeleteIssueForm() {
  const { selectedIssue } = useSelector<AppState, AppState["issues"]>(
    (state) => state.issues
  );
  const dispatch = useDispatch();

  const onDelete = useCallback(() => {
    dispatch(deleteIssue(selectedIssue?.id || ""));
    onCancel();
  }, [dispatch, selectedIssue?.id]);

  const onCancel = useCallback(() => {
    dispatch(setModalName(undefined));
  }, [dispatch]);

  return (
    <section className="delete-modal">
      <Title level={5}>Archive Issue</Title>
      <Text type="secondary">
        Are you sure you want to archive the issue{" "}
        <strong>
          “{selectedIssue?.serialId} : {ISSUE_TYPE[selectedIssue?.type || 0]}”
        </strong>{" "}
        ?
      </Text>
      <div className="action-btn-container">
        <Button className="cancel-btn" onClick={onCancel}>
          Cancel
        </Button>
        <Button type="primary" danger onClick={onDelete}>
          Archive
        </Button>
      </div>
    </section>
  );
}
