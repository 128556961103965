import { useCallback } from "react";
import { Formik } from "formik";
import { useDispatch } from "react-redux";
import { Form, Button, Typography } from "antd";

import { SelectSearch } from "../components";

import { setModalName } from "store/actions";

const { Title, Text } = Typography;

const metaData = {
  vehicle_id: {
    items: [
      { value: "J-37829" },
      { value: "H-34421" },
      { value: "D-34662" },
      { value: "G-34522" },
      { value: "D-74932" },
      { value: "P-34359" },
      { value: "S-83204" },
    ],
  },
};

export default function AssignZonesForm() {
  const dispatch = useDispatch();

  const onCancel = useCallback(() => {
    dispatch(setModalName(undefined));
  }, [dispatch]);

  return (
    <Formik initialValues={{}} onSubmit={(values) => console.trace(values)}>
      {({ setFieldValue }) => (
        <div className="form-container">
          <Title level={5}>Assign Zone</Title>
          <Text className="description" type="secondary">
            Select a zone you want to assign to the selected vehicle(s).
          </Text>
          <Form className="--add-vehicles-form">
            <Form.Item label="Vehicles" name="vehicles">
              <SelectSearch
                value={""}
                items={[]}
                setFieldValue={setFieldValue}
                placeholder="Select User"
              />
            </Form.Item>
            <Form.Item label="Zone" name="zone">
              <SelectSearch
                value={""}
                items={[]}
                setFieldValue={setFieldValue}
                placeholder="Select User"
              />
            </Form.Item>
          </Form>
          <div className="action-btn-container">
            <Button className="cancel-btn" onClick={onCancel}>
              Cancel
            </Button>
            <Button type="primary" onClick={onCancel}>
              Save
            </Button>
          </div>
        </div>
      )}
    </Formik>
  );
}
