import {BatteryModelValues, ChangeMqttServerValues} from "../types/formik";
import {useDispatch, useSelector} from "react-redux";
import {useCallback, useState} from "react";
import {changeMqttServer, setModalName} from "../store/actions";
import {Formik} from "formik";
import {BATTERY_MODEL_FIRMWARE, CHANGE_MQTT_SERVER_FIRMWARE} from "../utils/formik-data";
import {Button, Form, Input, Typography} from "antd";
import {FormItem, SelectSearch} from "../components";
import {StateBatteryModel} from "./battery-model";
import {AppState} from "../store";
const { Title, Text } = Typography;

export type StateChangeMqttServer = ChangeMqttServerValues & { }

export default function ChangeMqttServerFormForm() {
    const [{ values }, setState] = useState<{
        values: StateChangeMqttServer;
    }>({
        values: { ...CHANGE_MQTT_SERVER_FIRMWARE.initialValues },
    });
    const {
        vehicles: {selectedVehicle},
    } = useSelector<AppState, AppState>((state) => state);

    const dispatch = useDispatch();

    const onCancel = useCallback(() => {
        dispatch(setModalName(undefined));
    }, [dispatch]);

    const setValues = useCallback(
        (values: ChangeMqttServerValues) => {
            setState({
                values: {
                    ...values,

                },
            });
        },

        [dispatch, onCancel]
    );
    const serverOption = [
        {
            label: "Staging",
            value: "stg"
        },
        {
            label: "Production",
            value: "prod"
        },
    ];

    const onSubmit = useCallback(
        (values: ChangeMqttServerValues) => {
            console.log("mqtt server values : ", values, selectedVehicle?.id)
            dispatch(
                changeMqttServer({mqtt_server: values.mqtt_server || "", vehicle_id: selectedVehicle?.id || "", port: values.port})
            );

            onCancel();
        },
        [dispatch, onCancel]
    );

    return (
        <Formik
            initialValues={values}
            validationSchema={CHANGE_MQTT_SERVER_FIRMWARE.validationSchema}
            onSubmit={onSubmit}
            enableReinitialize
        >
            {({
                  setFieldValue,
                  dirty,
                  isValid,
                  handleSubmit,
                  handleChange,
                  values,
                  errors,
              }) => (
                <div className="form-container">
                    <Title level={5}>Change Server</Title>
                    <Form className="--add-users-form" onFinish={handleSubmit}>
                        <section className="">
                            <FormItem label="Server" required error={errors.mqtt_server}>
                                <Input
                                    name="mqtt_server"
                                    value={values.mqtt_server}
                                    onChange={handleChange}
                                    placeholder="Server"
                                />
                            </FormItem>
                            <FormItem label="Port" required error={errors.port}>
                                <Input
                                    name="port"
                                    value={values.port}
                                    onChange={handleChange}
                                    placeholder="Port"
                                />
                            </FormItem>
                        </section>
                        <div className="action-btn-container">
                            <>
                                <Button className="cancel-btn" onClick={onCancel}>
                                    Cancel
                                </Button>
                                <Button
                                    htmlType="submit"
                                    type="primary"
                                    disabled={(!isValid && dirty)}
                                >
                                    Save
                                </Button>
                            </>
                        </div>
                    </Form>
                </div>
            )}
        </Formik>
    );
}