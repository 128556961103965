import { Typography, Button } from "antd";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import { AppState } from "store";
import { deleteUser, setModalName } from "store/actions";

const { Text, Title } = Typography;

export default function DeleteUserForm() {
  const { selectedUser } = useSelector<AppState, AppState["users"]>(
    (state) => state.users
  );

  const dispatch = useDispatch();

  const onDelete = useCallback(() => {
    dispatch(deleteUser(selectedUser?.id || ""));
    onCancel();
  }, [dispatch, selectedUser?.id]);

  const onCancel = useCallback(() => {
    dispatch(setModalName(undefined));
  }, [dispatch]);

  return (
    <section className="delete-modal">
      <Title level={5}>Archive User</Title>
      <Text type="secondary">
        Are you sure you want to archive the user{" "}
        <strong>"{selectedUser?.name}"</strong>
      </Text>
      <div className="action-btn-container">
        <Button className="cancel-btn" onClick={onCancel}>
          Cancel
        </Button>
        <Button type="primary" danger onClick={onDelete}>
          Archive
        </Button>
      </div>
    </section>
  );
}
