import { Typography, Button } from "antd";
import {useCallback, useState, useMemo , useEffect} from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "store";
import {deleteVehicle, setModalName, updateFirmwareVehicle, getDropdownFirmwares, updateFirmwareOnVehicles} from "store/actions";
import {FormItem, SelectSearch} from "../components";
import { parseFirmwares } from "utils/parsers";



const { Text, Title } = Typography;

export default function FirmwareVehicleForm() {
 
  const {
    firmwares: { dropdownFirmwares },
  } = useSelector<AppState, AppState>((state) => state);

  const dispatch = useDispatch();
  const { selectedVehicle } = useSelector<AppState, AppState["vehicles"]>(
    (state) => state.vehicles
  );

  const firmwaresOptions = useMemo(() =>  parseFirmwares(dropdownFirmwares), [
    dropdownFirmwares,
  ]);

  
  const [version,setFieldValue] = useState('');

  const onUpdateFirmware = useCallback(() => {
   dispatch(updateFirmwareOnVehicles(setFieldValue.name, [selectedVehicle?.deviceIotId || ""]));
    onCancel();
  }, [dispatch, selectedVehicle?.deviceIotId]);

  const onCancel = useCallback(() => {
    dispatch(setModalName(undefined));
  }, [dispatch]);


  useEffect(() => {
    if (!dropdownFirmwares) {
        console.log("reached here", dropdownFirmwares)
      dispatch(getDropdownFirmwares());
    }
  }, [dispatch, dropdownFirmwares]);




  return(
    <section className="delete-modal">
      <Title level={5}>Update Firmware</Title>
      <Text type="secondary">
        Are you sure you want to update vehicle firmware for{" "}
        <strong> “{selectedVehicle?.licensePlate}”</strong>
        ?
        <br/><br/>
      </Text>
      <FormItem label="Firmware Versions">
        <SelectSearch
            value={version}
           items= {firmwaresOptions} 
            setFieldValue={setFieldValue}
            fieldName="Firmware Version"
            placeholder="Select "
            onChangeProp={(value) => {
              setFieldValue(value);
            }}
        />
      </FormItem>
      <div className="action-btn-container">
        <Button className="cancel-btn" onClick={onCancel}>
          Cancel
        </Button>
        <Button type="primary" danger onClick={onUpdateFirmware}>
          Update Firmware
        </Button>
      </div>
    </section>
      );

}
