import { format } from "date-fns";

import { IssuesState } from "types/store";
import { DATE_FORMAT, FULL_DATE_FORMAT } from "../constants";
import {
  getDateFromSeconds,
  getRandomNumber,
  getRandomString,
} from "../helpers";
import {ISSUE_TYPE, KIOSK_ISSUE_TYPE} from "../mapped-data";

export function parseIssuesTable(
  search: IssuesState["search"],
  list?: IssuesState["issues"],
  listType?: IssuesState["filters"]["listType"]
) {
  if (list) {
    const data = list.map((issue, index) => ({
      key: index,
      issue_id:issue.serialId, //getRandomNumber(10000).toString(),
      issue_type: issue.issueHost == 1 ? ISSUE_TYPE[issue.type] : KIOSK_ISSUE_TYPE[issue.kioskIssueType],
      issue_name: getRandomString([
        "Accident",
        "Broken Headlight",
        "Flat Tire",
        "Maintenance",
        "Reserve",
        "Broken Handle Bar",
        "Oil Leakage",
        "Broken Cable",
        "Out of Fuel",
        "Broken Rim",
      ]),
      vehicle_id: issue.vehiclePate,
      driver_name: issue.riderName,
      created_by: issue.createdByName,
      date_and_time:  format(
        getDateFromSeconds(issue.addedAt),
        FULL_DATE_FORMAT
      ),
      assignee: issue.assigneeName ? issue.assigneeName : "-",
      due_date: issue.dueDate >= 0 ? format(getDateFromSeconds(issue.dueDate), DATE_FORMAT) : "-",
      issue_occur_date: issue.issueOccurDate >= 0 ? format(getDateFromSeconds(issue.issueOccurDate), DATE_FORMAT) : "-",
      status: issue.status,
      issue_host: issue.issueHost == 1 ? "Vehicle" : "Kiosk",
      issue_host_id: issue.issueHostId,
      kiosk_issue_type: issue.kioskIssueType,
      issue_level: "Level " + issue.issueLevel,
      action: "",
      raw: issue,
      listType: listType,
    }));

    return data.filter((issue) => {
      return Object.values({
        vehicle_id: issue.vehicle_id,
        issue_id: issue.issue_id,
        driver_name: issue.driver_name,
        created_by: issue.created_by,
        assignee: issue.assignee,
      })
        .join(" ")
        .toLowerCase()
        .includes(search.toLowerCase());
    });
  }

  return [];
}
