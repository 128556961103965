import { Formik } from "formik";
import { useState } from "react";
import { Form, Button, Typography, Upload, Tooltip } from "antd";

import { NEXTSTEP_DOCUMENT_FORMIK } from "utils/formik-data";
import { FormItem } from "components";
import { saveIcon, uploadedFileIcon, uploadIcon, closeIcon } from "svgs";
import { NextStepValues } from "types/formik";

const { Title } = Typography;
const { Text } = Typography;

interface ComponentProps {
  updateStep: (updatedStep: number) => void;
}

export default function StepTwo(props: ComponentProps) {
  const { updateStep } = props;
  const [isUploaded, setIsUploaded] = useState(false);

  const onSubmit = () => {
    updateStep(3);
  };
  return (
    <main className="main-container">
      <Formik
        initialValues={NEXTSTEP_DOCUMENT_FORMIK.initialValues as NextStepValues}
        validationSchema={NEXTSTEP_DOCUMENT_FORMIK.validationSchema}
        onSubmit={onSubmit}>
        {({
          values,
          setFieldValue,
          handleSubmit,
          errors,
          handleChange,
          isValid,
          resetForm,
          dirty,
        }) => (
          <div className="next-step-form-container">
            <Text className="step">Step 2 of 2</Text>
            <Title level={5} className="next-step-title">
              <span className="title-icon">{saveIcon}</span>
              <span>
                The trade license you provided has expired. Please upload a
                scanned copy of your updated license.
              </span>
            </Title>

            <Form className="next-step-form" onFinish={handleSubmit}>
              <FormItem
                label=""
                error={errors.fileList}
                verticalAlign="flex-start">
                <Upload.Dragger
                  listType="text"
                  name="document_file"
                  className="step-file-upload"
                  multiple={false}
                  fileList={values.fileList}
                  onChange={({ fileList }) => {
                    // Getting total number of files
                    const totalFiles = fileList.length;

                    // If multiple files, pick last file
                    if (totalFiles > 1) {
                      setFieldValue("fileList", [fileList[totalFiles - 1]]);
                    } else {
                      setFieldValue("fileList", fileList);
                    }
                    setIsUploaded(true);
                  }}>
                  {isUploaded === false ? (
                    <>
                      {uploadIcon}
                      <p className="ant-upload text">
                        Drag and drop a file to upload or
                        <span className="primary-color"> browse</span> from
                        system
                      </p>
                    </>
                  ) : (
                    <Tooltip
                      title={values?.fileList?.[0]?.name}
                      className="uploadded-file">
                      <span className="file-icon">{uploadedFileIcon}</span>
                      <section className="file-info">
                        <span className="--name">
                          {values?.fileList?.[0]?.name}
                        </span>
                        <span className="--size">
                          {values?.fileList?.[0]?.size} KB
                        </span>
                      </section>
                      <span
                        className="download-file-icon"
                        onClick={(e) => {
                          e.persist();
                          e.preventDefault();
                          setIsUploaded(false);
                          resetForm();
                        }}>
                        {closeIcon}
                      </span>
                    </Tooltip>
                  )}
                </Upload.Dragger>
              </FormItem>

              <div className="action-btn-container">
                <Button
                  className="cancel-btn"
                  onClick={() => {
                    resetForm();
                    setIsUploaded(false);
                  }}>
                  Reset
                </Button>
                <Button
                  type="primary"
                  htmlType="submit"
                  disabled={(!isValid && dirty) || !values.fileList}>
                  Submit
                </Button>
              </div>
            </Form>
          </div>
        )}
      </Formik>
    </main>
  );
}
