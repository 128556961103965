import { PieChart, PieChartData } from "@k2/rv-viz";
import { pieChartWidget } from "./../../svgs";

export default function RenderChart() {
  const pieData: PieChartData[] = [
    {
      label: "Completed",
      value: 80,
    },
    {
      label: "In Progress",
      value: 10,
    },
    {
      label: "Cancelled",
      value: 5,
    },
    {
      label: "Pending",
      value: 20,
    },
  ];

  return (
    <PieChart
      legends={false}
      colors={{
        dark: ["#70b857", "#4580d9", "#ed2020", "#ecc600"],
        light: ["#70b857", "#4580d9", "#ed2020", "#ecc600"],
      }}
      data={pieData}
      radial={{
        anglePadding: 2,
        innerRadius: 0.9,
      }}
      title={"Task by Status"}
      classes={{
        label: "label-container",
      }}
      // TODO:commenting might need this in future
      // label={(props) => {
      //   return (
      //     <article className="label">
      //       <p className="label-value">{props.data.value}</p>
      //       <p className="label-title">{props.data.label}</p>
      //     </article>
      //   );
      // }}
      componentStates={{
        blank: (
          <div className="pie-chart-container">
            <strong className="pie-chart-title">Task Breakdown</strong>
            <figure className="empty-state-widget">{pieChartWidget}</figure>
          </div>
        ),
      }}
    />
  );
}
