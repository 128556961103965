import { ReactNode } from "react";
import { Typography } from "antd";
import { classNames } from "utils/helpers";

const { Text } = Typography;

interface ComponentProps {
  label?: ReactNode;
  error?: string;
  required?: boolean;
  children: ReactNode;
  verticalAlign?: "flex-start" | "center";
}

export default function FormItem(props: ComponentProps) {
  const {
    label,
    children,
    error,
    required = false,
    verticalAlign = "center",
  } = props;
  const labelClasses = classNames({
    __label: true,
    "--required": required,
    "--vertical-top": verticalAlign === "flex-start",
    "--vertical-center": verticalAlign === "center",
  });

  return (
    <div className="custom-form-item">
      {label && <Text className={labelClasses}>{label}</Text>}
      <div className="__field-wrapper">
        {children}
        <Text className="error-text" title={error}>{error}</Text>
      </div>
    </div>
  );
}
