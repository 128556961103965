import { useCallback, useMemo } from "react";
import { Menu, Dropdown } from "antd";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { ROUTES } from "utils/constants";

import { actionMenu } from "svgs";
import {VehicleModelState, VehiclesState} from "types/store";
import {setModalName, setSelectedVehicleModel} from "store/actions";

export default function DropdownAction({
  model,
}: {
  model: VehicleModelState["selectedModels"];
}) {
  const dispatch = useDispatch();

    const onUploadFirmware = useCallback(() => {
        dispatch(setSelectedVehicleModel(model));
        dispatch(setModalName("add_model_firmware"));
    }, [dispatch, model]);

    const onUpdateFirmware = useCallback(() => {
        dispatch(setSelectedVehicleModel(model));
        dispatch(setModalName("update_model_firmware"));
    }, [dispatch, model]);

    const onDelete = useCallback(() => {
        dispatch(setSelectedVehicleModel(model));
        dispatch(setModalName("delete_vehicle_model"));
    }, [dispatch, model]);


  const menu = useMemo(
    () => (
      <Menu>
        <Menu.Item key="1">
        </Menu.Item>
        <Menu.Item key="2" className="firmware-action" onClick={onUploadFirmware}>
            Upload Firmware
        </Menu.Item>
        <Menu.Item key="3" className="firmware-action" onClick={onUpdateFirmware}>
            Rollout Firmware Upgrade
        </Menu.Item>
        <Menu.Item key="3" className="delete-action" onClick={onDelete}>
          Archive
        </Menu.Item>

      </Menu>
    ),
    [onUploadFirmware, onUpdateFirmware, onDelete]
  );

  return (
    <Dropdown overlay={menu} trigger={["click"]}>
      <a className="ant-dropdown-link">{actionMenu}</a>
    </Dropdown>
  );
}
