import {
  CLIENT_CONSIGNEE_ID,
  PAGE_SIZE,
  SUCCESS_MESSAGES,
} from "utils/constants";
import {
  GetState,
  UserDataItem,
  UsersActions,
  UsersState,
  UserTabName,
} from "types";
import {
  AddUserManagementRequest,
  AddUserManagementResponse,
  DeleteUserAdminRequest,
  DeleteUserAdminResponse,
  ListUsersManagementRequest,
  ListUsersManagementResponse,
  PhoneNumber,
  UpdateUserManagementRequest,
  UpdateUserManagementResponse,
  UserUpdateItem,
  UserDataUpdate,
  GetUserManagementRequest,
  GetUserManagementResponse,
  DownloadFileRequest,
  DownloadFileResponse,
  ListUsersRiderManagementRequest,
  ListUsersRiderManagementResponse,
  UploadPhotoRequest,
  UploadPhotoResponse,
  Filter,
} from "protobuf/user_service_pb";
import {
  userManagementServiceClient,
  userAdminServiceClient,
} from "utils/grpc-clients";
import { NullableClientOrgId } from "protobuf/client_service_pb";
import { Dispatch } from "react";
import { PageRequest, PageResponse } from "protobuf/page_pb";
import { apiResponse } from "./common-actions";
import {
  UserAdminServiceClient,
  UserManagementServiceClient,
} from "protobuf/User_serviceServiceClientPb";
import { getFullName } from "utils/helpers";
import { Filters } from "protobuf/filter_pb";

interface UserPayload {
  firstName: string;
  lastName: string;
  clientOrgId?: string;
  countryPrefix: string;
  number: string;
  email: string;
  password: string;
  type: number;
  documentId: string;
  licenceNo: string;
  vehicleId: string[];
  document: Uint8Array | string;
  documentName: string;
  documentExt: string;
  organizationId:string;
}

export function setUserSearch(payload: UsersState["search"]): UsersActions {
  return {
    type: "SET_USER_SEARCH",
    payload,
  };
}

export function setBulkActionsUser(
  payload: UsersState["showbulkActionsUser"]
): UsersActions {
  return {
    type: "SET_USER_BULK_ACTIONS",
    payload,
  };
}
export function setSelectedUserRows(
  payload: UsersState["selectedUserRows"]
): UsersActions {
  return {
    type: "SET_USER_SELECTED_ROWS",
    payload,
  };
}

export function setSelectedUser(
  payload: UsersState["selectedUser"]
): UsersActions {
  return {
    type: "SET_SELECTED_USER",
    payload,
  };
}

export function setAllUser(payload: UsersState["allUsers"]): UsersActions {
  return {
    type: "SET_ALL_USERS",
    payload,
  };
}

export function setAllUsersSelectedPage(
  payload: UsersState["allUsersPage"]["selectedPage"]
): UsersActions {
  return {
    type: "SET_ALL_USERS_SELECTED_PAGE",
    payload,
  };
}

export function setAllUsersTotalPage(
  payload: PageResponse.AsObject
): UsersActions {
  return {
    type: "SET_ALL_USERS_TOTAL_PAGE",
    payload,
  };
}

export function setAdminUser(payload: UsersState["adminUsers"]): UsersActions {
  return {
    type: "SET_ADMIN_USERS",
    payload,
  };
}

export function setAdminUsersSelectedPage(
  payload: UsersState["adminUsersPage"]["selectedPage"]
): UsersActions {
  return {
    type: "SET_ADMIN_USERS_SELECTED_PAGE",
    payload,
  };
}

export function setAdminUsersTotalPage(
  payload: PageResponse.AsObject
): UsersActions {
  return {
    type: "SET_ADMIN_USERS_TOTAL_PAGE",
    payload,
  };
}

export function setRiderUser(payload: UsersState["riderUsers"]): UsersActions {
  return {
    type: "SET_RIDER_USERS",
    payload,
  };
}

export function setRiderUsersSelectedPage(
  payload: UsersState["riderUsersPage"]["selectedPage"]
): UsersActions {
  return {
    type: "SET_RIDER_USERS_SELECTED_PAGE",
    payload,
  };
}

export function setRiderUsersTotalPage(
  payload: PageResponse.AsObject
): UsersActions {
  return {
    type: "SET_RIDER_USERS_TOTAL_PAGE",
    payload,
  };
}

export function setMechanicUser(
  payload: UsersState["mechanicUsers"]
): UsersActions {
  return {
    type: "SET_MECHANIC_USERS",
    payload,
  };
}

export function setMechanicUsersSelectedPage(
  payload: UsersState["mechanicUsersPage"]["selectedPage"]
): UsersActions {
  return {
    type: "SET_MECHANIC_USERS_SELECTED_PAGE",
    payload,
  };
}

export function setMechanicUsersTotalPage(
  payload: PageResponse.AsObject
): UsersActions {
  return {
    type: "SET_MECHANIC_USERS_TOTAL_PAGE",
    payload,
  };
}

export function setSelectedTab(
  payload: UsersState["selectedTab"]
): UsersActions {
  return {
    type: "SET_SELECTED_TAB",
    payload,
  };
}

export function setDropdownUsers(
  payload: UsersState["dropdownUsers"]
): UsersActions {
  return {
    type: "SET_DROPDOWN_USERS",
    payload,
  };
}

export function addUser({
  countryPrefix,
  email,
  firstName,
  lastName = "",
  number,
  password,
  type,
  documentId,
  licenceNo,
  document,
  vehicleId,
  documentExt,
  documentName,
    organizationId,
}: UserPayload) {
  return (dispatch: Dispatch<any>, getState: GetState) => {
    const { selectedTab } = getState().users;

    const user = new UserUpdateItem();
    user.setFirstName(firstName);
    user.setLastName(lastName);

    const consignee = new NullableClientOrgId();
    consignee.setVal(CLIENT_CONSIGNEE_ID);

    const phone = new PhoneNumber();
    phone.setCountryPrefix(countryPrefix);
    phone.setNumber(number);

    const request = new AddUserManagementRequest();
    request.setEmail(email);
    request.setPassword(password);
    request.setPhoneNumber(phone);
    request.setType(type);
    request.setUser(user);
    request.setDocumentId(documentId);
    request.setLicenseNo(licenceNo);
    request.setDocument(document);
    request.setClientOrgId(consignee);
    request.setVehicleIdsList(vehicleId);
    request.setDocumentExt(documentExt);
    request.setDocumentName(documentName);
    request.setOrganizationId(organizationId)

    function dispatcher() {
      dispatchUsers(dispatch, selectedTab);
    }

    dispatch(
      apiResponse<
        UserManagementServiceClient,
        AddUserManagementRequest,
        AddUserManagementResponse
      >({
        client: userManagementServiceClient,
        action: "addUser",
        request,
        dispatcher,
        successMessage: SUCCESS_MESSAGES.addUser,
        modalAction: true,
      })
    );
  };
}

export function fetchAllUsers() {
  return (dispatch: Dispatch<any>, getState: GetState) => {
    const { allUsersPage } = getState().users;

    const page = new PageRequest();
    page.setPageNumber(allUsersPage.selectedPage);
    page.setPageSize(PAGE_SIZE);

    const request = new ListUsersManagementRequest();
    request.setClientOrgId(CLIENT_CONSIGNEE_ID);
    request.setTypeList([0, 1, 2, 3, 4, 5, 6]);
    request.setPage(page);
    console.log("request : ", request.toObject())

    function dispatcher(resp: ListUsersManagementResponse) {
      const page = resp?.getPage()?.toObject() || {
        totalElements: 0,
        totalPages: 0,
      };
      const users = resp?.getUsersList()?.map(
        (user) =>
          ({
            ...user?.getBasic()?.toObject(),
            vehicle: user?.getAssignedvehiclesList().map((v) => v.toObject()),
          } as UserDataItem)
      );

      dispatch(setAllUsersTotalPage(page));
      dispatch(setAllUser(users));
    }

    dispatch(
      apiResponse<
        UserManagementServiceClient,
        ListUsersManagementRequest,
        ListUsersManagementResponse
      >({
        client: userManagementServiceClient,
        action: "listUsers",
        request,
        dispatcher,
      })
    );
  };
}

export function fetchRiderUsers() {
  return (dispatch: Dispatch<any>, getState: GetState) => {
    const { riderUsersPage } = getState().users;

    const page = new PageRequest();
    page.setPageNumber(riderUsersPage.selectedPage);
    page.setPageSize(PAGE_SIZE);

    const request = new ListUsersManagementRequest();
    request.setClientOrgId(CLIENT_CONSIGNEE_ID);
    request.setTypeList([3]);
    request.setPage(page);

    function dispatcher(resp: ListUsersManagementResponse) {
      const page = resp?.getPage()?.toObject() || {
        totalElements: 0,
        totalPages: 0,
      };
      const users = resp?.getUsersList()?.map(
        (user) =>
          ({
            ...user?.getBasic()?.toObject(),
            vehicle: user?.getAssignedvehiclesList().map((v) => v.toObject()),
          } as UserDataItem)
      );

      dispatch(setRiderUsersTotalPage(page));
      dispatch(setRiderUser(users));
    }

    dispatch(
      apiResponse<
        UserManagementServiceClient,
        ListUsersManagementRequest,
        ListUsersManagementResponse
      >({
        client: userManagementServiceClient,
        action: "listUsers",
        request,
        dispatcher,
      })
    );
  };
}

export function fetchAdminUsers() {
  return (dispatch: Dispatch<any>, getState: GetState) => {
    const { adminUsersPage } = getState().users;

    const page = new PageRequest();
    page.setPageNumber(adminUsersPage.selectedPage);
    page.setPageSize(PAGE_SIZE);

    const request = new ListUsersManagementRequest();
    request.setClientOrgId(CLIENT_CONSIGNEE_ID);
    request.setTypeList([1, 2]);
    request.setPage(page);

    function dispatcher(resp: ListUsersManagementResponse) {
      const page = resp?.getPage()?.toObject() || {
        totalElements: 0,
        totalPages: 0,
      };
      const users = resp?.getUsersList()?.map(
        (user) =>
          ({
            ...user?.getBasic()?.toObject(),
            vehicle: user?.getAssignedvehiclesList().map((v) => v.toObject()),
          } as UserDataItem)
      );

      dispatch(setAdminUsersTotalPage(page));
      dispatch(setAdminUser(users));
    }

    dispatch(
      apiResponse<
        UserManagementServiceClient,
        ListUsersManagementRequest,
        ListUsersManagementResponse
      >({
        client: userManagementServiceClient,
        action: "listUsers",
        request,
        dispatcher,
      })
    );
  };
}

export function fetchClientAdminUsers() {
  return (dispatch: Dispatch<any>, getState: GetState) => {
    const { adminUsersPage } = getState().users;

    const page = new PageRequest();
    page.setPageNumber(adminUsersPage.selectedPage);
    page.setPageSize(PAGE_SIZE);

    const request = new ListUsersManagementRequest();
    request.setClientOrgId(CLIENT_CONSIGNEE_ID);
    request.setTypeList([5]);
    request.setPage(page);

    function dispatcher(resp: ListUsersManagementResponse) {
      const page = resp?.getPage()?.toObject() || {
        totalElements: 0,
        totalPages: 0,
      };
      const users = resp?.getUsersList()?.map(
          (user) =>
              ({
                ...user?.getBasic()?.toObject(),
                vehicle: user?.getAssignedvehiclesList().map((v) => v.toObject()),
              } as UserDataItem)
      );
      console.log("client admin : ", users)
      dispatch(setAdminUsersTotalPage(page));
      dispatch(setAdminUser(users));
    }

    dispatch(
        apiResponse<
            UserManagementServiceClient,
            ListUsersManagementRequest,
            ListUsersManagementResponse
        >({
          client: userManagementServiceClient,
          action: "listUsers",
          request,
          dispatcher,
        })
    );
  };
}

export function fetchMechanicUsers() {
  return (dispatch: Dispatch<any>, getState: GetState) => {
    const { mechanicUsersPage } = getState().users;

    const page = new PageRequest();
    page.setPageNumber(mechanicUsersPage.selectedPage);
    page.setPageSize(PAGE_SIZE);

    const request = new ListUsersManagementRequest();
    request.setClientOrgId(CLIENT_CONSIGNEE_ID);
    request.setTypeList([4]);
    request.setPage(page);

    function dispatcher(resp: ListUsersManagementResponse) {
      const page = resp?.getPage()?.toObject() || {
        totalElements: 0,
        totalPages: 0,
      };
      const users = resp?.getUsersList()?.map(
        (user) =>
          ({
            ...user?.getBasic()?.toObject(),
            vehicle: user?.getAssignedvehiclesList().map((v) => v.toObject()),
          } as UserDataItem)
      );

      dispatch(setMechanicUsersTotalPage(page));
      dispatch(setMechanicUser(users));
    }

    dispatch(
      apiResponse<
        UserManagementServiceClient,
        ListUsersManagementRequest,
        ListUsersManagementResponse
      >({
        client: userManagementServiceClient,
        action: "listUsers",
        request,
        dispatcher,
      })
    );
  };
}

export function getDropdownUsers() {
  return (dispatch: Dispatch<any>) => {
    const typeFilter = new Filters.In();
    typeFilter.setIntsList([0, 1, 2, 3, 4, 5]);

    const filters = new Filter();
    filters.setType(typeFilter);

    const request = new ListUsersRiderManagementRequest();
    request.setFilter(filters);

    function dispatcher(resp: ListUsersRiderManagementResponse) {
      const users = resp
        ?.getUsersList()
        ?.map((user) => user?.getBasic()?.toObject() as UserDataItem);
      dispatch(setDropdownUsers(users));
    }

    dispatch(
      apiResponse<
        UserManagementServiceClient,
        ListUsersRiderManagementRequest,
        ListUsersRiderManagementResponse
      >({
        client: userManagementServiceClient,
        request,
        action: "listUserRiders",
        dispatcher,
      })
    );
  };
}

export function dispatchUsers(dispatch: Dispatch<any>, tabName: UserTabName) {
  switch (tabName) {
    case "all":
      dispatch(fetchAllUsers());
      break;

    case "riders":
      dispatch(fetchRiderUsers());
      break;

    // case "mechanics":
    //   dispatch(fetchMechanicUsers());
    //   break;

    case "admins":
      dispatch(fetchAdminUsers());
      break;

    case "client admins":
      dispatch(fetchClientAdminUsers());
      break;
    default:
      dispatch(fetchAllUsers());
      break;
  }
}

export function deleteUser(userId: string, isBlock = false) {
  return (dispatch: Dispatch<any>, getState: GetState) => {
    const { selectedTab } = getState().users;

    const request = new DeleteUserAdminRequest();
    request.setId(userId);

    function dispatcher() {
      dispatchUsers(dispatch, selectedTab);
    }

    dispatch(
      apiResponse<
        UserAdminServiceClient,
        DeleteUserAdminRequest,
        DeleteUserAdminResponse
      >({
        client: userAdminServiceClient,
        action: "deleteUser",
        request,
        dispatcher,
        successMessage: isBlock
          ? SUCCESS_MESSAGES.blockAccount
          : SUCCESS_MESSAGES.deleteUser,
      })
    );
  };
}

export function updateUser(
  {
    email,
    firstName,
    lastName = "",
    number,
    type,
    documentId,
    licenceNo,
    vehicleId,
    document,
    documentExt,
    documentName,
      organizationId,
  }: Omit<UserPayload, "password" | "countryPrefix">,
  userId: string
) {
  return (dispatch: Dispatch<any>, getState: GetState) => {
    const {
      users: { selectedTab },
    } = getState();

    const user = new UserDataUpdate();
    user.setFirstName(firstName);
    user.setLastName(lastName);
    user.setEmail(email);
    user.setType(type);
    user.setDocumentId(documentId);
    user.setLicenseNo(licenceNo);
    user.setPhoneNumber(number);
    user.setVehicleIdsList(vehicleId);
    user.setDocument(document);
    user.setDocumentExt(documentExt);
    user.setDocumentName(documentName);

    const request = new UpdateUserManagementRequest();
    request.setId(userId);
    request.setUser(user);

    function dispatcher() {
      dispatchUsers(dispatch, selectedTab);
      dispatch(getUpdatedUser(userId));
    }

    dispatch(
      apiResponse<
        UserManagementServiceClient,
        UpdateUserManagementRequest,
        UpdateUserManagementResponse
      >({
        client: userManagementServiceClient,
        action: "updateUser",
        request,
        successMessage: SUCCESS_MESSAGES.updateUser,
        dispatcher,
        modalAction: true,
      })
    );
  };
}

export function getUpdatedUser(userId: string) {
  return (dispatch: Dispatch<any>) => {
    const request = new GetUserManagementRequest();
    request.setId(userId);

    function dispatcher(resp: GetUserManagementResponse) {
      const user = resp.getUser()?.getBasic();
      dispatch(
        setSelectedUser({
          email: user?.getEmail() || "",
          id: user?.getId() || "",
          imgUrl: user?.getProfilePhotoLink() || "",
          name: getFullName(
            user?.getFirstName() || "",
            user?.getLastName() || ""
          ),
          phone: user?.getPhoneNumber() || "",
          type: user?.getType() || 1,
          documentId: user?.getDocumentId(),
          lincenceNo: user?.getLicenseNo(),
          vehicle: resp?.getAssignedVehicleList().map((v) => v.toObject()),
          document: user?.getDocument(),
          documentLink: user?.getDocumentLink(),
          documentName: user?.getDocumentName(),
          documentType: user?.getDocumentExt(),
          organization_id:user?.getOrganizationId() || "",
        })
      );
    }

    dispatch(
      apiResponse<
        UserManagementServiceClient,
        GetUserManagementRequest,
        GetUserManagementResponse
      >({
        client: userManagementServiceClient,
        request,
        action: "getUser",
        dispatcher,
      })
    );
  };
}

export function getDownloadedBytes(
  fileLink: string,
  dispatcher: (resp: DownloadFileResponse) => void
) {
  return (dispatch: Dispatch<any>) => {
    const request = new DownloadFileRequest();
    request.setFileName(fileLink);

    dispatch(
      apiResponse<
        UserManagementServiceClient,
        DownloadFileRequest,
        DownloadFileResponse
      >({
        request,
        client: userManagementServiceClient,
        dispatcher,
        action: "downloadFile",
      })
    );
  };
}

export function uploadProfilePic(
  userId: string,
  photo: string | Uint8Array,
  photoExt: string,
  photoName: string
) {
  return (dispatch: Dispatch<any>) => {
    const request = new UploadPhotoRequest();
    request.setUserId(userId);
    request.setPhoto(photo);
    request.setPhotoExt(photoExt);
    request.setPhotoName(photoName);

    dispatch(
      apiResponse<
        UserManagementServiceClient,
        UploadPhotoRequest,
        UploadPhotoResponse
      >({
        request,
        action: "uploadProfilePhoto",
        dispatcher: () => {
          dispatch(getUpdatedUser(userId));
        },
        client: userManagementServiceClient,
      })
    );
  };
}
