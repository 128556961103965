import { useCallback, useState } from "react";
import { Collapse } from "antd";
import { CollapsePanelProps } from "antd/lib/collapse";

import { RadioGroup } from "components";
import { DatePicker } from "components/base";
import { RadioChangeEvent } from "antd/lib/radio";
import { seperatorIcon } from "svgs";

const { RangePicker } = DatePicker;
const { Panel } = Collapse;

interface ComponentProps {
  name: string;
  options: { label: string; value: string }[];
  value: string;
  onChange: (value: string, range?: [string, string]) => void;
  option: {
    label: string;
    value: string;
    range?: [Date, Date];
  };
}

export default function RadioPanelList(
  props: ComponentProps & CollapsePanelProps
) {
  const { name, options, value, onChange: callback, option, ...rest } = props;
  const [showCustomRange, setShowCustomRange] = useState(false);

  const onChange = useCallback(
    (e: RadioChangeEvent) => {
      callback(e.target.value);
      setShowCustomRange(e.target.value === "Custom Range");
    },
    [callback]
  );
  const onChangeRange = useCallback(
    (range) => {
      callback("Custom Range", range);
    },
    [callback]
  );

  return (
    <Panel {...rest} header={name}>
      <RadioGroup onChange={onChange} options={options} value={value} />
      {showCustomRange && (
        <RangePicker
          value={option.range}
          className="issues-range-picker"
          onChange={onChangeRange}
          dropdownClassName="issues-range-picker-popup"
          placeholder={["Start", "End"]}
          separator={seperatorIcon}
          format="MMM DD"
        />
      )}
    </Panel>
  );
}
