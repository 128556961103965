import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useCookies } from "react-cookie";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { AppState } from "store";
import {
  setAdminUsersSelectedPage,
  setAllUsersSelectedPage,
  setMechanicUsersSelectedPage,
  setRiderUsersSelectedPage,
} from "store/actions";
import { UserTabName } from "types";

import { ROUTES } from "./constants";

type Delay = number | null;
type TimerHandler = (...args: any[]) => void;

export function useRedirectToDefault() {
  const [{ meta }] = useCookies();
  const history = useHistory();

  useEffect(() => {
    if (meta?.token) {
      //TODO change it letter to dashboard route when dashboard is ready
      // history.push(ROUTES.dashboard);
      history.push(ROUTES.users);
    }
  }, [history, meta?.token]);
}

export function useGetUserData(tabName: UserTabName) {
  const {
    allUsersPage,
    riderUsersPage,
    // mechanicUsersPage,
    adminUsersPage,
    allUsers,
    riderUsers,
    mechanicUsers,
    adminUsers,
    clientAdminUsers,
  } = useSelector<AppState, AppState["users"]>((state) => state.users);
  const dispatch = useDispatch();
  const page = useMemo(() => {
    switch (tabName) {
      case "all":
        return allUsersPage;
      case "admins":
        return adminUsersPage;
      case "client admins":
        return adminUsersPage;
      // case "mechanics":
      //   return mechanicUsersPage;
      case "riders":
        return riderUsersPage;
      default:
        return allUsersPage;
    }
  }, [
    tabName,
    allUsersPage,
    riderUsersPage,
    // mechanicUsersPage,
    adminUsersPage,
  ]);
  const selectedPageCallback = useMemo(() => {
    switch (tabName) {
      case "all":
        return setAllUsersSelectedPage;
      case "admins":
        return setAdminUsersSelectedPage;
      case "client admins":
        return setAdminUsersSelectedPage;
      // case "mechanics":
      //   return setMechanicUsersSelectedPage;
      case "riders":
        return setRiderUsersSelectedPage;
      default:
        return setAllUsersSelectedPage;
    }
  }, [tabName]);
  const tableData = useMemo(() => {
    switch (tabName) {
      case "all":
        return allUsers;
      case "admins":
        return adminUsers;
      case "client admins":
        return adminUsers;
      // case "mechanics":
      //   return mechanicUsers;
      case "riders":
        return riderUsers;
      default:
        return allUsers;
    }
  }, [tabName, allUsers, riderUsers, mechanicUsers, adminUsers]);
  const { selectedPage, totalPages } = page;

  const onBack = useCallback(() => {
    if (selectedPage > 1) {
      dispatch(selectedPageCallback(selectedPage - 1));
    }
  }, [dispatch, selectedPage, selectedPageCallback]);

  const onNext = useCallback(() => {
    if (selectedPage < totalPages) {
      dispatch(selectedPageCallback(selectedPage + 1));
    }
  }, [dispatch, selectedPage, selectedPageCallback, totalPages]);

  const resetCallback = useCallback(() => {
    dispatch(selectedPageCallback(1));
  }, [dispatch, selectedPageCallback]);

  return {
    page,
    onBack,
    onNext,
    tableData,
    resetCallback,
  };
}

/**
 * Provides a declarative useInterval
 *
 * @param callback - Function that will be called every `delay` ms.
 * @param delay - Number representing the delay in ms. Set to `null` to "pause" the interval.
 */

export const useInterval = (callback: TimerHandler, delay: Delay) => {
  const savedCallbackRef = useRef<TimerHandler>();

  useEffect(() => {
    savedCallbackRef.current = callback;
  }, [callback]);

  useEffect(() => {
    const handler = (...args: any[]) => savedCallbackRef.current!(...args);

    if (delay !== null) {
      const intervalId = setInterval(handler, delay);
      return () => clearInterval(intervalId);
    }
  }, [delay]);
};

// Our hook
export default function useDebounce(value: string, delay: number) {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(
    () => {
      // Set debouncedValue to value (passed in) after the specified delay
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);

      // Return a cleanup function that will be called every time ...
      // ... useEffect is re-called. useEffect will only be re-called ...
      // ... if value changes (see the inputs array below).
      // This is how we prevent debouncedValue from changing if value is ...
      // ... changed within the delay period. Timeout gets cleared and restarted.
      // To put it in context, if the user is typing within our app's ...
      // ... search box, we don't want the debouncedValue to update until ...
      // ... they've stopped typing for more than 500ms.
      return () => {
        clearTimeout(handler);
      };
    },
    // Only re-call effect if value changes
    // You could also add the "delay" var to inputs array if you ...
    // ... need to be able to change that dynamically.
    [value]
  );

  return debouncedValue;
}
