import { Table } from "components/base";
import { TableProps } from "antd/lib/table";
import { financialsTableData } from "./__mock__";
import {
  renderPaymentIcon,
  paymentDetailCell,
  renderEmptyContent,
  renderPaidAction,
  renderOutstandingAction,
  outstandingPaymentDetailCell,
} from "./cell-renders";
import { parseFinancialsTable } from "utils/parsers/financials-parsers";
import { useCallback, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "store";
import { setBulkActionsFinancials } from "store/actions";

const paidColumns: TableProps<any>["columns"] = [
  {
    title: "Payment Icon",
    dataIndex: "payment_icon",
    width: "70px",
    render: renderPaymentIcon,
  },
  {
    title: "Payment Title",
    dataIndex: "payment_title",
    render: renderEmptyContent,
  },
  {
    title: "Payment Date",
    dataIndex: "payment_date",
    render: renderEmptyContent,
  },
  {
    title: "Payment",
    dataIndex: "payment",
    render: renderEmptyContent,
  },
  {
    title: "Amount",
    dataIndex: "amount",
    align: "left",
    render: paymentDetailCell,
  },
  {
    title: "action",
    dataIndex: "action",
    className: "open-link",
    render: renderPaidAction,
  },
];

const outstandingColumns: TableProps<any>["columns"] = [
  {
    title: "Payment Icon",
    dataIndex: "payment_icon",
    width: "70px",
    render: renderPaymentIcon,
  },
  {
    title: "Payment Title",
    dataIndex: "payment_title",
    render: renderEmptyContent,
  },
  {
    title: "Payment Date",
    dataIndex: "payment_date",
    render: renderEmptyContent,
  },
  {
    title: "Payment",
    dataIndex: "payment",
    render: renderEmptyContent,
  },
  {
    title: "Amount",
    dataIndex: "amount",
    align: "left",
    render: outstandingPaymentDetailCell,
  },
  {
    title: "action",
    dataIndex: "action",
    className: "open-link",
    render: renderOutstandingAction,
  },
];

interface ComponentProps {
  selectedTab: string;
}

export default function FinancialsContent(props: ComponentProps) {
  const dispatch = useDispatch();
  const { selectedTab } = props;
  const { paymentList, search } = useSelector<AppState, AppState["financials"]>(
    (state) => state.financials
  );

  const data = useMemo(
    () => parseFinancialsTable(search, selectedTab, paymentList),
    [paymentList, search, selectedTab]
  );
  const [selectedRows, setSelectedRows] = useState([]);
  const onChangeSelection = useCallback(
    (value) => {
      setSelectedRows(value);

      if (value.length !== 0) {
        dispatch(setBulkActionsFinancials(true));
      } else {
        dispatch(setBulkActionsFinancials(false));
      }
    },
    [dispatch]
  );
  return (
    <div className="financials-content">
      <Table
        customClasses="--common-list-table"
        columns={selectedTab === "paid" ? paidColumns : outstandingColumns}
        rowSelection={{
          selectedRowKeys: selectedRows,
          onChange: onChangeSelection,
        }}
        dataSource={data}
        pagination={false}
        showHeader={false}
      />
    </div>
  );
}
