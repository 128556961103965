import { Table } from "antd";
import { TableProps } from "antd/lib/table";

interface ComponentProps {
  customClasses?: string;
}

export default function TableView<RecordType extends object = any>(
  props: ComponentProps & TableProps<RecordType>
) {
  const { customClasses = "" } = props;
  return <Table {...props} className={`common-table ${customClasses}`} />;
}
