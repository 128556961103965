import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { BatteryModelValues} from "types/formik";
import { BATTERY_MODEL_FIRMWARE } from "utils/formik-data";
import AddBatteryModelForm from "./add-battery-model";
import {setModalName} from "../../store/actions";


export type StateBatteryModel = BatteryModelValues & { };

export default function AddBatteryModelsForm() {
    const [{ values }, setState] = useState<{
        values: StateBatteryModel;
    }>({
        values: { ...BATTERY_MODEL_FIRMWARE.initialValues },
    });

    const dispatch = useDispatch();
    const onCancel = useCallback(() => {
        dispatch(setModalName(undefined));
    }, [dispatch]);

    const setValues = useCallback(
        (values: BatteryModelValues) => {
            setState({
                values: {
                    ...values,

                },
            });
            // dispatch(
            //     addFirmWare({
            //         document: values.document_file || "",
            //         documentExt: values.document_file_raw?.type || "",
            //         documentName: values.document_file_raw?.name || "",
            //     })
            // );
        },

        [dispatch, onCancel]
    );

    return  (
        <AddBatteryModelForm
            initialValues={values}
            setValues={setValues}
            onCancel={onCancel}
        />
    );
}