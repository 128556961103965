import {Zone, ZonesActions, ZonesState} from "types/store";

const initialState: ZonesState = {
  showbulkActionsZones: false,
  zoneCoordinates: [],
};

export default function zoneReducer(
  state: ZonesState = initialState,
  action: ZonesActions
): ZonesState {
  switch (action.type) {
    case "SET_ZONE_BULK_ACTIONS":
      return { ...state, showbulkActionsZones: action.payload };
    case "SET_ZONE_COORDINATES":
      return {...state, zoneCoordinates: action.payload };
    case "SET_ZONES":
      return {...state, zones: action.payload};
    case "SET_SELECTED_ZONE":
      return {...state, selectedZone: action.payload}
    default:
      return state;
  }
}
