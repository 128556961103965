import { Button } from "antd";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Input, SwitchButton } from "components/base";

import { useHistory, useLocation } from "react-router-dom";
import { setIssuessSearch, setModalName } from "store/actions";
import { archiveIcon, exportIcon, plusIcon, searchIcon } from "svgs";
import { ROUTES } from "utils/constants";
import { AppState } from "store";

export default function IssuesHeader() {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  const selectedView = location.pathname.split("/")[2];

  const { showbulkActionsIssues } = useSelector<AppState, AppState["issues"]>(
    (state) => state.issues
  );

  const onAddIssues = useCallback(() => {
    dispatch(setModalName("add_issue"));
  }, [dispatch]);

  const onSwitchView = useCallback(
    (event) => {
      const { value } = event.target;
      history.push(`${ROUTES.issues}/${value}`);
    },
    [history]
  );

  const onChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      dispatch(setIssuessSearch(event.target.value));
    },
    [dispatch]
  );

  return (
    <div className="common-header">
      <Input
        customClasses="--standard"
        placeholder="Search Issues..."
        prefix={searchIcon}
        onChange={onChange}
      />
      {showbulkActionsIssues && (
        <article className="bulk-actions">
          <Button disabled icon={exportIcon}>
            Export
          </Button>
          {/* <Button disabled danger icon={archiveIcon}>
            Archive
          </Button> */}
        </article>
      )}
      <div className="actions-container">
        {/*<SwitchButton*/}
        {/*  label="Select View:"*/}
        {/*  options={["table", "list"]}*/}
        {/*  defaultOption={selectedView ?? "table"}*/}
        {/*  onChange={onSwitchView}*/}
        {/*  type="secondary"*/}
        {/*/>*/}
        {/*<div className="vertical-separator" />*/}
        {/* <Button
          className="export-btn"
          disabled
          icon={<span className="custom-icon">{exportIcon}</span>}>
          Export
        </Button> */}
        <Button
          type="primary"
          icon={<span className="custom-icon">{plusIcon}</span>}
          onClick={onAddIssues}>
          Add Issue
        </Button>
      </div>
    </div>
  );
}
