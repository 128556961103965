import { Formik } from "formik";
import { useCallback } from "react";
import PhoneInput from "react-phone-input-2";
import { Form, Button, Typography, Input, Upload } from "antd";

import {
    userRoles,
} from "components/tab-content/users-content/__mock__";
import { fileToByteArray } from "utils/helpers";
import { FormItem, SelectSearch } from "components";
import { FIRMWARE_FORMIK } from "utils/formik-data";
import { FirmwareValues } from "types/formik";
import { SelectType } from "types";
import { uploadIcon } from "svgs";

const { Title, Text } = Typography;

interface ComponentProps {
    initialValues: FirmwareValues;
    addFirmwareCallback: VoidFunction;
    setValues: (values: FirmwareValues) => void;
    onCancel?: React.MouseEventHandler<HTMLElement>;
}

export default function AddVehicleFirmwareForm(props: ComponentProps) {
    const { initialValues, addFirmwareCallback, onCancel, setValues } = props;

    const onSubmit = useCallback(
        (values: FirmwareValues) => {
            setValues(values);
        },
        [setValues]
    );

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={FIRMWARE_FORMIK.validationSchema}
            onSubmit={onSubmit}
            enableReinitialize
        >
            {({
                  setFieldValue,
                  dirty,
                  isValid,
                  handleSubmit,
                  handleChange,
                  values,
                  errors,
              }) => (
                <div className="form-container">
                    <Title level={5}>Add Vehicle Firmware</Title>
                    <Text className="description" type="secondary">
                        Upload new version of firmware.
                    </Text>
                    <br />
                     <br />
                    <Form className="--add-users-form" onFinish={handleSubmit}>
                        <section className="fixed-height-form">

                            <FormItem
                                label=""
                                error={errors.document_file}
                                verticalAlign="flex-start"
                            >
                                <Upload.Dragger
                                    name="document_file"
                                    multiple={false}
                                    fileList={values.fileList}
                                    onChange={({ fileList }) => {
                                        // Getting total number of files
                                        const totalFiles = fileList.length;

                                        // If multiple files, pick last file
                                        if (totalFiles > 1) {
                                            setFieldValue("fileList", [fileList[totalFiles - 1]]);
                                        } else {
                                            setFieldValue("fileList", fileList);
                                        }
                                    }}
                                    beforeUpload={async (file) => {
                                        const unit8Arr = await fileToByteArray(file);
                                        setFieldValue("document_file", unit8Arr);
                                        setFieldValue("document_file_raw", file);
                                    }}
                                >
                                    {uploadIcon}
                                    <p className="ant-upload text">
                                        Drag the document here or
                                        <span className="secondary-color"> browse</span> from system
                                    </p>
                                </Upload.Dragger>
                            </FormItem>
                        </section>
                        <div className="action-btn-container">
                            <>
                                <Button className="cancel-btn" onClick={onCancel}>
                                    Cancel
                                </Button>
                                <Button
                                    htmlType="submit"
                                    type="primary"
                                    disabled={(!isValid && dirty)}
                                >
                                    Save
                                </Button>
                            </>
                        </div>
                    </Form>
                </div>
            )}
        </Formik>
    );
}
