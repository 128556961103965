import { useCallback } from "react";
import {useDispatch, useSelector} from "react-redux";

import { VehicleStatus } from "protobuf/vehicle_service_pb";
import { classNames } from "utils/helpers";
import {VEHICLE_FUNCTIONAL_STATUS, VEHICLE_STATUS} from "utils/mapped-data";
import { parseVehiclesTable } from "utils/parsers";
import { editIcon, archiveIcon } from "svgs";
import { Tag } from "components/base";
import { setModalName, setSelectedVehicle } from "store/actions";
import { issuesTableData } from "./__mock__";
import { Link } from "react-router-dom";
import { ROUTES } from "utils/constants";
import { FullscreenOutlined } from "@ant-design/icons";
import DropdownAction from "./dropdown-action";
import { Tooltip } from "antd";
import {AppState} from "../../../store";
import ReactDOM from 'react-dom';

export function functionalStatusCell(functionalStatus: VehicleStatus.Enum) {
  const statusStr = VEHICLE_STATUS[functionalStatus];
  const classes = classNames({
    "--green": statusStr === "Ride In-progress",
    "--orange": statusStr === "In Maintenance",
    "--red": statusStr === "Marked Broken",
    "--yellow": statusStr === "Retired",
    "--purple": statusStr === "Idle",
    "--gray": statusStr === "Unknown",
  });
  return (
    <span className={`functional-status-cell ${classes}`}>{statusStr}</span>
  );
}

export function functionalStatusOnOffCell(statusStr:string) {
  const classes = classNames({
    "--green": statusStr === "ON",
    "--purple": statusStr === "OFF",
  });
  return (
      <span className={`functional-status-cell ${classes}`}>{statusStr}</span>
  );
}

export function getMapFunctionalStatusOnOffCell(statusStr:string) {
  const classes = classNames({
    "--green": statusStr === "ON",
    "--purple": statusStr === "OFF",
  });
  return `<span  class="functional-status-cell ${classes}" style="padding-left: 5px"><b>${statusStr}</b></span>`; //functional-status-cell
}

export function serviceStatusCell(statusStr: string) {
  const classes = classNames({
    "--orange": statusStr === "In Maintenance",
    "--red": statusStr === "Marked Broken",
    "--yellow": statusStr === "Retired",
    "--green": statusStr === "Unknown" ||  statusStr === "Operational",
  });
  return (
      <span className={`service-status-cell ${classes}`}>{statusStr}</span>
  );
}
export function UpdateVehicle(
  value: string,
  row: ReturnType<typeof parseVehiclesTable>[number]
) {
  const dispatch = useDispatch();

  const { raw: vehicle } = row;

  const onSelectVehicle = useCallback(() => {
    dispatch(setSelectedVehicle(vehicle));
  }, [dispatch, vehicle]);

  const onVehicleDetail = useCallback(() => {
    dispatch(setSelectedVehicle(row.raw));
  }, [dispatch, row.raw]);

  const onEditVehicle = useCallback(
    (e) => {
      e.stopPropagation();
      dispatch(setSelectedVehicle(vehicle));
      dispatch(setModalName('edit_vehicle'));
    },
    [vehicle, dispatch]
  );
  const onDeleteVehicle = useCallback(
    () => {
      onSelectVehicle();
      dispatch(setModalName('delete_vehicle'));
    },
    [onSelectVehicle, dispatch]
  );

  const onUpdateFirmwareVehicle = useCallback(
    () => {
   
      onSelectVehicle();
      dispatch(setModalName('bulk_update_vehicle_firmware'));
    },
    [onSelectVehicle, dispatch]
  );

  const {
    common: { userType =1 },
  } = useSelector<AppState, AppState>((state) => state);

  return (
    <section className="table-action-container update-vehicle show-on-hover">
      {userType != 6 && userType != 3 && <>
      <span className="edit-icon icon" onClick={onEditVehicle}>
        {editIcon}
      </span>

      <DropdownAction vehicle={row.raw} /> </>
      }
    </section>
  );
}

export function renderResolvedOn(
  value: string,
  row: typeof issuesTableData[number]
) {
  const availabilityClass = classNames({
    "text-light": row.resolved_on === "Ongoing",
  });
  return <span className={availabilityClass}>{row.resolved_on}</span>;
}

export function renderDueDate(
  value: string,
  row: typeof issuesTableData[number]
) {
  const availabilityClass = classNames({
    "text-light": row.due_date === "Not Available",
  });
  return <span className={availabilityClass}>{row.due_date}</span>;
}

export function renderUtilizationStatus(
  value: string,
  row: ReturnType<typeof parseVehiclesTable>[number]
) {
  const { status } = row.raw;

  switch (status) {
    case 0:
      return "In the Lot";
    case 1:
      return "Checked Out";
    case 2:
      return "In the Lot";
    case 3:
      return "In the Lot";
    case 4:
      return "In the Lot";
    case 5:
      return "In the Lot";

    default:
      return "Checked Out";
  }
}

export function renderAssignees(
  value: string,
  row: ReturnType<typeof parseVehiclesTable>[number]
) {
  const { assignee } = row.raw;
  const assigneeList = assignee?.map((assigne) => assigne.firstName).join(", ");
  return (
    <section className="assignees-container">
      {assignee
        ?.filter((data, index) => index < 1)
        .map((assigne) => (
          <Tag customClasses="vehicle-id-tag-list --fixed-width --gray">
            {assigne.firstName}
          </Tag>
        ))}
      {assignee && assignee.length > 1 && (
        <Tooltip title={assigneeList} color="blue">
          <span className="count-box">{`+${assignee.length - 1}`}</span>
        </Tooltip>
      )}
    </section>
  );
}

