import { Typography } from "antd";

import {
  checkIcon,
  infoIcon,
  crossIcon,
  largePhoneIcon,
  bikeIcon,
  largeMailIcon, editIcon,
} from "svgs";
import { clientsTableData } from "./__mock__";
import {ClientsState} from "types";
import {useDispatch} from "react-redux";
import {useCallback} from "react";
import {setModalName, setSelectedClient} from "store/actions";
import DropdownAction from "../battery/dropdown-action";

const { Text } = Typography;

export function ActionMenu(
    value: string,
    row:  {
        raw: ClientsState["selectedOrganization"];
    }
) {
    const dispatch = useDispatch();

    const onEditClient = useCallback(() => {
        dispatch(setSelectedClient(row.raw));
        dispatch(setModalName("edit_organization"));
    }, [dispatch, row.raw]);

    return (
        <section className="table-action-container show-on-hover">
      <span className="edit-icon icon" onClick={onEditClient} title="edit organization">
        {editIcon}
      </span>
            {/*<DropdownAction battery={row.raw} />*/}
        </section>
    );
}

export function renderEmptyContent(value: string) {
  return {
    children: value,
    props: {
      colSpan: 0,
    },
  };
}

export function clientsDetailsCell(
  value: string,
  row: typeof clientsTableData[number]
) {
  const { enquiry_name } = row;
  return (
    <p className="list-detail">
      <Text className="" type="secondary">
        <strong>{enquiry_name}</strong>
      </Text>
      <Text type="secondary" className="list-container">
        <article className="list-item">
          <span className="icon ">{largePhoneIcon}</span>
          {row.enquiry_phone}
        </article>
        <article className="list-item">
          <span className="icon ">{bikeIcon}</span>
          Lease for {row.enquiry_lease_duration}
        </article>
        <article className="list-item">
          <span className="icon ">{largeMailIcon}</span>
          {row.enquiry_email}
        </article>
      </Text>
    </p>
  );
}

export function renderAction() {
  return (
    <section className="action-cell">
      <section className=" hide-on-hover">
        <Text className="" type="secondary">
          Received 2 days ago
        </Text>
      </section>
      <section className="action-buttons-container show-on-hover ">
        <article className="action-button --info show-on-hover">
          {infoIcon} <span>Request Info</span>
        </article>
        <article className="action-button --success show-on-hover">
          {checkIcon} <span>Approve</span>
        </article>
        <article className="action-button --danger show-on-hover">
          {crossIcon} <span>Reject</span>
        </article>
      </section>
    </section>
  );
}

export function renderClientname(
  value: string,
  row: typeof clientsTableData[number]
) {
  return (
    <>
      <figure className="user-icon-container-table">
        <img
          className="image-rounded"
          src={`https://picsum.photos/22?random=${row.vehicle_count}`}
          alt="user pic"
        />
        <span className="user-name">{row.client_name}</span>
      </figure>
    </>
  );
}


