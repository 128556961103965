import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {getBatteryModelsDropdown, getClients, setModalName} from "store/actions";
import {parseBatteryModels, parseClients} from "utils/parsers";
import { AppState } from "store";
import { BatteryValues } from "types/formik";
import { BATTERY_FIRMWARE } from "utils/formik-data";
import AddBatteryForm from "./add_battery"


export type StateBATTERY_FIRMWARE = BatteryValues & { };

export default function AddBatteriesForm() {
    const [{ values }, setState] = useState<{
        values: StateBATTERY_FIRMWARE;
    }>({
        values: { ...BATTERY_FIRMWARE.initialValues },
    });

    const dispatch = useDispatch();

    const {
        batteryModels: { dropdownBatteryModels },
        vehicles: {clients}
    } = useSelector<AppState, AppState>((state) => state);

    const batteryModelsOptions = useMemo(() => parseBatteryModels(dropdownBatteryModels), [
        dropdownBatteryModels,
    ]);
    const clientOptions = useMemo(() => parseClients(clients), [clients]);


    const onCancel = useCallback(() => {
        dispatch(setModalName(undefined));
    }, [dispatch]);

    useEffect(() => {
        dispatch(getBatteryModelsDropdown())
        dispatch(getClients());
    }, [dispatch, dropdownBatteryModels]);

    const setValues = useCallback(
        (values: BatteryValues) => {
            console.log("Hell val First : ", values)

            setState({
                values: {
                    ...values,

                },
            });
        },

        [dispatch, onCancel]
    );

    return  (
        <AddBatteryForm
            initialValues={values}
            batteryModelOptions={batteryModelsOptions}
            clientOptions={clientOptions}
            setValues={setValues}
            onCancel={onCancel}
        />
    );
}