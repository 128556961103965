import { ReactNode, ReactText, useCallback, useEffect, useState } from "react";
import { Collapse } from "antd";
import { CheckboxOptionType } from "antd/lib/checkbox";
import { CollapsePanelProps } from "antd/lib/collapse";

import { Checkbox, Input } from "components/base";
import CheckBoxGroup from "components/checkbox-group";
import { searchIcon, infoGrayIcon } from "svgs";

const { Panel } = Collapse;

interface ComponentProps {
  name: string;
  options: ({ render?: ReactNode } & CheckboxOptionType)[];
  value: string[];
  search?: boolean;
  suffix?: boolean;
  onChange: (
    name: string,
    value: boolean | string[],
    type: "list" | "all"
  ) => void;
}

export default function CheckboxPanelList(
  props: ComponentProps & CollapsePanelProps
) {
  const {
    name,
    options,
    value,
    search,
    suffix,
    onChange: callback,
    ...rest
  } = props;
  const [stateOptions, setStateOptions] = useState(options);

  const isAllChecked = value.length === options.length;
  // if checkboxes are checked but not all of them
  const indeterminate = !isAllChecked && value.length > 0;
  const onChange = useCallback(
    (value) => {
      callback(name, value, "list");
    },
    [callback, name]
  );
  const onAllChange = useCallback(
    (event) => {
      const { checked } = event.target;
      callback(name, checked, "all");
    },
    [callback, name]
  );
  const onSearch = useCallback(
    (event) => {
      const { value } = event.target;
      const filteredOptions = options.filter((option) =>
        option?.label?.toString()?.toLowerCase().includes(value.toLowerCase())
      );
      setStateOptions(filteredOptions);
    },
    [options]
  );

  useEffect(() => {
    setStateOptions(options);
  }, [options]);

  return (
    <Panel
      {...rest}
      header={
        <Checkbox
          customClasses="--filter-header"
          checked={isAllChecked}
          onChange={onAllChange}
          indeterminate={indeterminate}>
          {name}
          {suffix && infoGrayIcon}
        </Checkbox>
      }>
      {search && (
        <Input
          prefix={searchIcon}
          placeholder="Search..."
          customClasses="filter-search"
          onChange={onSearch}
        />
      )}
      <CheckBoxGroup
        onChange={onChange}
        options={stateOptions}
        value={value}
        suffix={suffix}
      />
    </Panel>
  );
}
