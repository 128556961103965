import { Modal as ModalAnt } from "antd";
import { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import { AppState } from "store";
import {
  AddVehiclesForm,
  AddIssuesForm,
  AddUsersForm,
  AddTaskForm,
  ViewTaskDetailsForm,
  EditShiftForm,
  ImportZoneForm,
  UploadPic,
  EditUser,
  ImportCsv,
  EditRole,
  EditIssuesForm,
  DeleteIssueForm,
  UndoDeleteIssueForm,
  DeleteUserForm,
  DeleteVehicleForm,
  BlockAccountForm,
  FirmwareVehicleForm,
  AddFirmwareForm,
  BulkVehicleFirmwareUpdateForm,
  AddVehicleModelsForm,
  EditVehicleModelForm,
  DeleteVehicleModelForm,
  UploadModelFirmware,
  UpdateModelFirmwareForm,
  AddBatteriesForm,
  EditBatteryForm,
  EditBatteryModelForm,
  AddBatteryModelsForm,
  DeleteBatteryModelForm,
  DeleteBatteryForm,
  AddOrganizationForm,
  EditOrganizationForm,
  AddZoneModalForm,
  EditZoneModalForm,
  ChangeMqttServerFormForm,
  ImportCsvBattery,
} from "forms";
import { setModalName  } from "store/actions";
import { CommonState } from "types/store";
import { classNames } from "utils/helpers";
import EditVehiclesForm from "forms/edit-vehicle";
import AddPaymentForm from "forms/add-payment";
import { KeyValue } from "types";
import ErrorDisplay from "components/error";

function getModalContent(modalName: CommonState["modalName"], props: KeyValue) {
  if (modalName) {
    switch (modalName) {
      case "display_error":
        return <ErrorDisplay />;
      case "add_vehicle":
        return <AddVehiclesForm />;
      case "add_issue":
        return <AddIssuesForm />;
      case "add_issue_from_vehicle":
        return <AddIssuesForm vehicleId={props.vehicleId} />;
      case "edit_issue":
        return <EditIssuesForm />;
      case "add_user":
        return <AddUsersForm />;
      case "add_task":
        return <AddTaskForm />;
      case "view_task_details":
        return <ViewTaskDetailsForm />;
      case "edit_shift":
        return <EditShiftForm />;
      case "import_zones":
        return <ImportZoneForm />;
      case "edit_user":
        return <EditUser />;
      case "edit_role":
        return <EditRole />;
      case "upload_pic":
        return <UploadPic />;
      case "import_csv":
        return <ImportCsv />;
      case "import_battery_csv":
        return <ImportCsvBattery />;
      case "delete_issue":
        return <DeleteIssueForm />;
      case "undo_delete_issue":
        return <UndoDeleteIssueForm />;
      case "delete_user":
        return <DeleteUserForm />;
      case "block_account":
        return <BlockAccountForm />;
      case "delete_vehicle":
        return <DeleteVehicleForm />;
      case "firmware_vehicle":
        return <FirmwareVehicleForm />;
      case "edit_vehicle":
        return <EditVehiclesForm />;
      case "add_payment":
        return <AddPaymentForm />;
      case "add_firmware_vehicle":
        return <AddFirmwareForm />;
      case "bulk_update_vehicle_firmware":
        return <BulkVehicleFirmwareUpdateForm />;
      case "add_vehicle_model":
        return <AddVehicleModelsForm />;
      case "edit_vehicle_model":
        return <EditVehicleModelForm />;
      case "delete_vehicle_model":
        return <DeleteVehicleModelForm />;
      case "add_model_firmware":
        return <UploadModelFirmware />;
      case "update_model_firmware":
        return <UpdateModelFirmwareForm />;
      case "add_battery":
        return <AddBatteriesForm />;
      case "edit_battery":
        return <EditBatteryForm />;
      case "edit_battery_model":
        return <EditBatteryModelForm />;
      case "add_battery_model":
        return <AddBatteryModelsForm />;
      case "delete_battery_model":
        return <DeleteBatteryModelForm />;
      case "delete_battery":
        return <DeleteBatteryForm />;
      case "add_organization":
        return <AddOrganizationForm />;
      case "add_zone_modal":
        return <AddZoneModalForm />;
      case "edit_zone_modal":
        return <EditZoneModalForm />;
      case "edit_organization":
        return < EditOrganizationForm />;
      case "change_mqtt_server":
        return < ChangeMqttServerFormForm />;
      default:
        return null;
    }
  }

  return null;
}

export default function Modal() {
  const {
    common: { modalName },
    vehicles: { selectedVehicle: { id: vehicleId } = {} },
  } = useSelector<AppState, AppState>((state) => state);
  const dispatch = useDispatch();

  const onCancel = useCallback(() => {
    dispatch(setModalName(undefined));
  }, [dispatch]);

  const classes = useMemo(
    () =>
      classNames({
        "add-user": modalName === "add_user",
        "add-task": modalName === "add_task",
        "add-vehicle": modalName === "add_vehicle",
        "edit-shift": modalName === "edit_shift",
        "upload-pic": modalName === "upload_pic",
        "edit-user": modalName === "edit_user",
        "edit-role": modalName === "edit_role",
        "import-csv": modalName === "import_csv",
        "add-payment": modalName === "add_payment",
        "delete-user": modalName === "delete_user",
        "block-account": modalName === "block_account",
        "add-edit-issues":
          modalName === "add_issue" ||
          modalName === "edit_issue" ||
          modalName === "add_issue_from_vehicle",
      }),
    [modalName]
  );

  return (
    <ModalAnt
      className={classes}
      visible={Boolean(modalName)}
      onCancel={onCancel}
      footer={null}
      closable={false}
      destroyOnClose>
      {getModalContent(modalName, { vehicleId })}
    </ModalAnt>
  );
}
