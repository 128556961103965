import { ReactNode } from "react";
import { Link } from "react-router-dom";
import { backCircularIcon } from "svgs";
import { ROUTES } from "utils/constants";
import { classNames } from "utils/helpers";

interface ComponentProps {
  type: "login" | "verify";
  children: ReactNode;
}

export default function LoginLayout(props: ComponentProps) {
  const { children, type } = props;
  const classes = classNames({
    "login-page": true,
    "--phone-number": type === "login",
    "--verify-otp": type === "verify",
  });

  return (
    <>
      {type === "verify" && (
        <Link to={ROUTES.login} className="back-circle">
          {backCircularIcon}
        </Link>
      )}
      <main className={classes}>{children}</main>
    </>
  );
}
