
import React from 'react';
import { useSelector, useDispatch  } from 'react-redux';
import { AppState } from "store";


const ErrorDisplay = () => {
    const { errorMessage } = useSelector<AppState, AppState["common"]>(
        (state) => state.common
    );

    const dispatch = useDispatch();

    if (!errorMessage) {
        return null;
    }
    const errorMessagesArray = errorMessage.split('- ').map(msg => msg.trim());


    return (
       
        <div style={styles.dialog}>
            <h2 style={styles.heading}>Errors while uploading CSV: </h2>
            <ul style={styles.messageList}>
                    {errorMessagesArray.map((error, index) => (
                        <li key={index} style={styles.messageItem}>{error}</li>
                    ))}
                </ul>
        </div>
      
    );
};

const styles: { [key: string]: React.CSSProperties } = {
    dialog: {
        border: '1px solid red',
        borderRadius: '5px',
        padding: '20px',
        margin: '0px',
        backgroundColor: '#ffe6e6',
        color: 'red',
        maxWidth: '500px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    },
    heading: {
        margin: '0 0 10px 0',
        fontSize: '18px',
    },
    messageList: {
        margin: '0 0 10px 0',
        padding: '0 20px',
        listStyleType: 'disc',
        color: 'red',
    },
    messageItem: {
        margin: '5px 0',
    },
};



export default ErrorDisplay;
