import { Typography } from "antd";

import RadioGroup from "components/radio-group";

const { Text } = Typography;

const qrFormat = [
  { label: "Alphanumeric", value: "Alphanumeric" },
  { label: "Numeric", value: "Numeric" },
];
const qrSize = [
  { label: "6", value: "6" },
  { label: "8", value: "8" },
  { label: "Other", value: "Other" },
];

export default function QrFormat() {
  return (
    <section className=" grid-container qr-code">
      <Text type="secondary" className="title">
        QR Code Format
      </Text>
      <span className="setting">
        <section className="qr-format">
          <Text>Select the format of QRCode.</Text>

          <RadioGroup options={qrFormat} defaultValue="Alphanumeric" />
        </section>
        <section className="qr-character">
          <Text>Select the size/number of characters of QRCode.</Text>
          <RadioGroup options={qrSize} defaultValue="6" />
        </section>
      </span>
    </section>
  );
}
