import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidV4 } from "uuid";

import {addUser, getClients, getDropdownVehicles, setModalName} from "store/actions";
import {
  dialCodePrefix,
  numberWithoutCode,
  splitFullName,
} from "utils/helpers";
import {
    parseDropdownOrganization,
    parseOrganizationClients,
    parseVehicles
} from "utils/parsers";
import { AppState } from "store";
import { UserValues } from "types/formik";
import { UserSummary } from "./user-summary";
import AddUserForm from "./form";
import { USER_FORMIK } from "utils/formik-data";

export type StateUserValues = UserValues & { no_plate: string[] };

export default function AddUsersForm() {
  const [{ showUserSummary, values }, setState] = useState<{
    showUserSummary: boolean;
    values: StateUserValues;
  }>({
    showUserSummary: false,
    values: { ...USER_FORMIK.initialValues, no_plate: [] },
  });
  const dispatch = useDispatch();
  const {
      vehicles: { dropdownVehicles, clients },
      common: { userType =1 },
  } = useSelector<AppState, AppState>((state) => state);

  const vehiclesOptions = useMemo(() => parseVehicles(dropdownVehicles), [
    dropdownVehicles,
  ]);

  const organizationOptions = useMemo( () => parseDropdownOrganization(clients), [clients])
    useEffect(() => {

        if (!dropdownVehicles) {
            dispatch(getDropdownVehicles());
        }
        if (!clients) {
            dispatch(getClients())
        }

    }, [dispatch, clients, dropdownVehicles]);
  const onCancel = useCallback(() => {
    dispatch(setModalName(undefined));
  }, [dispatch]);

  const setShowUserSummary = useCallback(() => {
    setState((st) => ({ ...st, showUserSummary: false }));
  }, []);

  const setValues = useCallback(
    (values: UserValues) => {
      const selectedVehicles = dropdownVehicles?.filter((vehicle) =>
        values.vehicle?.includes(vehicle.id)
      );
      setState({
        showUserSummary: true,
        values: {
          ...values,
          no_plate:
            selectedVehicles?.map((vehicle) => vehicle.licensePlate) || [],
        },
      });
    },
    [vehiclesOptions]
  );

  const addUserCallback = useCallback(() => {
    const splitedName = splitFullName(values.full_name.trim());

    dispatch(
      addUser({
        email: values.email || uuidV4() + "@fluxnet.co",
        firstName: splitedName.firstName,
        lastName: splitedName.lastName,
        password: "",
        type: Number(values.role),
        countryPrefix: dialCodePrefix(values.dial_code),
        number: numberWithoutCode(values.phone_no, values.dial_code),
        documentId: values.document_id || "",
        licenceNo: values.licence_no || "",
        document: values.document_file || "",
        vehicleId: values.vehicle || [],
        documentExt: values.document_file_raw?.type || "",
        documentName: values.document_file_raw?.name || "",
          organizationId:values.organization_id || "",
      })
    );
  }, [dispatch, values]);



  return showUserSummary ? (
    <UserSummary
      values={values}
      addUserCallback={addUserCallback}
      setShowUserSummary={setShowUserSummary}
    />
  ) : (
    <AddUserForm
      initialValues={values}
      vehiclesOptions={vehiclesOptions}
      organizationOptions={organizationOptions}
      onCancel={onCancel}
      setValues={setValues}
      userType={userType}

    />
  );
}
