import { exampleTileChartIcon } from "svgs";
import { getPercentageValue, getMaxBar } from "utils/helpers";
import { FixedPlacementBar } from ".";

const mockData = [
  {
    name: "Overspeeding",
    value: 10,
    totalValue: 10 + getPercentageValue(5, 10),
    color: "#FDE2E2",
    totalColor: "#ED2020",
  },
  {
    name: "Zone Breached",
    value: 30,
    totalValue: 30 + getPercentageValue(5, 30),
    color: "#FDE2E2",
    totalColor: "#ED2020",
  },
  {
    name: "Roadside Assistance",
    value: 15,
    totalValue: 15 + getPercentageValue(5, 15),
    color: "#FDE2E2",
    totalColor: "#ED2020",
  },
  {
    name: "Rash Driving",
    value: 25,
    totalValue: 25 + getPercentageValue(5, 25),
    color: "#FDE2E2",
    totalColor: "#ED2020",
  },
];

const RenderCustomTitle = () => (
  <article className="custom-chart-header">
    <section className="left-side">
      <strong>Alert Stats</strong>
      <a href="" className="link">
        View All
      </a>
    </section>
    <section className="right-side">
      <strong className="count">80</strong>
      <span> {exampleTileChartIcon}</span>
    </section>
  </article>
);

export default function AlertStats() {
  const maxValue = getMaxBar(mockData);
  const extraValue = getPercentageValue(1, maxValue);
  const data = mockData.map((bar) => ({
    ...bar,
    totalValue: bar.value + extraValue,
  }));

  return (
    <FixedPlacementBar
      title={<RenderCustomTitle />}
      data={data}
      sameBarHeight
      scaleVisibility={false}
      renderValue={(data) => data.value}
    />
  );
}
