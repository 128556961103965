import { useMemo } from "react";
import { useSelector } from "react-redux";
import { AppState } from "store";
import { ALL_WIDGETS } from "utils/constants";
import { getDashboardWidget } from "utils/helpers";

export interface ChartContainerProps {
  selectedDate?: string;
}

export default function ChartContainer({ selectedDate }: ChartContainerProps) {
  const { selectedDashboard } = useSelector<AppState, AppState["dashboard"]>(
    (state) => state.dashboard,
  );
  //Uncomment this for all features in dropdown (issues,vehicle,financials)
  // const chartRepo = useMemo(() => ALL_WIDGETS[selectedDashboard], [
  //   selectedDashboard,
  // ]);

  
  const chartRepo = useMemo(() => ALL_WIDGETS["financials"], [
    "financials",
  ]);

  return (
    
    <div className="dashboard-charts-container">
      {chartRepo.map((chart) => {
        
        const Widget = getDashboardWidget(chart.widget, selectedDate!);
        return chart.isWrapped ? (
          <div
            key={chart.name}
            className={`widget-item custom-card-wrapper ${chart.className}`}>
            <div className="chart-card">
              <Widget selectedDate ={selectedDate}/>
            </div>
          </div>
        ) : (
          <div key={chart.name} className={`widget-item ${chart.className}`}>
            <Widget selectedDate={selectedDate} />
          </div>
        );
      })}
    </div>
  );
}
