import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidV4 } from "uuid";

import { addFirmWare, getDropdownVehicles, setModalName } from "store/actions";
import {
    dialCodePrefix,
    numberWithoutCode,
    splitFullName,
} from "utils/helpers";
import { parseVehicles } from "utils/parsers";
import { AppState } from "store";
import { VehicleModelValues } from "types/formik";
import { VEHICLE_MODEL_FORMIK } from "utils/formik-data";
import AddVehicleModelForm from "./add_model"


export type StateVehicleModelValues = VehicleModelValues & { };

export default function AddVehicleModelsForm() {
    const [{ values }, setState] = useState<{
        values: StateVehicleModelValues;
    }>({
        values: { ...VEHICLE_MODEL_FORMIK.initialValues },
    });

    const dispatch = useDispatch();

    const onCancel = useCallback(() => {
        dispatch(setModalName(undefined));
    }, [dispatch]);

    const setValues = useCallback(
        (values: VehicleModelValues) => {
            console.log("Hell val First : ", values)

            setState({
                values: {
                    ...values,

                },
            });
            // dispatch(
            //     addFirmWare({
            //         document: values.document_file || "",
            //         documentExt: values.document_file_raw?.type || "",
            //         documentName: values.document_file_raw?.name || "",
            //     })
            // );
        },

        [dispatch, onCancel]
    );

    const addFirmwareCallback = useCallback(() => {

        // dispatch(
        //     addFirmWare({
        //         document: values.document_file || "",
        //         documentExt: values.document_file_raw?.type || "",
        //         documentName: values.document_file_raw?.name || "",
        //     })
        // );
    }, [dispatch, values]);

    return  (
        <AddVehicleModelForm
            initialValues={values}
            setValues={setValues}
            addFirmwareCallback={addFirmwareCallback}
            onCancel={onCancel}
        />
    );
}