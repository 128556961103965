import { useState } from "react";

import StepOne from "./step-one";
import StepTwo from "./step-two";
import EmptyState from "./empty-state";

export default function NextStepForm() {
  const [step, setStep] = useState(1);

  const NextStep = (updatedStep: number) => {
    setStep(updatedStep);
    console.log(step);
  };

  switch (step) {
    case 1:
      return <StepOne updateStep={NextStep} />;
    case 2:
      return <StepTwo updateStep={NextStep} />;

    default:
      return <EmptyState />;
  }
}
