import {useCallback, useEffect, useMemo} from "react";
import { useDispatch, useSelector } from "react-redux";

import { AppState } from "store";
import { Table, TablePagination } from "components/base";
import { parseVehicleModelTable } from "utils/parsers";
import {
    initializeVehicleModel,
    setIssuesSelectedPage, setVehicleModels,
    setSelectedVehicleModel,
} from "store/actions";
import { ActionMenu, issueTypeCell, statusCell } from "./cell-renders";
import { TableProps } from "antd/lib/table";

const columns: TableProps<any>["columns"] = [
    // {
    //     title: "Model ID",
    //     dataIndex: "model_id",
    //     width: 130,
    //     ellipsis: true,
    // },
    {
        title: "Model",
        dataIndex: "name",
        width: 150,
        ellipsis: true,
    },
    {
        title: "Make",
        dataIndex: "make",
        width: 130,
        ellipsis: true,
    },
    {
        title: "Year",
        dataIndex: "year",
        width: 130,
        ellipsis: true,
    },
    {
        title: "Firmware version",
        dataIndex: "firmware_version",
        width: 150,
        ellipsis: true,
    },
    {
        title: "",
        dataIndex: "action",
        render: ActionMenu,
        align: "right",
        // width: 56,
        className: "action-menu open-link --issues",
    },
];

export default function ModelTableView() {
  const dispatch = useDispatch();
  const {
    models,
    page,
    search,
    selectedModelsRows,
    filters: { listType },
  } = useSelector<AppState, AppState["models"]>((state) => state.models);
  const { selectedPage, totalPages } = page;
  const data = useMemo(
    () => parseVehicleModelTable(search, models, listType),
    [models, listType, search]
  );
    useEffect(() => {
        dispatch(initializeVehicleModel());
    }, [dispatch]);

    console.log("Data in models : = ", data)
  const onChangeSelection = useCallback(
    (value) => {
      dispatch(setSelectedVehicleModel(value));

      // if (value.length !== 0) {
      //   dispatch(setBulkActionsIssues(true));
      // } else {
      //   dispatch(setBulkActionsIssues(false));
      // }
    },
    [dispatch]
  );


  const onBack = useCallback(() => {
    if (selectedPage > 1) {
      dispatch(setIssuesSelectedPage(selectedPage - 1));
    }
  }, [dispatch, selectedPage]);

  const onNext = useCallback(() => {
    if (selectedPage < totalPages) {
      dispatch(setIssuesSelectedPage(selectedPage + 1));
    }
  }, [dispatch, selectedPage, totalPages]);

  const resetCallback = useCallback(() => {
    dispatch(setIssuesSelectedPage(1));
  }, [dispatch]);

  return (
    <>
      <Table
        columns={columns}
        dataSource={data}
        // rowSelection={{
        //   selectedRowKeys: selectedModelsRows,
        //   onChange: onChangeSelection,
        // }}
        pagination={false}
        scroll={{ y: "70vh" }}
      />
      {/*<TablePagination*/}
      {/*  page={page}*/}
      {/*  onBack={onBack}*/}
      {/*  onNext={onNext}*/}
      {/*  resetCallback={resetCallback}*/}
      {/*/>*/}
    </>
  );
}
