import {useCallback, useEffect, useMemo, useState} from "react";
import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { Form, Button, Typography, Input, Upload } from "antd";
import {AppState} from "../../store";
import { VehicleModelValues } from "../../types/formik";
import {editVehicleModel, setModalName} from "../../store/actions";
import {VEHICLE_MODEL_FORMIK} from "../../utils/formik-data";
import {FormItem, SelectSearch} from "../../components";
import * as google_protobuf_struct_pb from 'google-protobuf/google/protobuf/struct_pb';
import {Struct, Value} from "google-protobuf/google/protobuf/struct_pb";
const { Title, Text } = Typography;


export default function EditVehicleModelForm(){
    const dispatch = useDispatch()
    const {
       models: {selectedModels},
    } = useSelector<AppState, AppState>((state) => state);

    const onCancel = useCallback(() => {
        dispatch(setModalName(undefined));
    }, [dispatch]);
    const struct = new Struct();
    struct.getFieldsMap().set('foo', Value.fromJavaScript('bar'));
    const onSubmit = useCallback(
        (values: VehicleModelValues) => {
            dispatch(
                editVehicleModel({
                    id: selectedModels?.id || "",
                    make: values.make.trim(),
                    name: values.model.trim(),
                    year: parseInt(values.year),
                    ownerOrgId: selectedModels?.ownerOrgId || "",
                })
            )
            onCancel();
        },
        [dispatch, onCancel, selectedModels?.id]
    );


    const initialValues = useMemo(() => {
       return {
            model_id: selectedModels?.id,
            make: selectedModels?.make || "",
            model: selectedModels?.name || "",
            year: selectedModels?.year.toString() || '2023',
           transmission: selectedModels?.transmission || "Automatic",
           document_file_raw: undefined,
           document_file: undefined,
           fileList: undefined,
           modelFiles: [],
       }
   }, [selectedModels])

    const transmissionOption = [
        {
            label: "Manual",
            value: "Manual"
        },
        {
            label: "Automatic",
            value: "Automatic"
        }
    ]

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={VEHICLE_MODEL_FORMIK.validationSchema}
            onSubmit={onSubmit}
            enableReinitialize
        >
            {({
                  setFieldValue,
                  dirty,
                  isValid,
                  handleSubmit,
                  handleChange,
                  values,
                  errors,
              }) => (
                <div className="form-container">
                    <Title level={5}>Edit Model</Title>
                    <Text className="description" type="secondary">
                        Add New Model.
                    </Text>
                    <Form className="--add-vehicles-form" onFinish={handleSubmit}>
                        <section className="">

                            <FormItem label="Model" error={errors.model} required>
                                <Input
                                    name="model"
                                    value={values.model}
                                    onChange={handleChange}
                                    placeholder="Name"
                                />
                            </FormItem>
                            <FormItem label="Make" error={errors.make} required>
                                <Input
                                    name="make"
                                    value={values.make}
                                    onChange={handleChange}
                                    placeholder="Make"
                                />
                            </FormItem>
                            <FormItem label="Year" error={errors.year} required>
                                <Input
                                    name="year"
                                    value={values.year}
                                    onChange={handleChange}
                                    placeholder="Year"
                                />
                            </FormItem>
                            <FormItem error={errors.transmission} label="Transmission">
                                
                                <SelectSearch
                                    value={values.transmission}
                                    items={transmissionOption}
                                    setFieldValue={setFieldValue}
                                    fieldName="transmission"
                                    placeholder="Select "
                                
                                />
                            </FormItem>
                        </section>
                        <div className="action-btn-container">
                            <>
                                <Button className="cancel-btn" onClick={onCancel}>
                                    Cancel
                                </Button>
                                <Button
                                    htmlType="submit"
                                    type="primary"
                                    disabled={(!isValid && dirty)}
                                >
                                    Save
                                </Button>
                            </>
                        </div>
                    </Form>
                </div>
            )}
        </Formik>
    );
}