import { Switch, Redirect } from "react-router-dom";
import { ROUTES } from "utils/constants";
import TableView from "./table-view";
import ProtectedRoute from "components/protected-route";
import { Key } from 'react';  // Import type for selected rows

interface NotificationsContentProps {
  selectedRows: Key[];  // Type for selected rows (React.Key array)
  onChangeSelection: (selectedKeys: Key[]) => void;  // Function type for selection change
}

export default function NotificationsContent({ selectedRows, onChangeSelection }: NotificationsContentProps) {
  return (
    <div className="notifications-content">
      <Switch>
        <ProtectedRoute exact path={ROUTES.notifications}>
          <TableView    selectedRows={selectedRows}
        onChangeSelection={onChangeSelection} />
        </ProtectedRoute>
      </Switch>
    </div>
  );
}
