import { Button, Dropdown, Menu } from "antd";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Input, SwitchButton } from "components/base";
import { useHistory, useLocation } from "react-router-dom";
import { setModalName, setVehiclesSearch } from "store/actions";
import {
    actionMenu,
    exportIcon,
    plusIcon,
    searchIcon,
    zonesSmallIcon,
    addAssigneeIcon,
    archiveIcon, uploadIconSmall,
} from "svgs";
import { ROUTES } from "utils/constants";
import { AppState } from "store";

export default function BatteryHeader() {
    const location = useLocation();
    const history = useHistory();
    const dispatch = useDispatch();

    const selectedView = location.pathname.split("/")[2];
    const { showbulkActionsVehicles } = useSelector<AppState, AppState["vehicles"]>(
        (state) => state.vehicles
    );
    const {
        common: { userType =1 },
    } = useSelector<AppState, AppState>((state) => state);

    const onBulkVehiclesFirmwareUpdate = useCallback(() => {
        dispatch(setModalName("bulk_update_vehicle_firmware"));
    }, [dispatch]);


    const onAddBattery = useCallback(() => {
        dispatch(setModalName("add_battery"));
    }, [dispatch]);

    const onSwitchView = useCallback(
        (event) => {
            const { value } = event.target;
            if(value == "Battery Models")
                history.push(`${ROUTES.battery_model}/table`);
        },
        [history]
    );

    const onImportCsv = useCallback(() => {
        dispatch(setModalName("import_battery_csv"));
    }, [dispatch]);

    const onChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            dispatch(setVehiclesSearch(event.target.value));
        },
        [dispatch]
    );
    const menu = (
        <Menu className="vehicle-drop-menu">
            <Menu.Item key="0" onClick={onImportCsv}>
                <span> Import CSV</span>
            </Menu.Item>
            {/*<Menu.Item key="1" disabled>*/}
            {/*    <span> Export</span>*/}
            {/*</Menu.Item>*/}
        </Menu>
    );

    return (
        <div className="common-header">
            <Input
                customClasses="--standard"
                placeholder="Search Batteries..."
                prefix={searchIcon}
                onChange={onChange}
            />
            {showbulkActionsVehicles && (
                <article className="bulk-actions">
                    <Button disabled danger icon={archiveIcon}>
                        Archive
                    </Button>
                </article>
            )}
            <div className="actions-container">

                <SwitchButton
                    label=""
                    options={["Batteries", "Battery Models"]}
                    defaultOption={"Batteries"}
                    onChange={onSwitchView}
                    type="secondary"
                />

                <div className="vertical-separator" />
                { userType == 1 || userType == 2  ?
                    <>
                     <Dropdown overlay={menu} trigger={["click"]} className="vehicle-menu">
                        <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
                            {actionMenu}
                        </a>
                    </Dropdown>
                    <Button
                        type="primary"
                        icon={<span className="custom-icon">{plusIcon}</span>}
                        onClick={onAddBattery}>
                        Add Battery
                    </Button> </> : ""
                }


            </div>
        </div>
    );
}
