import {useCallback, useEffect, useMemo, useState} from "react";
import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { Form, Button, Typography, Input, Upload } from "antd";
import {AppState} from "../../store";
import { BatteryValues } from "../../types/formik";
import {
    addBattery,
    editVehicleModel,
    getBatteryModelsDropdown,
    getDropdownVehicles,
    setModalName,
    updateBattery
} from "../../store/actions";
import { BATTERY_FIRMWARE} from "../../utils/formik-data";
import {FormItem, SelectSearch} from "../../components";
import * as google_protobuf_struct_pb from 'google-protobuf/google/protobuf/struct_pb';
import {Struct, Value} from "google-protobuf/google/protobuf/struct_pb";
import {parseBatteryModels, parseVehicles} from "../../utils/parsers";
const { Title, Text } = Typography;


export default function EditBatteryForm(){
    const dispatch = useDispatch()
    const {
        batteries: {selectedBattery},
        vehicles: { dropdownVehicles },
    } = useSelector<AppState, AppState>((state) => state);




    const {
        batteryModels: { dropdownBatteryModels },
    } = useSelector<AppState, AppState>((state) => state);

    const batteryModelsOptions = useMemo(() => parseBatteryModels(dropdownBatteryModels), [
        dropdownBatteryModels,
    ]);
    useEffect(() => {
        if (!dropdownBatteryModels) {
            dispatch(getBatteryModelsDropdown());
        }
    }, [dispatch, dropdownBatteryModels]);

    const onCancel = useCallback(() => {
        dispatch(setModalName(undefined));
    }, [dispatch]);

    const onSubmit = useCallback(
        (values: BatteryValues) => {
            dispatch(
                updateBattery({
                    id: selectedBattery?.id || "",
                    battery_id: values.battery_id.trim() || "",
                    battery_model_id: values.battery_model_id || "",
                })
            )
            onCancel();
        },
        [dispatch, onCancel, selectedBattery?.id]
    );


    const initialValues = useMemo(() => {
        return {
            battery_id: selectedBattery?.batteryId || "",
            battery_health: selectedBattery?.batteryHealth || "",
            operational_state: selectedBattery?.operationalState || "",
            host_id: selectedBattery?.hostId || "",
            battery_model_id: selectedBattery?.batteryModelId || "",
            soc_level: selectedBattery?.socLevel || "",
            make: "",
            model: selectedBattery?.make_model || "",
            year: 2024,
            client_org_id: selectedBattery?.clientOrgId || "",
            battery_alias: selectedBattery?.batteryAlias || "",
        }
    }, [selectedBattery])

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={BATTERY_FIRMWARE.validationSchema}
            onSubmit={onSubmit}
            enableReinitialize
        >
            {({
                  setFieldValue,
                  dirty,
                  isValid,
                  handleSubmit,
                  handleChange,
                  values,
                  errors,
              }) => (
                <div className="form-container">
                    <Title level={5}>Update Battery</Title>
                    <Text className="description" type="secondary">
                        Update battery details
                    </Text>
                    <Form className="--add-users-form" onFinish={handleSubmit}>
                        <section className="">

                            <FormItem label="Battery ID" error={errors.battery_id} required>
                                <Input
                                    disabled={true}
                                    name="battery_id"
                                    value={values.battery_id}
                                    onChange={handleChange}
                                    placeholder="Name"
                                />
                            </FormItem>
                            <FormItem label="Battery Model ID" error={errors.battery_model_id} required>
                                <SelectSearch
                                    disabled={true}
                                    isMultiple={false}
                                    value={values.battery_model_id || ""}
                                    items={batteryModelsOptions}
                                    setFieldValue={setFieldValue}
                                    fieldName="battery_model_id"
                                    placeholder="Select"
                                    onChangeProp={(value) => {
                                        setFieldValue("battery_model_id", value);
                                    }}
                                />
                            </FormItem>
                        </section>
                        <div className="action-btn-container">
                            <>
                                <Button className="cancel-btn" onClick={onCancel}>
                                    Cancel
                                </Button>
                                <Button
                                    htmlType="submit"
                                    type="primary"
                                    disabled={(!isValid && dirty)}
                                >
                                    Update
                                </Button>
                            </>
                        </div>
                    </Form>
                </div>
            )}
        </Formik>
    );
}