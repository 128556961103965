import { Button, Typography, Upload } from "antd";
import { DraggerProps, RcFile } from "antd/lib/upload";
import { useDispatch, useSelector } from "react-redux";
import { useCallback, useMemo, useState } from "react";

import { uploadIcon } from "svgs";
import { setModalName, uploadCsvVehicles } from "store/actions";
import { AppState } from "store";

const { Title } = Typography;
const { Dragger } = Upload;

export default function ImportCsv() {
  const [file, setFile] = useState<RcFile>();
  const dispatch = useDispatch();
  const { token = "" } = useSelector<AppState, AppState["common"]>(
    (state) => state.common
  );

  const onCancel = useCallback(() => {
    dispatch(setModalName(undefined));
  }, [dispatch]);

  const onImport = useCallback(() => {
    if (file) {
      dispatch(uploadCsvVehicles(file, token));
    }
    onCancel();
  }, [dispatch, file, token, onCancel]);

  const draggerProps: DraggerProps = useMemo(
    () => ({
      name: "file",
      multiple: false,
      accept: "application/msword,.csv",
      beforeUpload: async (newFile) => {
        setFile(newFile);
      },
    }),
    []
  );

  return (
    <>
      <Title level={5}>Import File</Title>
      <Dragger {...draggerProps} className="import-csv">
        {uploadIcon}
        <p className="ant-upload text mr-2">
          Drag and drop a CSV file here or
          <span className="secondary-color"> browse</span> from your system
        </p>
      </Dragger>
      <div className="action-btn-container --vehicle">
        <Button className="cancel-btn" onClick={onCancel}>
          Cancel
        </Button>
        <Button type="primary" onClick={onImport} disabled={!file}>
          Import
        </Button>
      </div>
    </>
  );
}
