import {Dispatch} from "react";
import { FcmNotification, FcmNotificationActions, GetState} from "../../types";
import {apiResponse} from "./common-actions";
import {
    batteryManagmentServiceClient,
    fcmNotificationServiceClient,
    fcmUserServiceClient
} from "../../utils/grpc-clients";
import {setBatteries} from "./battery-actions";
import {
    FcmNotificationItem,
    GetUnreadNotificationRequest,
    GetUnreadNotificationResponse, MarkReadNotificationsByIdsRequest, MarkReadNotificationsByIdsResponse
} from "../../protobuf/fcm_notification_service_pb";
import {FcmNotificationServiceClient} from "../../protobuf/Fcm_notification_serviceServiceClientPb";
import {
    AddFcmUserTokenRequest,
    AddFcmUserTokenResponse,
    FcmUserTokenItem, UpdateFcmUserTokenItem
} from "../../protobuf/fcm_user_token_service_pb";
import {FcmUserServiceClient} from "../../protobuf/Fcm_user_token_serviceServiceClientPb";
import {initializeApp} from "firebase/app";
import {getMessaging, getToken, onMessage} from "firebase/messaging";
import {useDispatch} from "react-redux";

export function initializeNotification(
){
    const request = new GetUnreadNotificationRequest()
    return async (dispatch: Dispatch<any>) => {
        function dispatcher(resp: GetUnreadNotificationResponse) {
            const notifications: FcmNotification[] = resp?.getNotificationItemList()?.map(notification => ({
                ...(notification.toObject() as FcmNotificationItem.AsObject),
                name: "",
            }));

            dispatch(setNotifications(notifications))
        }

        dispatch(
            apiResponse<
                FcmNotificationServiceClient,
                GetUnreadNotificationRequest,
                GetUnreadNotificationResponse
                >({
                client: fcmNotificationServiceClient,
                action: "getUnreadNotification",
                request,
                dispatcher,
            })
        );
    };
}


export function addUserFcmToken(token:string
){
    const request = new AddFcmUserTokenRequest()
    const fcmUser = new UpdateFcmUserTokenItem()
    fcmUser.setFcmToken(token)
    request.setFcmuser(fcmUser)
    return async (dispatch: Dispatch<any>) => {
        function dispatcher(resp: AddFcmUserTokenResponse) {
            console.log("AddFcmUserTokenResponse : ", resp.getFcmuser())
        }
        dispatch(
            apiResponse<
                FcmUserServiceClient,
                AddFcmUserTokenRequest,
                AddFcmUserTokenResponse
                >({
                client: fcmUserServiceClient,
                action: "addFcmUserToken",
                request,
                dispatcher,
            })
        );
    };
}

export function markReadNotifications(ids:string[]
){
    const request = new MarkReadNotificationsByIdsRequest()
    request.setIdList(ids)
    console.log("markReadNotifications IDS: ",ids);
    return async (dispatch: Dispatch<any>) => {
        function dispatcher(resp: MarkReadNotificationsByIdsResponse) {
            
            console.log("MarkReadNotificationsByIdsResponse : ", resp)
        }
        dispatch(
            apiResponse<
                FcmNotificationServiceClient,
                MarkReadNotificationsByIdsRequest,
                MarkReadNotificationsByIdsResponse
                >({
                client: fcmNotificationServiceClient,
                action: "markReadNotificationsByIds",
                request,
                dispatcher,
            })
        );
    };
}


export function initializeFirebaseConfiguration(dispatch: Dispatch<any>) {

    var firebaseConfig = {
        apiKey: "AIzaSyB7gyH0WeX0-S-9EgsmEov-wz_-h9hQI-U",
        authDomain: "flux-rider.firebaseapp.com",
        projectId: "flux-rider",
        storageBucket: "flux-rider.appspot.com",
        messagingSenderId: "341327255513",
        appId: "1:341327255513:web:e7dbf71ef920819ccdebc8",
        measurementId: "G-5HKX4K6N2V"
    };

    const app = initializeApp(firebaseConfig);
    const messaging = getMessaging(app);

    function getFToken() {
        return  getToken(messaging, { vapidKey: 'BHzWdDz3gqrwWRGPI1ey9jMlnFWm5pxDyehebFy4yW24VMYzhnTpi0cnCExRBhDApBo-AJ4wIMOhbW1M15uhoGE' }).then((currentToken) => {
            if (currentToken) {
                // Send the token to your server and update the UI if necessary
                console.log("currentToken : ", currentToken)

                return currentToken;
                // ...
            } else {
                // Show permission request UI
                console.log('No registration token available. Request permission to generate one.');
                // ...
            }
        }).catch((err) => {
            console.log('An error occurred while retrieving token. ', err);
            // ...
        });
    }

    onMessage(messaging, function(payload:any){
        console.log("payload: ", payload)
        // const notificationTitle = 'Background Message Title';
        // const notificationOptions = {
        //     body: payload.notification,
        //     icon: '/firebase-logo.png'
        // };
        dispatch(initializeNotification())
    });


    // Request permission for notifications
    const requestNotificationPermission = async () => {
        try {
            const token = await getFToken();

            const permission = await window.Notification?.requestPermission();
            if (permission === 'granted') {
                console.log('Device token:', token);
                dispatch(addUserFcmToken(token || ""))
                console.log('Notification permission granted.');
                // Save the token to your server/database if needed
            } else {
                console.log('Notification permission denied.');

            }
        } catch (error) {
            console.log('Error occurred while requesting permission or retrieving token:', error);
        }
    };
    requestNotificationPermission()
}

function setFcmToken(token:string) {
    return (dispatch: Dispatch<any>, getState: GetState) => {
        console.log("Token in set : ", token)
        dispatch(addUserFcmToken(token))
    }
}

export function setNotifications(
    payload: FcmNotification[]
): FcmNotificationActions {
    return {
        type: "SET_FCM_NOTIFICATIONS",
        payload,
    };
}