import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Formik } from "formik";
import { Form, Button, Typography, Radio, Input } from "antd";

import { backCircularIcon, uploadIcon } from "../svgs";
import { SHAKEHAND_FORMIK } from "utils/formik-data";
import { Link } from "react-router-dom";
import { ROUTES } from "utils/constants";
import Dragger from "antd/lib/upload/Dragger";
import PhoneInput from "react-phone-input-2";
import { FormItem, SelectSearch } from "components";
import { fileToByteArray } from "utils/helpers";
import { sendEnquiry, setModels } from "store/actions";
import { AppState } from "store";
import { parseModels } from "utils/parsers";

const { Title } = Typography;

interface ComponentProps {
  onFinish: VoidFunction;
}

const optionsWithDisabled = [
  { label: "2 months", value: 1 },
  { label: "6 months", value: 2 },
  { label: "1 year", value: 3 },
  { label: "More", value: 4 },
];

export default function ShakeHandsForm({ onFinish }: ComponentProps) {
  const dispatch = useDispatch();
  const { models } = useSelector<AppState, AppState["vehicles"]>(
    (state) => state.vehicles
  );
  const modelsOptions = useMemo(() => parseModels(models), [models]);

  useEffect(() => {
    dispatch(setModels());
  }, [dispatch]);

  return (
    <main className="main-container">
      <Link to={ROUTES.welcome}>{backCircularIcon}</Link>
      <Formik
        initialValues={SHAKEHAND_FORMIK.initialValues}
        validationSchema={SHAKEHAND_FORMIK.validationSchema}
        onSubmit={(values) => {
          dispatch(
            sendEnquiry({
              email: values.email,
              model: "70c47782-80b2-4838-98ee-9ea8880c02dd",
              document: values.document_file || "",
              phone: {
                countryPrefix: "+" + values.dial_code,
                number: values.phone_no.replace(values.dial_code, ""),
              },
              type: values.lease_month as 0 | 1 | 2 | 3,
              dispatcher: () => {
                onFinish();
              },
            })
          );
        }}
      >
        {({
          values,
          setFieldValue,
          handleSubmit,
          errors,
          handleChange,
          isValid,
          dirty,
        }) => (
          <div className="shake-hand-form">
            <Title level={5} className="shake-hand-title">
              Shake Hands
            </Title>

            <Form className="--issues-modal-form" onFinish={handleSubmit}>
              <div className="shake-hand-switch">
                <p className="lease-label">
                  How long would you like to lease vehicles for?
                </p>
                <FormItem error={errors.lease_month}>
                  <Radio.Group
                    options={optionsWithDisabled}
                    onChange={handleChange}
                    value={values.lease_month}
                    optionType="button"
                    buttonStyle="solid"
                    name="lease_month"
                  />
                </FormItem>
              </div>
              <div className="shake-hand-bikes">
                <p className="lease-label">Choose the model:</p>
                <FormItem error={errors.model}>
                  <SelectSearch
                    value={values.model}
                    items={modelsOptions}
                    setFieldValue={setFieldValue}
                    fieldName="model"
                    placeholder="Select"
                  />
                </FormItem>
              </div>
              <div className="shake-hand-upload">
                <p className="lease-label">Upload document(s)</p>
                <FormItem error={errors.document_file}>
                  <Dragger
                    className="upload-document"
                    multiple={false}
                    beforeUpload={async (file) => {
                      const unit8Arr = await fileToByteArray(file);
                      setFieldValue("document_file", unit8Arr);
                    }}
                  >
                    <span className="upload-icon">{uploadIcon}</span>
                    <p className="ant-upload text">
                      Drag and drop or{" "}
                      <span className="secondary-color"> browse</span> the
                      system for the file
                    </p>
                  </Dragger>
                </FormItem>
              </div>
              <div className="shake-hand-phone ">
                <p className="lease-label">
                  Provide phone number so we can get back to you
                </p>
                <FormItem error={errors.phone_no}>
                  <PhoneInput
                    country="ae"
                    inputClass="login-phone-input"
                    buttonClass=""
                    placeholder="000000"
                    defaultErrorMessage="Phone number not regestired. Please enter a verified phone number"
                    value={values.phone_no}
                    onChange={(val, data: { dialCode: string }) => {
                      setFieldValue("dial_code", data.dialCode);
                      setFieldValue("phone_no", val);
                    }}
                  />
                </FormItem>
              </div>
              <div className="shake-hand-email ">
                <p className="lease-label">Provide your email address</p>
                <FormItem error={errors.email}>
                  <Input
                    value={values.email}
                    className="email-input"
                    name="email"
                    onChange={handleChange}
                    placeholder="username@gmail.com"
                    type="email"
                  />
                </FormItem>
              </div>

              <div className="action-btn-container">
                <Button
                  className="cancel-btn"
                  onClick={() => console.log("cancelled")}
                >
                  <Link to={ROUTES.welcome}> Cancel</Link>
                </Button>
                <Button
                  type="primary"
                  htmlType="submit"
                  disabled={!(isValid && dirty)}
                >
                  Send
                </Button>
              </div>
            </Form>
          </div>
        )}
      </Formik>
    </main>
  );
}
