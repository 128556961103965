import { useCallback, useMemo, useState , useEffect } from "react";
import { useHistory } from "react-router-dom";
import {TabNavs} from "../../../index";
import Detail from "./detail";
import Gallery from "./gallery";
import Table from "./table";
import { useDispatch } from "react-redux";
import { getVehicleLocationHistory, setModalName, setSelectedVehicle } from "store/actions";
import Stats from "./stats";
import { useSelector } from "react-redux";
import { AppState } from "store";

export type ComponentType = "gallery" | "stats";

export default function VehicleDetail() {
  const {
    vehicles: { selectedVehicle },
  } = useSelector<AppState, AppState>((state) => state);
  const [component, setComponent] = useState<ComponentType>("gallery");
  const history = useHistory();
  const dispatch = useDispatch();

  const onClickBack = useCallback(() => {
    history.goBack();
  }, [history]);
//   const onVehicleLocationHistory = useCallback( () => {
//     dispatch(getVehicleLocationHistory({id: selectedVehicle?.id || "", pageNo: 1}))
// }, [dispatch]);

  const onAddIssue = useCallback(() => {
    dispatch(setModalName("add_issue_from_vehicle"));
  }, []);
  const onEditVehicle = useCallback(() => {
    dispatch(setModalName("edit_vehicle"));
  }, [dispatch]);
  const onArchiveVehicle = useCallback(() => {
    dispatch(setModalName("delete_vehicle"));
  }, [dispatch]);
  const onChangeComponent = useCallback((component: ComponentType) => {
    setComponent(component);
  }, []);

  const dynamicComponent = useMemo(() => {
    switch (component) {
      case "gallery":
        return (
          <Gallery
            onEditVehicle={onEditVehicle}
            onArchiveVehicle={onArchiveVehicle}
            onChangeComponent={onChangeComponent}
            onClickBack={onClickBack}
          />
        );
      default:
        return <Stats onChangeComponent={onChangeComponent}/>;
    }
  }, [component]);
  // const [selectedView, setSelectedView] = useState("Location History");
  return (
    <main className="vehicle-detail-content">
      {dynamicComponent}
      <Detail />
      {/* <Table onAddIssue={onAddIssue} /> */}

      {/* <TabNavs
        selected={selectedView}
        onChange={(value) => setSelectedView(value)}
        options={[{ key: "Location History"}, { key: "Battery Insert Data" }]}
      /> */}
      <Table  />
    </main>
  );
}
