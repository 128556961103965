import { useEffect, useState } from "react";
import styled from "styled-components";
import { BarChart } from "@k2/rv-viz";

const data = [
  {
    data: [
      {
        color: "rgba(114, 48, 200, 0.2)",
        labelColor: "#777777",
        x: 10,
        y: "Refueling",
      },
      {
        color: "rgba(69, 128, 217, 0.2)",
        labelColor: "#777777",
        x: 10,
        y: "Maintenance",
      },
      {
        color: "rgba(245, 116, 0, 0.2)",
        labelColor: "#777777",
        x: 60,
        y: "Delivery",
      },
    ],
    name: "Series 1",
  },
];

const BarChartStyled = styled(BarChart)<{
  bar1Offset: number;
  bar2Offset: number;
  bar3Offset: number;
  borderHeight: number;
}>`
  &.custom-bar-chart {
    .k2--horizontal-bar-series {
      > rect {
        stroke-width: 4px;
        stroke-dasharray: calc(${(props) => props.borderHeight} - 1) 3000;

        :nth-last-of-type(1) {
          stroke-dashoffset: calc(-${(props) => props.bar1Offset} - 1);
          stroke: rgba(245, 116, 0) !important;
        }
        :nth-last-of-type(2) {
          stroke-dashoffset: calc(-${(props) => props.bar2Offset} - 1);
          stroke: rgba(69, 128, 217) !important;
        }
        :nth-last-of-type(3) {
          stroke-dashoffset: calc(-${(props) => props.bar3Offset} - 1);
          stroke: rgba(114, 48, 200) !important;
        }
      }
    }
  }
`;

export default function RenderChart() {
  const [state, setState] = useState({
    offset1: 0,
    offset2: 0,
    offset3: 0,
    borderHeight: 49,
  });

  useEffect(() => {
    function reportWindowSize() {
      setTimeout(() => {
        const barRects = document.querySelectorAll(
          ".custom-bar-chart .k2--horizontal-bar-series > rect"
        );
        const bar3 = barRects[0]?.getBoundingClientRect().width;
        const borderHeight = barRects[0]?.getBoundingClientRect().height;
        const bar2 = barRects[1]?.getBoundingClientRect().width;
        const bar1 = barRects[2]?.getBoundingClientRect().width;
        setState({
          offset1: bar1,
          offset2: bar2,
          offset3: bar3,
          borderHeight: borderHeight,
        });
      }, 500);
    }
    reportWindowSize();
    window.addEventListener("resize", reportWindowSize);

    return () => {
      window.removeEventListener("resize", reportWindowSize);
    };
  }, []);

  return (
    <BarChartStyled
      borderHeight={state.borderHeight}
      bar1Offset={state.offset1}
      bar2Offset={state.offset2}
      bar3Offset={state.offset3}
      barWidth={0.95}
      data={data}
      title={"Tasks Completed"}
      horizontalGridLines={false}
      legends={false}
      xAxis={{
        hideLine: true,
        tickSize: 0,
      }}
      yAxis={{
        hideLine: true,
        tickSize: 0,
      }}
      xyPlot={{
        margin: {
          left: 80,
          top: 0,
          bottom: 30,
          right: 5,
        },
      }}
      className="custom-bar-chart"
    />
  );
}
