import {ClientsActions, ClientsState, OrganizationClient} from "types/store";
const originalFilters = {
  Assignee: [],
};
const updatedFilters = {
  Assignee: [],
};

const initialState: ClientsState = {
  selectedOrganizationsRows: [],
  showbulkActionsClients: false,
  page: {
    selectedPage: 1,
    totalElements: 0,
    totalPages: 0,
  },
  filters: {
    original: originalFilters,
    updated: updatedFilters,
    listType: "current",
  },
  search: "",
};

export default function clientReducer(
  state: ClientsState = initialState,
  action: ClientsActions
): ClientsState {
  switch (action.type) {
    case "SET_CLIENT_BULK_ACTIONS":
      return { ...state, showbulkActionsClients: action.payload };
    case "SET_CLIENTS":
      return {...state, organizations: action.payload};
    case "SET_SELECTED_ORGANIZATION":
      return {...state, selectedOrganization: action.payload};
    case "SET_SELECTED_ORGANIZATION_ROWS":
      return {...state, selectedOrganizationsRows: action.payload}
    default:
      return state;
  }
}
