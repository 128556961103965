import { Formik } from "formik";
import { Form, Button, Typography } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useCallback, useState } from "react";
import { getCroppedImg } from "utils/canvas";

import { setModalName, uploadProfilePic } from "store/actions";
import Cropper from "react-easy-crop";
import { AppState } from "store";
import { fileToByteArray } from "utils/helpers";

const { Title } = Typography;

const ImgDialog = (props: any) => {
  return <img src={props.img} alt="Cropped" className="cropped-image" />;
};

export default function UploadPic() {
  const [imageSrc, setImageSrc] = useState("");
  const [imageMeta, setImageMeta] = useState({ name: "", type: "" });
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const [zoom, setZoom] = useState(1);

  const dispatch = useDispatch();
  const { selectedUser: { id: userId = "" } = {} } = useSelector<
    AppState,
    AppState["users"]
  >((state) => state.users);

  const onCancel = useCallback(() => {
    dispatch(setModalName(undefined));
  }, [dispatch]);

  const onCropCancel = useCallback(() => {
    setImageSrc("");
    setCroppedImage(null);
  }, []);

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  // TODO:remove any in future
  const onFileChange = async (e: any) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      let imageDataUrl: any = await readFile(file);

      setImageSrc(imageDataUrl);
      setImageMeta({ name: file.name, type: file.type });
    }
  };

  const showCroppedImage = useCallback(async () => {
    try {
      const croppedImage: any = (await getCroppedImg(
        imageSrc,
        croppedAreaPixels
      )) as Blob;
      const unit8Arr = await fileToByteArray(croppedImage);

      setCroppedImage(croppedImage);

      dispatch(
        uploadProfilePic(userId, unit8Arr, imageMeta.type, imageMeta.name)
      );
      onCancel();
    } catch (e) {
      console.error(e);
    }
  }, [dispatch, imageSrc, imageMeta, croppedAreaPixels, userId, onCancel]);

  return (
    <Formik initialValues={{}} onSubmit={(values) => console.trace(values)}>
      {() => (
        <div className="form-container">
          <Title level={5}>Upload Photo</Title>

          <Form className="--upload-pic-form">
            <Form.Item name="dragger" valuePropName="fileList" noStyle>
              {imageSrc ? (
                <>
                  <section className="cropped-image-container">
                    {!croppedImage ? (
                      <Cropper
                        image={imageSrc}
                        crop={crop}
                        cropShape="round"
                        showGrid={false}
                        cropSize={{ height: 247, width: 247 }}
                        aspect={4 / 3}
                        zoom={zoom}
                        onZoomChange={setZoom}
                        onCropChange={setCrop}
                        onCropComplete={onCropComplete}
                        classes={{
                          containerClassName: "croppercontainer",
                          cropAreaClassName: "cropArea",
                          mediaClassName: "image",
                        }}
                      />
                    ) : (
                      <ImgDialog img={croppedImage} />
                    )}
                  </section>

                  <div className="action-btn-container">
                    <Button className="cancel-btn" onClick={onCropCancel}>
                      Cancel
                    </Button>
                    <Button type="primary" onClick={showCroppedImage}>
                      Save
                    </Button>
                  </div>
                </>
              ) : (
                <>
                  <input type="file" onChange={onFileChange} accept="image/*" />
                  <div className="action-btn-container">
                    <Button className="cancel-btn" onClick={onCancel}>
                      Cancel
                    </Button>
                    <Button type="primary" onClick={showCroppedImage}>
                      Save
                    </Button>
                  </div>
                </>
              )}
            </Form.Item>
          </Form>
        </div>
      )}
    </Formik>
  );

  function readFile(file: any) {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.addEventListener("load", () => resolve(reader.result), false);
      reader.readAsDataURL(file);
    });
  }
}
