import { Typography, Button } from "antd";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "store";
import { deleteVehicle, setModalName } from "store/actions";

const { Text, Title } = Typography;

export default function DeleteVehicleForm() {
  const dispatch = useDispatch();
  const { selectedVehicle } = useSelector<AppState, AppState["vehicles"]>(
    (state) => state.vehicles
  );

  const onDelete = useCallback(() => {
    dispatch(deleteVehicle(selectedVehicle?.id || ""));
    onCancel();
  }, [dispatch, selectedVehicle?.id]);

  const onCancel = useCallback(() => {
    dispatch(setModalName(undefined));
  }, [dispatch]);

  return (
    <section className="delete-modal">
      <Title level={5}>Archive Vehicle</Title>
      <Text type="secondary">
        Are you sure you want to archive the vehicle{" "}
        <strong> “{selectedVehicle?.licensePlate}”</strong> ?
      </Text>
      <div className="action-btn-container">
        <Button className="cancel-btn" onClick={onCancel}>
          Cancel
        </Button>
        <Button type="primary" danger onClick={onDelete}>
          Archive
        </Button>
      </div>
    </section>
  );
}
