import {ClientsState, VehiclesState} from "types/store";

export function parseClients(list?: VehiclesState["clients"]) {
  if (list) {
    return list.map((client) => ({
      label: client.name,
      value: client.id,
    }));
  }

  return [];
}

export function parseOrganizationClients(list?: ClientsState["organizations"]) {
  if (list) {
    return list.map((client) => ({
      name: client.name,
      //organization_name: client.ownerOrgName,
      address: client.address?.streetAddress1,
      city: client.address?.city,
      state: client.address?.state,
      country: client.address?.country,
      raw: client,
    }));
  }

  return [];
}

export function parseDropdownOrganization(list?: { name: string; id: string }[]) {
  if (list) {
    return list.map((client) => ({
      label: client.name,
      value: client.id,
    }));
  }

  return [];
}