/**
 * @fileoverview gRPC-Web generated client stub for api
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v4.24.2
// source: billing_service.proto


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as billing_service_pb from './billing_service_pb';


export class BillingServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname.replace(/\/+$/, '');
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorCreatePackage = new grpcWeb.MethodDescriptor(
    '/api.BillingService/CreatePackage',
    grpcWeb.MethodType.UNARY,
    billing_service_pb.CreatePackageRequest,
    billing_service_pb.CreatePackageResponse,
    (request: billing_service_pb.CreatePackageRequest) => {
      return request.serializeBinary();
    },
    billing_service_pb.CreatePackageResponse.deserializeBinary
  );

  createPackage(
    request: billing_service_pb.CreatePackageRequest,
    metadata: grpcWeb.Metadata | null): Promise<billing_service_pb.CreatePackageResponse>;

  createPackage(
    request: billing_service_pb.CreatePackageRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: billing_service_pb.CreatePackageResponse) => void): grpcWeb.ClientReadableStream<billing_service_pb.CreatePackageResponse>;

  createPackage(
    request: billing_service_pb.CreatePackageRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: billing_service_pb.CreatePackageResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/api.BillingService/CreatePackage',
        request,
        metadata || {},
        this.methodDescriptorCreatePackage,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/api.BillingService/CreatePackage',
    request,
    metadata || {},
    this.methodDescriptorCreatePackage);
  }

  methodDescriptorCreatePlan = new grpcWeb.MethodDescriptor(
    '/api.BillingService/CreatePlan',
    grpcWeb.MethodType.UNARY,
    billing_service_pb.CreatePlanRequest,
    billing_service_pb.CreatePlanResponse,
    (request: billing_service_pb.CreatePlanRequest) => {
      return request.serializeBinary();
    },
    billing_service_pb.CreatePlanResponse.deserializeBinary
  );

  createPlan(
    request: billing_service_pb.CreatePlanRequest,
    metadata: grpcWeb.Metadata | null): Promise<billing_service_pb.CreatePlanResponse>;

  createPlan(
    request: billing_service_pb.CreatePlanRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: billing_service_pb.CreatePlanResponse) => void): grpcWeb.ClientReadableStream<billing_service_pb.CreatePlanResponse>;

  createPlan(
    request: billing_service_pb.CreatePlanRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: billing_service_pb.CreatePlanResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/api.BillingService/CreatePlan',
        request,
        metadata || {},
        this.methodDescriptorCreatePlan,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/api.BillingService/CreatePlan',
    request,
    metadata || {},
    this.methodDescriptorCreatePlan);
  }

  methodDescriptorCreateSubscription = new grpcWeb.MethodDescriptor(
    '/api.BillingService/CreateSubscription',
    grpcWeb.MethodType.UNARY,
    billing_service_pb.CreateSubscriptionRequest,
    billing_service_pb.CreateSubscriptionResponse,
    (request: billing_service_pb.CreateSubscriptionRequest) => {
      return request.serializeBinary();
    },
    billing_service_pb.CreateSubscriptionResponse.deserializeBinary
  );

  createSubscription(
    request: billing_service_pb.CreateSubscriptionRequest,
    metadata: grpcWeb.Metadata | null): Promise<billing_service_pb.CreateSubscriptionResponse>;

  createSubscription(
    request: billing_service_pb.CreateSubscriptionRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: billing_service_pb.CreateSubscriptionResponse) => void): grpcWeb.ClientReadableStream<billing_service_pb.CreateSubscriptionResponse>;

  createSubscription(
    request: billing_service_pb.CreateSubscriptionRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: billing_service_pb.CreateSubscriptionResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/api.BillingService/CreateSubscription',
        request,
        metadata || {},
        this.methodDescriptorCreateSubscription,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/api.BillingService/CreateSubscription',
    request,
    metadata || {},
    this.methodDescriptorCreateSubscription);
  }

  methodDescriptorPostBill = new grpcWeb.MethodDescriptor(
    '/api.BillingService/PostBill',
    grpcWeb.MethodType.UNARY,
    billing_service_pb.PostBillRequest,
    billing_service_pb.PostBillResponse,
    (request: billing_service_pb.PostBillRequest) => {
      return request.serializeBinary();
    },
    billing_service_pb.PostBillResponse.deserializeBinary
  );

  postBill(
    request: billing_service_pb.PostBillRequest,
    metadata: grpcWeb.Metadata | null): Promise<billing_service_pb.PostBillResponse>;

  postBill(
    request: billing_service_pb.PostBillRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: billing_service_pb.PostBillResponse) => void): grpcWeb.ClientReadableStream<billing_service_pb.PostBillResponse>;

  postBill(
    request: billing_service_pb.PostBillRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: billing_service_pb.PostBillResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/api.BillingService/PostBill',
        request,
        metadata || {},
        this.methodDescriptorPostBill,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/api.BillingService/PostBill',
    request,
    metadata || {},
    this.methodDescriptorPostBill);
  }

  methodDescriptorGetPlansList = new grpcWeb.MethodDescriptor(
    '/api.BillingService/GetPlansList',
    grpcWeb.MethodType.UNARY,
    billing_service_pb.GetPlansListRequest,
    billing_service_pb.GetPlansListResponse,
    (request: billing_service_pb.GetPlansListRequest) => {
      return request.serializeBinary();
    },
    billing_service_pb.GetPlansListResponse.deserializeBinary
  );

  getPlansList(
    request: billing_service_pb.GetPlansListRequest,
    metadata: grpcWeb.Metadata | null): Promise<billing_service_pb.GetPlansListResponse>;

  getPlansList(
    request: billing_service_pb.GetPlansListRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: billing_service_pb.GetPlansListResponse) => void): grpcWeb.ClientReadableStream<billing_service_pb.GetPlansListResponse>;

  getPlansList(
    request: billing_service_pb.GetPlansListRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: billing_service_pb.GetPlansListResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/api.BillingService/GetPlansList',
        request,
        metadata || {},
        this.methodDescriptorGetPlansList,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/api.BillingService/GetPlansList',
    request,
    metadata || {},
    this.methodDescriptorGetPlansList);
  }

  methodDescriptorGetSubscriptionList = new grpcWeb.MethodDescriptor(
    '/api.BillingService/GetSubscriptionList',
    grpcWeb.MethodType.UNARY,
    billing_service_pb.GetSubscriptionListRequest,
    billing_service_pb.GetSubscriptionListResponse,
    (request: billing_service_pb.GetSubscriptionListRequest) => {
      return request.serializeBinary();
    },
    billing_service_pb.GetSubscriptionListResponse.deserializeBinary
  );

  getSubscriptionList(
    request: billing_service_pb.GetSubscriptionListRequest,
    metadata: grpcWeb.Metadata | null): Promise<billing_service_pb.GetSubscriptionListResponse>;

  getSubscriptionList(
    request: billing_service_pb.GetSubscriptionListRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: billing_service_pb.GetSubscriptionListResponse) => void): grpcWeb.ClientReadableStream<billing_service_pb.GetSubscriptionListResponse>;

  getSubscriptionList(
    request: billing_service_pb.GetSubscriptionListRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: billing_service_pb.GetSubscriptionListResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/api.BillingService/GetSubscriptionList',
        request,
        metadata || {},
        this.methodDescriptorGetSubscriptionList,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/api.BillingService/GetSubscriptionList',
    request,
    metadata || {},
    this.methodDescriptorGetSubscriptionList);
  }

  methodDescriptorGetBills = new grpcWeb.MethodDescriptor(
    '/api.BillingService/GetBills',
    grpcWeb.MethodType.UNARY,
    billing_service_pb.GetBillsRequest,
    billing_service_pb.GetBillsResponse,
    (request: billing_service_pb.GetBillsRequest) => {
      return request.serializeBinary();
    },
    billing_service_pb.GetBillsResponse.deserializeBinary
  );

  getBills(
    request: billing_service_pb.GetBillsRequest,
    metadata: grpcWeb.Metadata | null): Promise<billing_service_pb.GetBillsResponse>;

  getBills(
    request: billing_service_pb.GetBillsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: billing_service_pb.GetBillsResponse) => void): grpcWeb.ClientReadableStream<billing_service_pb.GetBillsResponse>;

  getBills(
    request: billing_service_pb.GetBillsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: billing_service_pb.GetBillsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/api.BillingService/GetBills',
        request,
        metadata || {},
        this.methodDescriptorGetBills,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/api.BillingService/GetBills',
    request,
    metadata || {},
    this.methodDescriptorGetBills);
  }

  methodDescriptorUpdateSubscription = new grpcWeb.MethodDescriptor(
    '/api.BillingService/UpdateSubscription',
    grpcWeb.MethodType.UNARY,
    billing_service_pb.UpdateSubscriptionRequest,
    billing_service_pb.UpdateSubscriptionResponse,
    (request: billing_service_pb.UpdateSubscriptionRequest) => {
      return request.serializeBinary();
    },
    billing_service_pb.UpdateSubscriptionResponse.deserializeBinary
  );

  updateSubscription(
    request: billing_service_pb.UpdateSubscriptionRequest,
    metadata: grpcWeb.Metadata | null): Promise<billing_service_pb.UpdateSubscriptionResponse>;

  updateSubscription(
    request: billing_service_pb.UpdateSubscriptionRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: billing_service_pb.UpdateSubscriptionResponse) => void): grpcWeb.ClientReadableStream<billing_service_pb.UpdateSubscriptionResponse>;

  updateSubscription(
    request: billing_service_pb.UpdateSubscriptionRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: billing_service_pb.UpdateSubscriptionResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/api.BillingService/UpdateSubscription',
        request,
        metadata || {},
        this.methodDescriptorUpdateSubscription,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/api.BillingService/UpdateSubscription',
    request,
    metadata || {},
    this.methodDescriptorUpdateSubscription);
  }

  methodDescriptorGetLatestBillByCustomer = new grpcWeb.MethodDescriptor(
    '/api.BillingService/GetLatestBillByCustomer',
    grpcWeb.MethodType.UNARY,
    billing_service_pb.GetLatestBillByCustomerRequest,
    billing_service_pb.GetLatestBillByCustomerResponse,
    (request: billing_service_pb.GetLatestBillByCustomerRequest) => {
      return request.serializeBinary();
    },
    billing_service_pb.GetLatestBillByCustomerResponse.deserializeBinary
  );

  getLatestBillByCustomer(
    request: billing_service_pb.GetLatestBillByCustomerRequest,
    metadata: grpcWeb.Metadata | null): Promise<billing_service_pb.GetLatestBillByCustomerResponse>;

  getLatestBillByCustomer(
    request: billing_service_pb.GetLatestBillByCustomerRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: billing_service_pb.GetLatestBillByCustomerResponse) => void): grpcWeb.ClientReadableStream<billing_service_pb.GetLatestBillByCustomerResponse>;

  getLatestBillByCustomer(
    request: billing_service_pb.GetLatestBillByCustomerRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: billing_service_pb.GetLatestBillByCustomerResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/api.BillingService/GetLatestBillByCustomer',
        request,
        metadata || {},
        this.methodDescriptorGetLatestBillByCustomer,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/api.BillingService/GetLatestBillByCustomer',
    request,
    metadata || {},
    this.methodDescriptorGetLatestBillByCustomer);
  }

  methodDescriptorGetPackage = new grpcWeb.MethodDescriptor(
    '/api.BillingService/GetPackage',
    grpcWeb.MethodType.UNARY,
    billing_service_pb.GetPackageRequest,
    billing_service_pb.GetPackageResponse,
    (request: billing_service_pb.GetPackageRequest) => {
      return request.serializeBinary();
    },
    billing_service_pb.GetPackageResponse.deserializeBinary
  );

  getPackage(
    request: billing_service_pb.GetPackageRequest,
    metadata: grpcWeb.Metadata | null): Promise<billing_service_pb.GetPackageResponse>;

  getPackage(
    request: billing_service_pb.GetPackageRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: billing_service_pb.GetPackageResponse) => void): grpcWeb.ClientReadableStream<billing_service_pb.GetPackageResponse>;

  getPackage(
    request: billing_service_pb.GetPackageRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: billing_service_pb.GetPackageResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/api.BillingService/GetPackage',
        request,
        metadata || {},
        this.methodDescriptorGetPackage,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/api.BillingService/GetPackage',
    request,
    metadata || {},
    this.methodDescriptorGetPackage);
  }

  methodDescriptorGetPlan = new grpcWeb.MethodDescriptor(
    '/api.BillingService/GetPlan',
    grpcWeb.MethodType.UNARY,
    billing_service_pb.GetPlanRequest,
    billing_service_pb.GetPlanResponse,
    (request: billing_service_pb.GetPlanRequest) => {
      return request.serializeBinary();
    },
    billing_service_pb.GetPlanResponse.deserializeBinary
  );

  getPlan(
    request: billing_service_pb.GetPlanRequest,
    metadata: grpcWeb.Metadata | null): Promise<billing_service_pb.GetPlanResponse>;

  getPlan(
    request: billing_service_pb.GetPlanRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: billing_service_pb.GetPlanResponse) => void): grpcWeb.ClientReadableStream<billing_service_pb.GetPlanResponse>;

  getPlan(
    request: billing_service_pb.GetPlanRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: billing_service_pb.GetPlanResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/api.BillingService/GetPlan',
        request,
        metadata || {},
        this.methodDescriptorGetPlan,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/api.BillingService/GetPlan',
    request,
    metadata || {},
    this.methodDescriptorGetPlan);
  }

  methodDescriptorGetSubscription = new grpcWeb.MethodDescriptor(
    '/api.BillingService/GetSubscription',
    grpcWeb.MethodType.UNARY,
    billing_service_pb.GetSubscriptionRequest,
    billing_service_pb.GetSubscriptionResponse,
    (request: billing_service_pb.GetSubscriptionRequest) => {
      return request.serializeBinary();
    },
    billing_service_pb.GetSubscriptionResponse.deserializeBinary
  );

  getSubscription(
    request: billing_service_pb.GetSubscriptionRequest,
    metadata: grpcWeb.Metadata | null): Promise<billing_service_pb.GetSubscriptionResponse>;

  getSubscription(
    request: billing_service_pb.GetSubscriptionRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: billing_service_pb.GetSubscriptionResponse) => void): grpcWeb.ClientReadableStream<billing_service_pb.GetSubscriptionResponse>;

  getSubscription(
    request: billing_service_pb.GetSubscriptionRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: billing_service_pb.GetSubscriptionResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/api.BillingService/GetSubscription',
        request,
        metadata || {},
        this.methodDescriptorGetSubscription,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/api.BillingService/GetSubscription',
    request,
    metadata || {},
    this.methodDescriptorGetSubscription);
  }

}

