import {useDispatch, useSelector} from "react-redux";
import { AppState } from "store";
import {useCallback, useEffect, useMemo} from "react";
import {parseClients} from "../utils/parsers";
import {getClients, getDropdownUsers, setModalName, setModels} from "../store/actions";
import {Formik} from "formik";
import {VEHICLE_FORMIK, VEHICLE_ORGANIZATION_ASSIGNMENT_FORMIK} from "../utils/formik-data";
import {Button, Form, Input, Typography} from "antd";
import {FormItem, SelectSearch} from "../components";
import {VehicleOrganizationAssignmentValues} from "../types/formik";
const { Title, Text } = Typography;

export default function AssignmentVehicleOrganizationForm(){
    const dispatch = useDispatch();
    const {
        vehicles: { models, clients },
        users: { dropdownUsers },
    } = useSelector<AppState, AppState>((state) => state);
    const clientOptions = useMemo(() => parseClients(clients), [clients]);

    useEffect(() => {
        dispatch(getClients());
    }, [dispatch]);

    const onSubmit = useCallback(
        (values: VehicleOrganizationAssignmentValues) => {
            console.log(values)
        },[dispatch]
    )

    const onCancel = useCallback(() => {
        dispatch(setModalName(undefined));
    }, [dispatch]);

    return (
        <Formik
            initialValues={VEHICLE_ORGANIZATION_ASSIGNMENT_FORMIK.initialValues}
            validationSchema={VEHICLE_ORGANIZATION_ASSIGNMENT_FORMIK.validationSchema}
            onSubmit={onSubmit}>
            {({
                  values,
                  errors,
                  isValid,
                  dirty,
                  handleSubmit,
                  handleChange,
                  setFieldValue,
              }) => (
                <div className="form-container">
                    <Title level={5}>Assigned Vehicle</Title>
                    <Text className="description" type="secondary">
                        Assigned vehicles to a organization.
                    </Text>
                    <Form onFinish={handleSubmit} className="--add-vehicles-form">
                        <FormItem error={errors.organization_id} label="Organizations">
                            <SelectSearch
                                value={values.organization_id}
                                items={clientOptions}
                                setFieldValue={setFieldValue}
                                fieldName="organizations"
                                placeholder="Select "
                            />
                        </FormItem>
                        <div className="action-btn-container">
                            <Button className="cancel-btn" onClick={onCancel}>
                                Cancel
                            </Button>
                            <Button
                                type="primary"
                                htmlType="submit"
                                disabled={!(isValid && dirty)}>
                                Add
                            </Button>
                        </div>
                    </Form>
                </div>
            )}
        </Formik>
    );

}