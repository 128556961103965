import { BatteryModelState, BatteryModelActions} from "types/store";

const originalFilters = {
    Assignee: [],
};
const updatedFilters = {
    Assignee: [],
};

const initialState: BatteryModelState = {
    page: {
        selectedPage: 1,
        totalElements: 0,
        totalPages: 0,
    },
    filters: {
        original: originalFilters,
        updated: updatedFilters,
        listType: "current",
    },
    search: "",
    showbulkActionsBatteryModels: false,
    selectedBatteryModelRows: [],
};

export default function batteryModelReducer(
    state: BatteryModelState = initialState,
    action: BatteryModelActions
): BatteryModelState {
    switch (action.type) {
        case "SET_BATTERY_MODELS":
            return { ...state, batteryModels: action.payload };
        case "SET_BATTERY_MODEL_SELECTED_PAGE":
            return {
                ...state,
                page: { ...state.page, selectedPage: action.payload },
            };
        case "SET_BATTERY_MODEL_TOTAL_PAGE":
            return { ...state, page: { ...state.page, ...action.payload } };
        case "SET_BATTERY_MODEL_ORIGINAL_FILTERS":
            return {
                ...state,
                filters: { ...state.filters, original: action.payload },
            };
        case "SET_BATTERY_MODEL_UPDATED_FILTERS":
            return {
                ...state,
                filters: { ...state.filters, updated: action.payload },
            };
        case "SET_BATTERY_MODEL_SEARCH":
            return {
                ...state,
                search: action.payload,
            };
        case "SET_SELECTED_BATTERY_MODEL":
            return {
                ...state,
                selectedBatteryModel: action.payload,
            };
        case "SET_BATTERY_MODEL_BULK_ACTIONS":
            return {
                ...state,
                showbulkActionsBatteryModels: action.payload,
            };
        case "SET_BATTERY_MODEL_SELECTED_ROWS":
            return {
                ...state,
                selectedBatteryModelRows: action.payload,
            };
        case "SET_BATTERY_MODEL_LIST_TYPE":
            return {
                ...state,
                filters: {
                    ...state.filters,
                    listType: action.payload,
                },
            };
        case "SET_BATTERY_MODEL_DROPDOWN":
            return {...state, dropdownBatteryModels: action.payload}
        default:
            return state;
    }
}
