import { BatteryHbState, BatteryHeartbeatAction} from "types/store";
const initialState: BatteryHbState = {
    heartbeats:[]
};

export default function batteryHeartbeatReducer(
    state: BatteryHbState = initialState,
    action: BatteryHeartbeatAction
): BatteryHbState {
    switch (action.type) {
        case "SET_BHB":
            console.log("hb bat in recucer : ", action.payload)
            return { ...state, heartbeats: action.payload };
        default:
            return state;
    }
}
