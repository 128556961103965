import { Table } from "components/base";
import {useCallback, useEffect, useMemo, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {initializeZones, setBulkActionsZones} from "store/actions";
import {
    renderEmptyContent,
    userIconCell,
    userDetailCell, UserIconCellTable,
} from "./cell-renders";
import { usersTableData } from "./__mock__";
import {AppState} from "../../../store";
import {parseBatteryTable, parseZoneTable} from "../../../utils/parsers";
import {ActionMenu} from "./cell-renders";

const columns = [

  {
    title: "Name",
    dataIndex: "name",
    colSpan: 7,

  },
    {
        title: "",
        dataIndex: "action",
        colSpan: 7,
        render: ActionMenu,
    },
];

export default function ListView() {
  const dispatch = useDispatch();
  const {
   zones,
  } = useSelector<AppState, AppState["zones"]>((state) => state.zones);

  const data = useMemo(
      () => parseZoneTable(zones),
      [zones]
  );
  useEffect(() => {
    dispatch(initializeZones());
  }, [dispatch]);

  const [selectedRows, setSelectedRows] = useState([]);
  const onChangeSelection = useCallback(
    (value) => {
      setSelectedRows(value);

      if (value.length !== 0) {
        dispatch(setBulkActionsZones(true));
      } else {
        dispatch(setBulkActionsZones(false));
      }
    },
    [dispatch]
  );
  return (
    <Table
      customClasses="--common-list-table"
      showHeader={false}
      // rowSelection={{
      //   selectedRowKeys: selectedRows,
      //   onChange: onChangeSelection,
      // }}
      columns={columns}
      dataSource={data}
      pagination={false}
    />
  );
}
