import { Table } from "components/base";
import { TableProps } from "antd/lib/table";
import { clientsTableData } from "./__mock__";
import {
  clientsDetailsCell,
  renderAction,
  renderEmptyContent,
} from "./cell-renders";

const allColumns: TableProps<any>["columns"] = [
  {
    title: "Clients Name",
    dataIndex: "client_name",
    render: renderEmptyContent,
  },
  {
    title: "Number of Vehicles",
    dataIndex: "vehicle_count",
    render: renderEmptyContent,
  },
  {
    title: "Onboarding Date",
    dataIndex: "onboarding_date",
    render: renderEmptyContent,
  },
  {
    title: "Client Admin",
    dataIndex: "client_admin",
    render: renderEmptyContent,
  },
  {
    title: "Client Details",
    dataIndex: "client_details",
    align: "left",
    render: clientsDetailsCell,
  },
  {
    title: "action",
    dataIndex: "action",
    className: "open-link",
    align: "right",
    render: renderAction,
  },
];

export default function ListView() {
  return (
    <Table
      columns={allColumns}
      dataSource={clientsTableData}
      pagination={false}
      showHeader={false}
      rowClassName="white-background-row"
    />
  );
}
