import {BatteryState, VehiclesState, ZonesState} from "../../types";
import {ZoneItem} from "../../protobuf/zone_service_pb";

export function parseZoneTable(
    list?: ZonesState["zones"]
) {
    if (list) {
        const data = list.map((zone, index) => ({
            key: index,
            name: zone.zoneName,
            coordinates: zone.latLngList,
            action: "",
            raw: zone,
        }));
        return data
    }

    return [];
}

export function parseZoneOptions(list?: ZoneItem.AsObject[]) {
    if (list) {
        return list.map((zone) => ({
            label: zone.zoneName,
            value: zone.id,
        }));
    }

    return [];
}