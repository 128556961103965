import { VehicleServiceStatus, VehicleStatus } from "protobuf/vehicle_service_pb";
import { VehiclesActions, VehiclesState } from "types/store";
import { VEHICLE_SERVICE_STATUS, VEHICLE_STATUS } from "utils/mapped-data";

const originalFilters = {
  Model: [
    { label: "Bajaj", value: "Bajaj" },
    { label: "TVS", value: "TVS" },
    { label: "Honda", value: "Honda" },
    { label: "Honda 160 cc", value: "Honda 160 cc" },
    { label: "TVS 160 cc", value: "TVS 160 cc" },
    { label: "TVS 180 cc", value: "TVS 180 cc" },
  ],
  // "Service Status": Object.keys(VehicleStatus.Enum).map((key, index) => ({
  //   label: VEHICLE_STATUS[index as VehicleStatus.Enum],
  //   value: key,
  // })),
  "Functional Status": [
    { label: "ON", value: "ON" },
    { label: "OFF", value: "OFF" },
  ],
  "Service Status": Object.keys(VehicleServiceStatus.Enum).map((key, index) => ({
    label: VEHICLE_SERVICE_STATUS[index as VehicleServiceStatus.Enum],
    value: key,
  })),

  "Client": [
    { label: "Waseem", value: "234534c546c241" },
    { label: "Zirgham", value: "413r23f3f2323" },
  ],

};

const updatedFilters = {
  Partner: [],
  Model: [],
  "Functional Status": [],
  "Service Status": [],
  "Client": [],
};

const initialState: VehiclesState = {
  page: {
    selectedPage: 1,
    totalElements: 0,
    totalPages: 0,
  },
  filters: {
    original: originalFilters,
    updated: updatedFilters,
  },
  search: "",
  showbulkActionsVehicles: false,
  logsPage:  {
    selectedPage: 1,
    totalElements: 0,
    totalPages: 0,
  },
};

export default function vehiclesReducer(
  state: VehiclesState = initialState,
  action: VehiclesActions
): VehiclesState {
  switch (action.type) {
    case "SET_VEHICLES_TABLE":
      return { ...state, vehicles: action.payload };
    case "SET_MODELS":
      return { ...state, models: action.payload };
    case "SET_VEHICLES_SELECTED_PAGE":
      return {
        ...state,
        page: { ...state.page, selectedPage: action.payload },
      };
    case "SET_LOGS_SELECTED_PAGE":
      return {
        ...state,
        logsPage: { ...state.logsPage, selectedPage: action.payload },
      };
    case "SET_LOGS_TOTAL_PAGE":
        return { ...state, logsPage: { ...state.logsPage, ...action.payload } };
    case "SET_VEHICLES_TOTAL_PAGE":
      return { ...state, page: { ...state.page, ...action.payload } };
    case "SET_VEHICLES_ORIGINAL_FILTERS":
      return {
        ...state,
        filters: { ...state.filters, original: action.payload },
      };
    case "SET_VEHICLES_UPDATED_FILTERS":
      return {
        ...state,
        filters: { ...state.filters, updated: action.payload },
      };
    case "SET_VEHICLE_SEARCH":
      return {
        ...state,
        search: action.payload,
      };
    case "SET_VEHICLES_BULK_ACTIONS":
      return {
        ...state,
        showbulkActionsVehicles: action.payload,
      };
    case "SET_DROPDOWN_VEHICLES":
      return {
        ...state,
        dropdownVehicles: action.payload,
      };
    case "SET_DROPDOWN_FIRMWARE":
      return {
        ...state,
        dropdownFirmwares: action.payload,
      };
    case "SET_VEHICLE_CLIENTS":
      return {
        ...state,
        clients: action.payload,
      };
    case "SET_SELECTED_VEHICLE":
      return {
        ...state,
        selectedVehicle: action.payload,
      };
    case "SET_SELECTED_VEHICLES":
      return {
        ...state,
        selectedVehicles: action.payload,
      };
    case "SET_VEHICLE_ISSUES":
      return {
        ...state,
        issues: action.payload,
      };
    case "SET_VEHICLE_LOCATION_HISTORY":
      return {
        ...state,
        vehicleLogs: action.payload,
      };
    case "SET_VEHICLES_BULK_UPDATE":
      return { ...state, vehicles: action.payload };
    default:
      return state;
  }
}
