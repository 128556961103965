import { Formik } from "formik";
import { Form, Button, Typography, Input } from "antd";
import { useCookies } from "react-cookie";
import { format } from "date-fns";

import { NEXTSTEP_ENQUIRY_FORMIK } from "utils/formik-data";
import { FormItem } from "components";
import { saveIcon } from "svgs";
import { useCallback } from "react";
import { date } from "yup";

const { Title } = Typography;
const { Text } = Typography;
const { TextArea } = Input;

interface ComponentProps {
  updateStep: (updatedStep: number) => void;
}

export default function StepOne(props: ComponentProps) {
  const { updateStep } = props;
  const [cookies, setCookies] = useCookies();
  const [{ enquiries = [] }] = useCookies();

  const onSubmit = useCallback(
    (values: typeof NEXTSTEP_ENQUIRY_FORMIK.initialValues) => {
      const { message } = values;

      setCookies("enquiries", [
        ...enquiries,
        { message, date: format(new Date(), "dd MMM, yyyy") },
      ]);
      updateStep(2);
    },
    [updateStep]
  );

  return (
    <main className="main-container">
      <Formik
        initialValues={NEXTSTEP_ENQUIRY_FORMIK.initialValues}
        validationSchema={NEXTSTEP_ENQUIRY_FORMIK.validationSchema}
        onSubmit={onSubmit}>
        {({
          values,
          handleSubmit,
          errors,
          handleChange,
          resetForm,
          isValid,
          dirty,
        }) => (
          <div className="next-step-form-container">
            <Text className="step">Step 1 of 2</Text>
            <Title level={5} className="next-step-title">
              <span className="title-icon">{saveIcon}</span>
              <span>
                Please provide your company’s full name and year of
                registration.
              </span>
            </Title>

            <Form className="next-step-form" onFinish={handleSubmit}>
              <FormItem>
                <TextArea
                  name="message"
                  rows={4}
                  placeholder="Type your answer..."
                  onChange={handleChange}
                  className="message override-height"
                  value={values.message}
                />
              </FormItem>

              <div className="action-btn-container">
                <Button className="cancel-btn" onClick={() => resetForm()}>
                  Reset
                </Button>
                <Button
                  type="primary"
                  htmlType="submit"
                  disabled={!isValid && dirty}>
                  Submit
                </Button>
              </div>
            </Form>
          </div>
        )}
      </Formik>
    </main>
  );
}
