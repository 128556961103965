import {BillingsComponentsData, BillingState} from "../../types";
import {string} from "yup";
import {monthNames, getNumberValue, formatDate} from "../../store/actions";
import {da} from "date-fns/locale";
import {FINANCIALS_WIDGET} from "../../components/tab-content/dashboard-content/__mock__";


export function parseBillingFieldsTable(
    bill?: BillingState["bill"]
){
    let data = {
        distance: 0,
        batterySubCharges: 0,
        swaps: 0,
        swapCharges: 0,
        perSwapCost: "0",
        dueDate: "",
        subTotal: 0,
        tax: 0,
        totalBill: 0,
        monthYear: "",
        totalBikes:0,
    }
    if (bill) {
        data.distance = getNumberValue(bill.usageCalculations?.fieldsMap || [], "distance") || 0;
        data.batterySubCharges = getNumberValue(bill.components?.fieldsMap || [], "batterySubCharges") || 0;
        data.swaps =  getNumberValue(bill.usageCalculations?.fieldsMap || [], "swaps") || 0;
        data.perSwapCost = bill.perSwapCost || "";
        data.swapCharges = getNumberValue(bill.components?.fieldsMap || [], "swapCharges") || 0;
        data.tax =  getNumberValue(bill.components?.fieldsMap || [], "tax") || 0;
        data.subTotal = getNumberValue(bill.components?.fieldsMap || [], "subTotal") || 0;
        data.totalBill = getNumberValue(bill.components?.fieldsMap || [], "totalBill") || 0;
        data.dueDate = formatDate(bill?.dueDate)
        data.monthYear = `${monthNames[bill.billingMonth-1]}, ${bill.billingYear}`
        data.totalBikes = bill.totalOperationalBikes

    }
    return data
}
