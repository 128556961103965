import { TableProps } from "antd/lib/table";
import { Table, TablePagination } from "components/base";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "store";
import {
  dispatchUsers,
  setBulkActionsUser,
  setSelectedUserRows,
} from "store/actions";
import { useGetUserData } from "utils/hooks";
import { parseUsersTable } from "utils/parsers";
import {
  renderEmptyContent,
  userIconCell,
  userDetailCell,
  ActionMenu,
} from "./cell-renders";

const columns: TableProps<any>["columns"] = [
  {
    title: "user ID",
    dataIndex: "user_id",
    width: "70px",
    render: userIconCell,
  },
  {
    title: "Name",
    dataIndex: "driver_name",
    colSpan: 7,
    render: userDetailCell,
  },
  {
    title: "Driver ID",
    dataIndex: "user_id",
    colSpan: 0,
    render: renderEmptyContent,
  },
  {
    title: "Assigned Vehicles",
    dataIndex: "vehicle_id",
    colSpan: 0,
    render: renderEmptyContent,
  },
  {
    title: "Today’s Shifts",
    dataIndex: "date_and_time",
    colSpan: 0,
    render: renderEmptyContent,
  },
  {
    title: "Safety",
    dataIndex: "safety_rating",
    colSpan: 0,
    render: renderEmptyContent,
  },
  {
    title: "Tasks Completed ",
    dataIndex: "task_completed",
    colSpan: 0,
    render: renderEmptyContent,
  },
  {
    title: " ",
    dataIndex: "action",
    render: ActionMenu,
    width: 56,
    className: "action-menu open-link --user",
  },
];

export default function ListView() {
  const { selectedTab, search, selectedUserRows } = useSelector<
    AppState,
    AppState["users"]
  >((state) => state.users);
  const dispatch = useDispatch();
  const { page, onNext, onBack, tableData, resetCallback } =
    useGetUserData(selectedTab);
  const dataSource = useMemo(() => {
    return parseUsersTable(search, tableData);
  }, [search, tableData]);

  useEffect(() => {
    dispatchUsers(dispatch, selectedTab);
  }, [dispatch, selectedTab, page.selectedPage]);

  const onChangeSelection = useCallback(
    (value) => {
      dispatch(setSelectedUserRows(value));

      if (value.length !== 0) {
        dispatch(setBulkActionsUser(true));
      } else {
        dispatch(setBulkActionsUser(false));
      }
    },
    [dispatch]
  );

  return (
    <>
      <Table
        customClasses="--common-list-table"
        showHeader={false}
        columns={columns}
        dataSource={dataSource}
        pagination={false}
        rowSelection={{
          selectedRowKeys: selectedUserRows,
          onChange: onChangeSelection,
        }}
      />
      <TablePagination
        page={page}
        onBack={onBack}
        onNext={onNext}
        resetCallback={resetCallback}
      />
    </>
  );
}
