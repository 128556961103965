import { Table } from "components/base";
import { TableProps } from "antd/lib/table";
import { unresolvedissuesData } from "./__mock__";
import {
  renderIssueId,
  renderVehicleId,
  renderIssueType,
} from "./cell-renders";

const columns: TableProps<any>["columns"] = [
  {
    title: "Issue ID",
    dataIndex: "issue_id",
    render: renderIssueId,
    className: "open-link",
  },
  {
    title: "Issue Type",
    dataIndex: "issue_type",
    align: "center",
    render: renderIssueType,
  },
  {
    title: "Vehicle ID",
    dataIndex: "vehicle_id",
    render: renderVehicleId,
    className: "open-link",
  },
];

export default function UnresolvedIssues() {
  return (
    <>
      <section className="dashboard-table-header">
        <strong className="dashboard-table-title">
          Most Recent Unresolved Issues
        </strong>
      </section>

      <Table
        customClasses="--dashboard-table "
        rowClassName="dashed-border "
        dataSource={unresolvedissuesData}
        columns={columns}
        pagination={false}
      />
    </>
  );
}
