import { Typography, Progress } from "antd";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { format } from "date-fns";

import Map from "components/map";
import { AppState } from "store";
import { calendarIcon, rightAngleIcon, timeIcon } from "svgs";
import { ComponentType } from ".";
import { DATE_FORMAT } from "utils/constants";
import { getDateFromSeconds } from "utils/helpers";

interface ComponentProps {
  onChangeComponent: (component: ComponentType) => void;
}

const { Title, Text } = Typography;

function getDateObj(seconds: number) {
  const date = getDateFromSeconds(seconds);
  const formatedDate = format(date, DATE_FORMAT);
  const day = format(date, "EEEE");
  const time = format(date, "p");

  return {
    date: formatedDate,
    day,
    time,
  };
}

export default function Stats(props: ComponentProps) {
  const { onChangeComponent } = props;
  const { selectedVehicle } = useSelector<AppState, AppState["vehicles"]>(
    (state) => state.vehicles
  );
  const {
    map_marker,
  } = useSelector<AppState, AppState["map_marker"]>((state) => state.map_marker);
  
  const locationUpdatedAt = useMemo(() => {
    if (selectedVehicle?.locationUpdatedAt) {
      return getDateObj(selectedVehicle.locationUpdatedAt);
    }

    return;
  }, [selectedVehicle?.locationUpdatedAt]);
  const statusUpdatedAt = useMemo(() => {
    if (selectedVehicle?.statusAddedAt) {
      return getDateObj(selectedVehicle.statusAddedAt);
    }

    return;
  }, [selectedVehicle?.statusAddedAt]);

  return (
    <section className="__stats-container">
      <Title type="secondary" className="vehicle-no" level={5}>
        <span
          role="button"
          className="forward-icon"
          onClick={() => onChangeComponent("gallery")}
        >
          {rightAngleIcon}
        </span>
        {selectedVehicle?.licensePlate}
      </Title>
      <div className="location-container">
        <Text className="stats-title" type="secondary">
          Location
        </Text>
        <Text className="vehicle-info ">
          <>
            Longitude:{" "}
            <Text type="secondary" className="value">
              {selectedVehicle?.point?.longitude}
            </Text>
          </>
          <>
            Longitude:{" "}
            <Text type="secondary" className="value">
              {selectedVehicle?.point?.latitude}
            </Text>
          </>
        </Text>
        <Text className="vehicle-info">
          Updated On:{" "}
          <Text type="secondary" className="value">
            {calendarIcon} {locationUpdatedAt?.date} -{locationUpdatedAt?.day}
          </Text>{" "}
          <Text type="secondary" className="time-container">
            {timeIcon} {locationUpdatedAt?.time}
          </Text>
        </Text>
        <div className="location-map">
          <Map center={map_marker}/>
        </div>
      </div>
      <div className="last-status">
        <Text className="stats-title" type="secondary">
          Last Status Update
        </Text>
        <div className="status-box">
          <div className="status">
            <span className="value-with-color --circle">Idle</span>
          </div>
          <div className="time">
            <Text className="vehicle-info">
              <Text type="secondary">
                {calendarIcon} {statusUpdatedAt?.date} - {statusUpdatedAt?.day}
              </Text>{" "}
              <Text type="secondary" className="time-container">
                {timeIcon} {statusUpdatedAt?.time}
              </Text>
            </Text>
          </div>
        </div>
      </div>
      <Text className="stats-title" type="secondary">
        Last 24 Hours Stats
      </Text>
      <div className="charts-container">
        <Progress
          className="progress-chart"
          type="dashboard"
          gapDegree={120}
          percent={40}
          strokeColor={{
            "100%": "#70b857",
            "50%": "#ecc600",
            "0%": "#ed2020",
          }}
          showInfo={false}
        />
        <Progress
          className="progress-chart"
          type="dashboard"
          gapDegree={120}
          percent={75}
          strokeColor={{
            "100%": "#70b857",
            "50%": "#ecc600",
            "0%": "#ed2020",
          }}
          showInfo={false}
        />
        <Progress
          className="progress-chart"
          type="dashboard"
          gapDegree={120}
          percent={55}
          showInfo={false}
          status="exception"
        />
        <div className="charts-info">
          <div className="info-container">
            <Title className="value" level={3}>
              78
            </Title>
            <Text type="secondary" className="unit">
              liters
            </Text>
            <Text type="secondary" className="label">
              Fuel Consumed
            </Text>
          </div>
          <div className="info-container">
            <Title className="value" level={3}>
              58
            </Title>
            <Text type="secondary" className="unit">
              km / hr
            </Text>
            <Text type="secondary" className="label">
              Average Speed
            </Text>
          </div>
          <div className="info-container">
            <Title className="value" level={3}>
              03
            </Title>
            <Text type="secondary" className="unit">
              Count
            </Text>
            <Text type="secondary" className="label">
              Alerts
            </Text>
          </div>
        </div>
      </div>
    </section>
  );
}
