import { ReactNode } from "react";
import { Radio as RadioAntd } from "antd";
import { RadioProps } from "antd/lib/radio";

interface ComponentProps {
  children: ReactNode;
  customClasses?: string;
}

export default function Radio(props: ComponentProps & RadioProps) {
  const { customClasses = "", children, ...rest } = props;
  return (
    <RadioAntd className={`radio ${customClasses}`} {...rest}>
      {children}
    </RadioAntd>
  );
}
