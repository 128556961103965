import { useState } from "react";
import { Typography, Button, Input } from "antd";

import { IssueType } from "protobuf/issue_service_pb";
import { ISSUE_TYPE } from "utils/mapped-data";
import { plusIcon } from "svgs";
import CheckBoxGroup from "components/checkbox-group";

const { Text } = Typography;

const issuesOptions = Object.keys(IssueType.Enum).map((key, index) => ({
  label: ISSUE_TYPE[index as IssueType.Enum],
  value: key,
}));

export default function IssueTypes() {
  const [addIssueType, setAddIssueType] = useState(false);

  return (
    <section className=" grid-container issue-type">
      <Text type="secondary" className="title">
        Issue Types
      </Text>
      <span className="setting">
        <Text>
          Select the type of issues that can be created and configured in the
          application
        </Text>

        <div className="issues-container">
          <CheckBoxGroup options={issuesOptions} />
          {!addIssueType ? (
            <figure className="add-new" onClick={() => setAddIssueType(true)}>
              {plusIcon} Add Issue Type
            </figure>
          ) : (
            <figure className="add-new-data">
              <Input />
              <Button type="primary" onClick={() => setAddIssueType(false)}>
                Add
              </Button>
              <Button onClick={() => setAddIssueType(false)}>Cancel</Button>
            </figure>
          )}
        </div>
      </span>
    </section>
  );
}
