import { Typography } from "antd";
import { type } from "os";
import {
  calendarIcon,
  petrolPumpIcon,
  salikIcon,
  trafficFineIcon,
  serviceIcon,
  checkIcon,
  editIcon,
  otherIcon,
  archiveIcon,
} from "svgs";

import { financialsTableData } from "./__mock__";

const { Text } = Typography;

type Row = ReturnType<typeof financialsTableData>[number];

export function renderEmptyContent(value: string) {
  return {
    children: value,
    props: {
      colSpan: 0,
    },
  };
}

export function renderPaidAction() {
  return (
    <section className="table-action-container show-on-hover">
      <span className="edit-icon icon">{editIcon}</span>
      <span className="delete-icon icon">{archiveIcon}</span>
    </section>
  );
}

export function renderOutstandingAction() {
  return (
    <section className="table-action-container show-on-hover">
      <article className="action-button --success ">
        {checkIcon} <span>Mark as Paid</span>
      </article>
      <span className="separator"></span>
      <span className="edit-icon icon">{editIcon}</span>
      <span className="delete-icon icon">{archiveIcon}</span>
    </section>
  );
}

function renderPaymentSvg(paymentTitle: string) {
  switch (paymentTitle) {
    case "Lease":
      return salikIcon;

    case "Salik":
      return petrolPumpIcon;

    case "Traffic Fine":
      return trafficFineIcon;

    case "Service & Repair":
      return serviceIcon;

    default:
      return otherIcon;
  }
}

export function renderPaymentIcon(value: string, row: Row) {
  return (
    <figure className="icon-container">
      {renderPaymentSvg(row.payment_type)}
    </figure>
  );
}

function renderPaymentReason(paymentTitle: string) {
  switch (paymentTitle) {
    case "Lease":
      return <span> for 20 vehicles</span>;

    case "Salik":
      return <span> at Jebel Ali Salik Gate</span>;

    case "Traffic Fine":
      return <span> for Overspeeding</span>;

    case "Service & Repair":
      return <span> at Rapido Garage</span>;

    default:
      return <span> at Jebel Ali Salik Gate</span>;
  }
}

export function paymentDetailCell(value: string, row: Row) {
  return (
    <p className="list-detail">
      <Text className="" type="secondary">
        <strong className="dot-separator">{row.payment_type}</strong>
        Attributed to <strong>{row.client}</strong>
      </Text>
      <Text type="secondary">
        Paid {row.amount} AED {renderPaymentReason(row.payment_type)} on
        <span className="icon --calendar">{calendarIcon}</span>
        <span className="capitalize">{row.date}</span>
        <span className="separator"></span>
        <a className="link disabled">View Invoice</a>
      </Text>
    </p>
  );
}

export function outstandingPaymentDetailCell(value: string, row: Row) {
  return (
    <p className="list-detail">
      <Text className="" type="secondary">
        <strong className="dot-separator">{row.payment_type}</strong>
        Attributed to <strong>{row.client}</strong>
      </Text>
      <Text type="secondary">
        Payment of {row.amount} AED
        {renderPaymentReason(row.payment_type)} due on
        <span className="icon --calendar">{calendarIcon}</span>
        <span className="capitalize">{row.date}</span>
        <span className="separator"></span>
        <a href="" className="link disabled">
          View Invoice
        </a>
      </Text>
    </p>
  );
}
